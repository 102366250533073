import {FirebaseApp, initializeApp} from 'firebase/app';
import {Auth, browserLocalPersistence, getAuth, onAuthStateChanged, setPersistence} from 'firebase/auth';
import React, {PropsWithChildren, useContext, useEffect, useState} from 'react';

import {cookies} from '../../cookies';
import CustomErrorComponent from '../../pages/_error';
import {Environment} from '../../pages/api/environment';
import {clearAuth, installAuthCookie} from '../firebase/public';
import {addCookieListener} from '../query/cookieListener';
import RichText from '../tool/components/RichText';
import {AppHelp} from '../tool/decision/types';

type AppEnvironment = Omit<Environment, 'firebaseConfig' | 'help'> & {
  firebaseApp: FirebaseApp;
  firebaseAuth: Auth;
  help: AppHelp;
};

const EnvironmentContext = React.createContext<AppEnvironment | undefined>(undefined);

const EnvironmentProvider = ({children}: PropsWithChildren<{}>) => {
  const [value, setValue] = useState<AppEnvironment>();
  const [error, setError] = useState(false);

  useEffect(() => {
    fetch('/api/environment')
      .then(async res => {
        if (!res.ok) {
          console.log(`Request to '/api/environment' failed with status ${res.status}`);
          setError(true);
        } else {
          const {firebaseConfig, help, ...environment} = (await res.json()) as Environment;
          const firebaseApp = initializeApp(firebaseConfig);
          const firebaseAuth = getAuth(firebaseApp);
          await setPersistence(firebaseAuth, browserLocalPersistence);
          addCookieListener(cookies);
          onAuthStateChanged(firebaseAuth, async () => {
            if (firebaseAuth.currentUser) {
              await installAuthCookie(firebaseAuth.currentUser, true);
            } else {
              await clearAuth();
            }

            // Convert text strings containing HTML into RichText elements
            const appHelp = Object.fromEntries(
              Object.entries(help).map(([key, value]) => [
                key,
                typeof value === 'object' && value !== null && 'content' in value
                  ? {
                      isDialogue: value.isDialogue,
                      title: value.title,
                      content: <RichText content={value.content} />,
                    }
                  : value,
              ])
            ) as AppHelp;

            setValue(prevState => prevState ?? {...environment, firebaseApp, firebaseAuth, help: appHelp});
          });
        }
      })
      .catch(e => {
        console.log(e);
        setError(true);
      });
  }, []);

  if (error) {
    return <CustomErrorComponent statusCode={500} message={'Error fetching environment'} />;
  }

  if (!value) return null;
  return <EnvironmentContext.Provider value={value}>{children}</EnvironmentContext.Provider>;
};

export default EnvironmentProvider;

export const useEnvironment = () => {
  return useContext(EnvironmentContext);
};

export const useFirebaseAuth = () => {
  const context = useContext(EnvironmentContext);
  return context?.firebaseAuth;
};
