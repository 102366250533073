import {NextPageContext} from 'next';

import Col from '../features/common/Col';
import Row from '../features/common/Row';
import {notify} from '../features/common/util/bugsnag';
import Button from '../features/tool/components/buttons/Button';

type Props = {
  statusCode: number;
  message?: string;
  buttonLabel?: string;
  onButtonClick?: () => void;
};

const CustomErrorComponent = ({statusCode, message, buttonLabel, onButtonClick}: Props): JSX.Element => (
  <Col className={'h-full justify-center items-center gap-xl'}>
    <Row className={'items-center'}>
      <span className={'text-xl font-bold border-r border-r-gray-200 pr-sm px-xs'}>{statusCode}</span>
      <span className={'pl-sm'}>{message || 'Internal error'}</span>
    </Row>
    {!!buttonLabel && onButtonClick && (
      <Button variant={'primary'} onClick={onButtonClick}>
        {buttonLabel}
      </Button>
    )}
  </Col>
);

CustomErrorComponent.getInitialProps = ({err, res}: NextPageContext) => {
  if (err) {
    notify(err, 'CustomErrorComponent');
  }

  const statusCode = res ? res.statusCode : err?.statusCode ?? 404;
  return {statusCode, message: err?.message};
};

export default CustomErrorComponent;
