// noinspection ExceptionCaughtLocallyJS

import classNames from 'classnames';
import React, {
  ComponentProps,
  FormEvent,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import Row from '../../../common/Row';
import {isNonNullish, isNullish} from '../../../common/util';
import {Flow} from '../../../query/graphql';
import useCurrentUser from '../../../query/useCurrentUser';
import {
  convertArea,
  convertAreaReverse,
  convertValuePerAreaReverse,
  convertValuePerHa,
} from '../../decision/calculations';
import {CostCollection, SimpleValue} from '../../decision/types';
import {toFixed} from '../../decision/util';
import {useQuestionViewChildren} from '../../hooks/useQuestionViewChildren';
import Button from '../buttons/Button';
import ProgressBar from './ProgressBar';
import QuestionFooter from './QuestionFooter';
import QuestionView from './QuestionView';

type PropsType = {
  persistProgress?: Flow;
  onFinish: (() => Promise<void>) | (() => void);
  editId?: string | string[] | undefined;
  onCancelEdit?: () => void;
  scenarioId: string | null;
};

function validateQuestion(props: React.ComponentProps<typeof QuestionView>, value: SimpleValue | null) {
  const actualValue = props.type === 'input' && props.numeric && value !== null ? Number(value) : value;
  return 'inputs' in props ? props.validate(actualValue, props.inputs) : props.validate(actualValue);
}

const QuestionSequence = ({
  children,
  persistProgress,
  onFinish,
  editId,
  onCancelEdit,
  scenarioId,
}: PropsWithChildren<PropsType>): ReactElement => {
  const currentUser = useCurrentUser();
  const imperialUnits = !!currentUser?.props.prefersImperial;

  const timeout = useRef<NodeJS.Timeout>();
  const [isLoading, setIsLoading] = useState(false);
  const [edited, setEdited] = useState<string[]>([]);
  const [error, setError] = useState<string>();
  const allQuestions = useQuestionViewChildren(children);
  const formRef = useRef<HTMLFormElement>(null);

  const questions = useMemo(
    () =>
      allQuestions.filter(q => {
        if (q.props.isOmitted) return false;
        if (editId) {
          // In edit mode, show only question with matching editId (if not yet edited) ...
          if (Array.isArray(editId)) {
            if (editId.includes(q.props.id)) return !edited.includes(q.props.id);
          } else {
            if (q.props.id === editId) return !edited;
          }
          // ... and any other invalid question
          const val = q.props.value ?? q.props.defaultValue ?? null;
          return !validateQuestion(q.props, val).valid;
        }
        return true;
      }),
    [allQuestions, editId, edited]
  );

  const [currentIdx, setCurrentIdx] = useState(0);
  const currentQuestion = questions.length ? questions[currentIdx] : undefined;
  const currentDefault = currentQuestion?.props.defaultValue ?? null;
  const [currentVal, setCurrentVal] = useState<SimpleValue | null>(null);
  const isValid = currentQuestion && validateQuestion(currentQuestion.props, currentVal ?? currentDefault).valid;
  const questionId = currentQuestion?.props.id;
  const questionType = currentQuestion?.props.type;
  const questionValue = currentQuestion?.props.value;
  const isArea =
    (currentQuestion?.props.type === 'input' && currentQuestion.props.numeric && currentQuestion.props.isArea) ?? false;
  const isNumeric =
    (currentQuestion?.props.type === 'input' && currentQuestion.props.numeric) ||
    currentQuestion?.props.type === 'slider';

  const [lastQuestionId, setLastQuestionId] = useState<string>();
  const [lastQuestionValue, setLastQuestionValue] = useState<SimpleValue | null>();

  // Whenever current question changes, read stored value
  useEffect(() => {
    setCurrentVal(prevState => {
      // If it's a new question, or question value has changed...
      if (!lastQuestionId || questionId !== lastQuestionId || questionValue !== lastQuestionValue) {
        formRef.current?.scrollTo({top: 0});
        return isNullish(questionValue) || isNullish(questionType)
          ? null
          : convertValueAfterReading(questionValue, questionType, isArea, imperialUnits);
      }

      // Otherwise, keep the value
      return prevState;
    });
    setLastQuestionId(questionId);
    setLastQuestionValue(questionValue);
  }, [imperialUnits, isArea, lastQuestionId, lastQuestionValue, questionId, questionType, questionValue]);

  const handleChangeQuestion = useCallback((id: string, value: SimpleValue | null) => {
    setError(undefined);
    setCurrentVal(_ => value);
  }, []);

  const handleClickPrev = useCallback(() => {
    setError(undefined);
    setCurrentIdx(prevState => prevState - 1);
  }, []);

  const doSubmit = useCallback(
    async (isAlternate: boolean) => {
      if (!currentQuestion || !questionType) {
        return;
      }

      try {
        let valueToSubmit = currentVal;
        // Validate numeric input
        if (isNonNullish(valueToSubmit) && isNumeric) {
          valueToSubmit = parseFloat(valueToSubmit as string);
          if (isNaN(valueToSubmit)) {
            throw new Error('Please enter a number');
          }
        }
        // Validate triple input
        if (isNonNullish(valueToSubmit) && questionType === 'triple') {
          if (!Array.isArray(valueToSubmit)) {
            throw new Error('Invalid number of elements');
          }
          valueToSubmit = valueToSubmit.map(v => parseFloat(v as unknown as string));
          if (valueToSubmit.some(v => isNaN(v) || v <= 0)) {
            throw new Error('Please provide only strictly positive numbers');
          }
        }

        valueToSubmit = isNullish(valueToSubmit)
          ? valueToSubmit
          : convertValueBeforeWriting(valueToSubmit, questionType, isArea, imperialUnits);

        const validation = validateQuestion(currentQuestion.props, valueToSubmit ?? currentDefault);
        if (validation?.valid === false) {
          throw new Error(validation.error);
        }

        const handler = isAlternate ? currentQuestion.props.alternateOnChange : currentQuestion.props.onChange;
        setIsLoading(true);
        await handler?.(currentQuestion.props.id, valueToSubmit);

        if (editId) {
          setEdited(prevState => [...prevState, currentQuestion.props.id]);
        } else {
          setCurrentIdx(prevState => prevState + 1);
        }
      } catch (e: any) {
        setError(e?.message || 'Unknown error');
      } finally {
        setIsLoading(false);
      }
    },
    [currentDefault, currentQuestion, currentVal, editId, imperialUnits, isArea, isNumeric, questionType]
  );

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      void doSubmit(false);
    },
    [doSubmit]
  );

  const handleClickAlternate = useCallback(() => {
    void doSubmit(true);
  }, [doSubmit]);

  useEffect(() => {
    const doAsync = async () => {
      setIsLoading(false);
      if (currentIdx >= questions.length) {
        console.log(`Finishing QuestionSequence on idx ${currentIdx} having ${questions.length} questions`);
        await onFinish();
      }
    };
    if (timeout.current) {
      setIsLoading(false);
      clearTimeout(timeout.current);
    }
    if (currentIdx >= questions.length) {
      setIsLoading(true);
      const delay = editId ? 100 : 200; // In non-edit mode, leave extra time for the progress bar to reach the end
      timeout.current = setTimeout(doAsync, delay);
    }
  }, [currentIdx, editId, onFinish, questions.length]);

  const CurrentQuestionView = useMemo(
    () =>
      currentQuestion
        ? React.cloneElement(currentQuestion, {
            value: currentVal,
            onChange: handleChangeQuestion,
            error,
            disabled: isLoading,
          })
        : null,
    [currentQuestion, currentVal, handleChangeQuestion, error, isLoading]
  );

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit}
      className={'w-full h-full flex-1 flex flex-col items-center overflow-y-auto'}
    >
      {!editId && (
        <ProgressBar persistAs={persistProgress} max={questions.length} current={currentIdx} scenarioId={scenarioId} />
      )}
      <div className={classNames('flex-1 w-full flex flex-col justify-center')}>{CurrentQuestionView}</div>
      <QuestionFooter>
        {!editId && (
          <Button
            type={'button'}
            onClick={handleClickPrev}
            variant={'secondary'}
            accessory={'back'}
            disabled={currentIdx === 0 || isLoading}
            className={currentIdx === 0 ? 'invisible' : undefined}
          >
            Previous
          </Button>
        )}
        {!!editId &&
          (currentQuestion?.props.id === editId ? (
            <Button type={'button'} disabled={isLoading} onClick={onCancelEdit} variant={'secondary'}>
              Cancel
            </Button>
          ) : (
            <div></div>
          ))}
        <Row className={'gap-xs'}>
          {currentQuestion?.props.alternateLabel && (
            <Button
              type={'button'}
              variant={'secondary'}
              disabled={isLoading || !!error || !isValid}
              onClick={handleClickAlternate}
            >
              {currentQuestion.props.alternateLabel}
            </Button>
          )}
          <Button
            type={'submit'}
            variant={'primary'}
            disabled={isLoading || !!error || !isValid}
            accessory={'forward'}
            loading={isLoading}
          >
            {currentQuestion?.props.nextButtonLabel ?? 'Next'}
          </Button>
        </Row>
      </QuestionFooter>
    </form>
  );
};

export default QuestionSequence;

function convertValueAfterReading(
  value: SimpleValue,
  type: ComponentProps<typeof QuestionView>['type'],
  isArea: boolean,
  imperialUnits: boolean
): SimpleValue {
  // If question is a numeric area input, convert value to imperial units if necessary
  if (isArea && (typeof value === 'number' || typeof value === 'string')) {
    const numeric = typeof value === 'string' ? parseFloat(value) : value;
    return toFixed(convertArea(numeric, imperialUnits), 2).toString();
  }
  // If question is a triple area input, convert each value to imperial units if necessary
  if (type === 'triple' && Array.isArray(value)) {
    return value.map(v => (typeof v === 'number' ? toFixed(convertArea(v, imperialUnits), 2).toString() : ''));
  }
  // If question is a costTable input, convert costs per ha to imperial units if necessary
  if (type === 'costTable') {
    const costs = value as CostCollection;
    if (costs.costUnit === 'hectare') {
      return {
        ...costs,
        operations: costs.operations.map(op => ({
          ...op,
          cost: op.cost === null ? null : convertValuePerHa(op.cost, imperialUnits),
          defaultCost: convertValuePerHa(op.defaultCost, imperialUnits),
        })),
      };
    }
  }
  // Otherwise, return the value as is
  return value;
}

function convertValueBeforeWriting(
  value: SimpleValue,
  type: ComponentProps<typeof QuestionView>['type'],
  isArea: boolean,
  imperialUnits: boolean
): SimpleValue {
  // If question is a numeric area input, convert value back from imperial units
  if (isArea && (typeof value === 'number' || typeof value === 'string')) {
    const numeric = typeof value === 'string' ? parseFloat(value) : value;
    return convertAreaReverse(numeric, imperialUnits);
  }
  // If question is a triple area input, convert each value back from imperial units if necessary
  if (type === 'triple' && Array.isArray(value)) {
    return value.map(v => {
      const numeric = typeof v === 'string' ? parseFloat(v) : v;
      return convertAreaReverse(numeric, imperialUnits);
    });
  }
  // If question is a costTable input, convert costs per ha back from imperial units if necessary
  if (type === 'costTable') {
    const costs = value as CostCollection;
    if (costs.costUnit === 'hectare') {
      return {
        ...costs,
        operations: costs.operations.map(op => ({
          ...op,
          cost: op.cost === null ? null : convertValuePerAreaReverse(op.cost, imperialUnits),
          defaultCost: convertValuePerAreaReverse(op.defaultCost, imperialUnits),
        })),
      };
    }
  }
  // Otherwise, return the value as is
  return value;
}
