import format from 'string-format';

import {GameLogicError} from '../errors';
import {Actions, gameSlice} from '../gameSlice';
import {
  choiceMade,
  computeCost,
  eventOccurredAtYear,
  formatMoney,
  isHarvestYear,
  isNHarvest,
  isPlantingYear,
  isPlantingYearPlus,
  questionEverAsked,
  scaleFuelQuality,
  scaleYield,
  scaleYieldPermanently,
} from '../mechanics';
import {Question} from '../types';
import {CropId, getCrop} from './crops';
import {EventId} from './events';
import {PlaceId} from './places';
import {ToolId} from './tools';

export enum QuestionId {
  landPreparation = 'landPreparation',
  planOrRush = 'planOrRush',
  cropType = 'cropType',
  plantingTime = 'plantingTime',
  plantationType = 'plantationType',
  dry = 'dry',
  rabbitProtection = 'rabbitProtection',
  deerProtection = 'deerProtection',
  beetles = 'beetles',
  herbicide = 'herbicide',
  gap_up = 'gap_up',
  councilTransport = 'councilTransport',
  newContractor_willow = 'newContractor_willow',
  newContractor_misc = 'newContractor_misc',
  newMarket = 'newMarket',
  application = 'application',
  harvestTime = 'harvestTime',
  interRowCultivator = 'interRowCultivator',
}

export enum OptionId {
  landPreparation_prepare = 'landPreparation_prepare',
  planOrRush_plan = 'planOrRush_plan',
  planOrRush_rush = 'planOrRush_rush',
  cropType_willow = 'cropType_willow',
  cropType_miscanthus = 'cropType_miscanthus',
  planting_march = 'planting_march',
  planting_may = 'planting_may',
  planting_july = 'planting_july',
  planting_september = 'planting_september',
  plantation_mono = 'plantation_mono',
  plantation_light = 'plantation_light',
  plantation_hard = 'plantation_hard',
  dry_air = 'dry_air',
  dry_force = 'dry_force',
  rabbit_protection_no = 'rabbit_protection_no',
  rabbit_protection_fence = 'rabbit_protection_fence',
  rabbit_protection_employee = 'rabbit_protection_employee',
  deer_protection_no = 'deer_protection_no',
  deer_protection_strip = 'deer_protection_strip',
  beetles_no_spray = 'beetles_no_spray',
  beetles_spray = 'beetles_spray',
  herbicide_no = 'herbicide_no',
  herbicide_yes = 'herbicide_yes',
  gap_up_no = 'gap_up_no',
  gap_up_yes = 'gap_up_yes',
  councilTransport_collect = 'councilTransport_collect',
  councilTransport_own = 'councilTransport_own',
  newContractor_stay = 'newContractor_stay',
  newContractor_change = 'newContractor_change',
  application_council = 'application_council',
  application_myself = 'application_myself',
  harvestTime_october = 'harvestTime_october',
  harvestTime_december = 'harvestTime_december',
  harvestTime_february = 'harvestTime_february',
  harvestTime_april = 'harvestTime_april',
  interRowCultivator_yes = 'interRowCultivator_yes',
  interRowCultivator_no = 'interRowCultivator_no',
  newMarket_bedding = 'newMarket_bedding',
  newMarket_miscrete = 'newMarket_miscrete',
}

export const QUESTIONS: {[key in QuestionId]: {id: key} & Question} = {
  // Year 0
  landPreparation: {
    id: QuestionId.landPreparation,
    subtitle: 'Current land use of your land is {}.',
    title: 'You need to spend {} to make it ready for growing',
    shouldAsk: status => status.currentYear === 0,
    compute: (status, question) => {
      const landUse = GameLogicError.assertNonNullish(status.landUse);
      const cost = GameLogicError.assertNonNullish(computeCost(status, landUse.preparationCost));
      question.title = format(question.title, formatMoney(cost));
      question.subtitle = format(question.subtitle, landUse.name);
      return question;
    },
    options: [
      {
        id: OptionId.landPreparation_prepare,
        title: 'Prepare land',
        compute: (status, option) => {
          const landUse = GameLogicError.assertNonNullish(status.landUse);
          option.costPerHa = landUse.preparationCost;
          return option;
        },
      },
    ],
  },
  planOrRush: {
    id: QuestionId.planOrRush,
    subtitle:
      'But when would you like to plant? You can plant as soon as possible, or spend a year getting your land ready. Tortoise or Hare? The choice is yours.',
    title: 'When would you like to plant?',
    shouldAsk: status => status.currentYear === 0,
    help: {
      neighbour: {
        text: "I don't think you'll have a problem planting after arable. 15 years ago I changed one field from grass to cereals. We ploughed it, sprayed it, sprayed it again and then sprayed it a bit more, just in case! We can't do that these days since so many of the herbicides and pesticides are now banned. If you're planting after grass, I think you might need to bide your time, so you can produce a good seed bed. Otherwise, you'll probably be throwing your money away.",
      },
      expert: {
        text: "If you are planting Willow or Miscanthus into arable or temporary grassland it is quite straightforward, and you can follow a crop harvest one autumn with land preparation for your biomass crop planting the following spring. With permanent pasture (land that has been in grass for over 13 years) and rough grazing, you really ought to take time and prepare your land. If you don't, you'll likely have severe weed and pest issues. Once you remove grass there will be several pest larvae (Leatherjackets, Wireworms and Moths) in the soil that will have nothing else to eat and they'll tuck into the newly establishing Willow and Miscanthus roots. This will reduce yield. Planting a sacrificial break crop (e.g. a crop of spring barley) gives the beasties something to feed on and then breaks the life cycle. Also, you should do a soil test before planting and make sure that you have read any legislation relating to planting energy crops. Although it’s tempting to crack on, you’ll get better results by a slow and steady approach.",
        cost: 150,
      },
    },
    options: [
      {
        id: OptionId.planOrRush_rush,
        title: 'As soon as possible',
        subtitle: 'Your plantation will start producing earlier',
        onChosen: _status => {
          // Higher chance of weeds, pests, etc. considered in events
        },
        penaltyPoints: 1,
      },
      {
        id: OptionId.planOrRush_plan,
        title: 'Plan ahead',
        subtitle: 'You will lose a year preparing your land',
        onChosen: status => {
          status.plantingYear = 1;
        },
      },
    ],
  },
  // Planting year
  cropType: {
    id: QuestionId.cropType,
    subtitle:
      'These are the energy crops available in the market. You will need to spend some money on planting material and planting costs.',
    title: 'Which one do you want to plant?',
    shouldAsk: status => isPlantingYear(status),
    help: {
      text: 'Willow and Miscanthus are crops that have different characteristics. Willow is harvested every 3 years while Miscanthus is harvested every year.',
      links: [
        {url: 'https://enerpower.ie/2012/07/05/miscanthus-v-willow/'},
        {url: 'https://en.wikipedia.org/wiki/Willow'},
        {url: 'https://en.wikipedia.org/wiki/Miscanthus'},
      ],
      neighbour: {
        text: 'As a rule of thumb I use this rhyme: Willow likes water, that’s for sure. Miscanthus does well in the sun, not the moors!',
      },
      expert: {
        text: 'Willow tends to thrive and produce the best yields in the wetter west. It can withstand harsher conditions than Miscanthus. Miscanthus produces the best growth in warmer and sunnier places on more cultivated land. It can withstand drier conditions better than Willows.',
        cost: 120,
      },
    },
    options: [
      {
        id: OptionId.cropType_willow,
        title: 'Willow',
        subtitle:
          'Biomass Willow crops are grown closely together and harvested every 3 years. The plantation is managed as a coppice, with each plant having many stems.',
        compute: (state, option) => {
          const crop = getCrop(CropId.willow);
          option.costPerHa = GameLogicError.assertNonNullish(crop.plantationCost);
          return option;
        },
        onChosen: status => gameSlice.caseReducers.chooseCrop(status, Actions.chooseCrop(CropId.willow)),
      },
      {
        id: OptionId.cropType_miscanthus,
        title: 'Miscanthus',
        subtitle:
          'Miscanthus is a fast-growing grass that originates from Asia. It is planted very densely and harvested every year. Each plant produces many canes.',
        compute: (state, option) => {
          const crop = getCrop(CropId.miscanthus);
          option.costPerHa = GameLogicError.assertNonNullish(crop.plantationCost);
          return option;
        },
        onChosen: status => gameSlice.caseReducers.chooseCrop(status, Actions.chooseCrop(CropId.miscanthus)),
      },
    ],
  },
  plantingTime: {
    id: QuestionId.plantingTime,
    subtitle: 'Planting time has a major impact on yield and crop quality.',
    title: 'When do you plan to plant?',
    shouldAsk: status => isPlantingYear(status),
    help: {
      text: 'Planting in one month or another has different effects depending whether willow or miscanthus was selected.',
      links: [
        {url: 'https://homeguides.sfgate.com/plant-willow-tree-43316.html'},
        {url: 'https://www.gardenersworld.com/how-to/grow-plants/how-to-grow-miscanthus/'},
      ],
      neighbour: {
        text: "I can't say I know much about biomass crops. Autumn is the typical time for planting most cereal crops and hedging trees, and you can definitely sow grass seeds in autumn or spring. Hold on a minute… Willow and Miscanthus are vegetatively propagated crops, like spuds. You usually plant spuds in the spring. Does that help?",
      },
      expert: {
        text: 'To get the best establishment from Willow and Miscanthus you need a warm soil with a good deal of moisture. This will provide the ideal conditions for a Willow cutting or Miscanthus rhizomes to start producing roots and shoots. Mid-March is perfect for Willow but only if it’s dry enough to get on the land with machinery. Otherwise, your machine might get stuck. In dry, warm areas you can plant Miscanthus in March, but usually April or May is better. Sometimes, in really wet areas you can plant well into early summer. Planting in the autumn is not advised. Usually, the plant material will rot in the ground over the winter period and be a waste of time and money.',
        cost: 100,
      },
    },
    options: [
      {
        id: OptionId.planting_march,
        title: 'March',
        onChosen: status => {
          status.plantingTime = 'march';
          if (status.place?.id === PlaceId.dryshire) scaleYield(status, 1.15);
          if (status.place?.id === PlaceId.rainyshire) scaleYield(status, 0.85);
        },
        shortName: 'planting in March',
      },
      {
        id: OptionId.planting_may,
        title: 'May',
        // Neutral effect
        onChosen: status => {
          status.plantingTime = 'may';
          if (status.place?.id === PlaceId.dryshire) scaleYield(status, 0.85);
          if (status.place?.id === PlaceId.windyshire) scaleYield(status, 0.9);
        },
        shortName: 'planting in May',
      },
      {
        id: OptionId.planting_july,
        title: 'July',
        onChosen: status => {
          status.plantingTime = 'july';
          if (status.place?.id === PlaceId.dryshire) scaleYield(status, 0.7);
          if (status.place?.id === PlaceId.sunnyshire) scaleYield(status, 0.75);
          if (status.place?.id === PlaceId.windyshire) scaleYield(status, 0.85);
        },
        shortName: 'planting in July',
      },
      {
        id: OptionId.planting_september,
        title: 'September',
        onChosen: status => {
          status.plantingTime = 'september';
          scaleYield(status, 0.1);
          // TODO: Should this be: failed plantation, wait one more year to plant?
        },
        shortName: 'planting in September',
        penaltyPoints: 4,
      },
    ],
  },
  plantationType: {
    id: QuestionId.plantationType,
    subtitle: 'Having several varieties of plants can help against crop diseases.',
    title: 'Which kind of plantation do you want to make?',
    shouldAsk: status => isPlantingYear(status),
    help: {
      text: 'Having several varieties of plants can help against crop diseases. If a crop disease takes place, most of the crops will get lost.',
      links: [
        {url: 'https://simplicable.com/new/monoculture-vs-polyculture'},
        {
          url: 'https://www.outdoorhappens.com/polyculture-farming-what-is-it-and-why-is-it-better-than-monoculture/',
        },
      ],
      neighbour: {
        text: "Most of the crops we grow are monocultures – Wheat, Barley, Rapeseed Oil. That seems to work pretty well. Of course, we spray them to keep the weeds and pests under control. Can you spray big crops like Willow and Miscanthus? That could be expensive. I've heard experts on the TV say that mixing grass types together can increase the yield. It might be worth a try.",
      },
      expert: {
        text: 'In the UK we recommend planting Willow as an intimate mix of 6 or more varieties. The more diverse the varieties the better as this will help stop disease and pest incidents and maintain or even increase yield. It might be more expensive as you’ll have to shop around from different suppliers but could pay dividends in the long run. It’s true that diversity in a population can lead to more resilience. Charles Darwin noticed this with grasses – it is sometimes referred to as the Darwin Effect. Currently Miscanthus is typically planted as a monoculture. It would be a good idea to plant mixtures but more research needs to be done on this.',
        cost: 150,
      },
    },
    options: [
      {
        id: OptionId.plantation_mono,
        title: 'Monoculture',
        subtitle: 'A single variety, no extra cost',
        onChosen: status => (status.plantationType = 'monoculture'),
        penaltyPoints: 1,
      },
      {
        id: OptionId.plantation_light,
        title: 'Light mixture',
        subtitle: 'Similar varieties, not thoroughly mixed',
        costPerHa: 150,
        onChosen: status => (status.plantationType = 'light_mixture'),
        penaltyPoints: 1,
      },
      {
        id: OptionId.plantation_hard,
        title: 'Hard mixture',
        subtitle: 'Diverse varieties, intimate mix',
        costPerHa: 375,
        onChosen: status => {
          status.plantationType = 'hard_mixture';
          scaleYield(status, 1.1);
        },
        shortName: 'choosing a hard mixture',
      },
    ],
  },
  herbicide: {
    id: QuestionId.herbicide,
    subtitle: 'Herbicides help against weeds',
    title: 'Do you want to use a herbicide?',
    shouldAsk: status => isPlantingYear(status),
    help: {
      text: 'Weeds can significantly reduce yields.',
      links: [{url: 'https://phys.org/news/2011-01-miscanthus-chance-weeds.html'}],
      neighbour: {
        text: 'In my opinion, you need to get on top of the weeds, spraying is the only option.',
      },
      expert: {
        text: "Biomass crops are low input options. This means that using chemicals in a targeted and strategic fashion is often the best way to get the clean seed bed that you need. It is possible to be organic but this can involve membranes or mulches which are more expensive and don't really work at scale. It is also possible to remove weeds mechanically with an inter row cultivator.",
        cost: 125,
      },
    },
    options: [
      {
        id: OptionId.herbicide_yes,
        title: 'Use a herbicide',
        buyToolId: ToolId.herbicide,
      },
      {
        id: OptionId.herbicide_no,
        title: 'Continue without a herbicide',
        penaltyPoints: 1,
      },
    ],
    enableTools: [ToolId.herbicide],
  },
  rabbitProtection: {
    id: QuestionId.rabbitProtection,
    subtitle: "Now that your crops are growing, it's time to protect them against unwanted visitors.",
    title: 'How do you want to protect your crops against rabbits?',
    shouldAsk: status => isPlantingYear(status),
    help: {
      text: 'Having a fence or an employee protects against invasions by rabbits and deer. If an invasion occurs, the yield would decrease significantly.',
      links: [
        {url: 'https://yardenly.com/yard/do-deer-eat-willow-trees/'},
        {url: 'https://www.miscanthus.co.nz/the-plant/miscanthus-for-wildlife-and-game-cover/'},
      ],
      neighbour: {
        text: "I wouldn't bother with fencing, that will cost a small fortune. I’d handle the rabbits or get someone to do it for you.",
      },
      expert: {
        text: 'If rabbits are an issue and you do nothing then they will have a big impact on establishment. They often return to the same stem time and time again. A fence is 100% efficient but also expensive. It could be a really good investment if you really want to increase yield potential from the get go.',
        cost: 125,
      },
    },
    options: [
      {
        id: OptionId.rabbit_protection_no,
        title: 'No protection',
        subtitle: 'Let the bunnies run free',
        penaltyPoints: 2,
      },
      {
        id: OptionId.rabbit_protection_fence,
        title: 'Erect a fence',
        buyToolId: ToolId.fence,
      },
      {
        id: OptionId.rabbit_protection_employee,
        title: 'Hire an employee',
        subtitle: 'Deploy an employee to manage the rabbits',
        buyToolId: ToolId.employee,
        penaltyPoints: 1,
      },
    ],
    enableTools: [ToolId.fence, ToolId.employee],
  },
  deerProtection: {
    id: QuestionId.deerProtection,
    subtitle: 'A 5-meter strip of sorghum planted around your crop may act as deer deterrent.',
    title: 'Do you want to protect your crops against deer?',
    shouldAsk: status => status.crop?.id === CropId.willow && isPlantingYear(status),
    options: [
      {
        id: OptionId.deer_protection_no,
        title: 'No',
        penaltyPoints: 1,
      },
      {
        id: OptionId.deer_protection_strip,
        title: 'Plant a sorghum strip',
        buyToolId: ToolId.sorghum_strip,
      },
    ],
    enableTools: [ToolId.sorghum_strip],
  },
  // Planting year + 1
  gap_up: {
    id: QuestionId.gap_up,
    subtitle:
      'Some of the decisions you made last year led to a gappy crop. You could get back some of your lost yield potential by gapping up.',
    title: 'Do you want to gap up?',
    shouldAsk: status =>
      isPlantingYearPlus(status, 1) &&
      // Ask only if we had rabbits, deer or stuck machinery on plantation year
      eventOccurredAtYear(status, status.plantingYear, [EventId.rabbits_1, EventId.deer_1, EventId.stuck_planter]),
    help: {
      neighbour: {
        text: "Gapping up will be a nightmare. You'll probably struggle to find anyone who wants to do the job and they might do it wrong. I wouldn't bother if it was me.",
      },
      expert: {
        text: 'Gapping up can be effective if done properly, and at the right time. Walk through the crop and fill in the gaps with new Willow rods or Miscanthus plantlets. If planted early, there is a chance the new material will be able to compete with the crop as it begins to grow. In the long run you may get back some of the lost yield lost in the establishment year and it will also be easier to harvest. It’s another outlay on plant material and labour. Because of your earlier choices, you are playing catch up but it is probably a better option than doing nothing.',
        cost: 150,
      },
    },
    options: [
      {
        id: OptionId.gap_up_yes,
        title: 'Gap up',
        subtitle: 'Your yield will increase by 5%',
        costPerHa: 100,
        onChosen: status => {
          scaleYield(status, 1.05);
        },
        shortName: 'gapping up',
      },
      {
        id: OptionId.gap_up_no,
        title: "Don't gap up",
      },
    ],
  },
  interRowCultivator: {
    id: QuestionId.interRowCultivator,
    subtitle: 'Grass weeds have sprung up everywhere!',
    title: 'You can use an inter-row cultivator to cut off some of the weeds. Do you want to use it?',
    shouldAsk: status =>
      isPlantingYearPlus(status, 1) &&
      // Ask only if we had rabbits, deer or stuck machinery on plantation year
      eventOccurredAtYear(status, status.plantingYear, [
        EventId.weeds_rushed_willow_1,
        EventId.weeds_rushed_misc_1,
        EventId.weeds_no_herbicide_1,
      ]),
    options: [
      {
        id: OptionId.interRowCultivator_yes,
        title: 'Use an inter-row cultivator',
        costPerHa: 200,
        onChosen: status => {
          scaleYield(status, 1.25);
        },
        shortName: 'Using an inter-row cultivator',
      },
      {
        id: OptionId.interRowCultivator_no,
        title: 'Let my crop outcompete the weeds naturally',
      },
    ],
  },
  // Further ahead
  harvestTime: {
    id: QuestionId.harvestTime,
    title: 'When do you want to harvest?',
    subtitle: '',
    help: {
      neighbour: {
        text: "Most crops are harvested from mid summer onwards. I've seen biomass crops standing all the way through winter. Surely, when they've dropped their leaves is the best time? I wouldn't do it when it’s wet. That will make a right mess of your headlands and you'll get mud everywhere.",
      },
      expert: {
        text: 'Ideally, the best time to harvest would be when the crop is dormant (during winter). For Willow this means no leaves contaminating the woodchip. However, in our climate it’s usually too wet to harvest during December to February. This means that harvesting has to take place when the crop is in leaf and the ground is dry. Willow is often harvested in mid to late spring and from late summer onwards. Hardly ideal but that’s the only option until we get more sophisticated machinery. Miscanthus is generally harvested in February to April before the crop starts growing again.  This enables the winter rain to remove nutrients from the canes (that could cause harm to the boiler system).',
        cost: 105,
      },
    },
    shouldAsk: status => status.harvestTime === undefined && isHarvestYear(status),
    options: [
      {
        id: OptionId.harvestTime_october,
        title: 'October',
        onChosen: status => {
          status.harvestTime = 'october';
          scaleFuelQuality(status, 0.8);
          scaleYieldPermanently(status, 0.9);
        },
        shortName: 'harvesting in October',
      },
      {
        id: OptionId.harvestTime_december,
        title: 'December',
        onChosen: status => {
          status.harvestTime = 'december';
          scaleFuelQuality(status, 0.9);
          scaleYieldPermanently(status, 0.9);
        },
        shortName: 'harvesting in December',
      },
      {
        id: OptionId.harvestTime_february,
        title: 'February',
        onChosen: status => {
          status.harvestTime = 'february';
        },
        shortName: 'harvesting in February',
      },
      {
        id: OptionId.harvestTime_april,
        title: 'April',
        onChosen: status => {
          status.harvestTime = 'april';
          scaleFuelQuality(status, 0.9);
          scaleYieldPermanently(status, 0.9);
        },
        shortName: 'harvesting in April',
      },
    ],
  },
  dry: {
    id: QuestionId.dry,
    subtitle:
      "You'll soon get your first harvest. You will need to dry it before it's in a suitable form to be used to heat the school.",
    title: 'How will you dry it?',
    shouldAsk: status => {
      if (status.crop?.id === CropId.miscanthus && !eventOccurredAtYear(status, status.plantingYear, EventId.chips))
        return false;
      return isNHarvest(status, 1);
    },
    help: {
      text: 'Drying your crops with drying equipment will produce a higher quality product.',
      links: [
        {url: 'https://www.sciencedirect.com/topics/agricultural-and-biological-sciences/drying-equipment'},
        {url: 'https://www.mecmargroup.com/en/news/agricultural_dryer_what_it_is_and_why_you_should_use_it-31'},
      ],
      neighbour: {
        text: "I'm pretty sure that biomass chips will turn to compost if you leave them in a pile or out in the open. You'll probably need to turn it quite a bit to avoid it heating up. That seems like a lot of additional work. Mind you it’s quite expensive to store inside and force dry it with an underground heating system. It’s a tough one. I'm not sure.",
      },
      expert: {
        text: "To dry or not to dry? That is the question! It is costly to dry the fuel but typically the school needs an exact specification (moisture content of the fuel) in order for the boiler to work efficiently. By providing a dry product, you can achieve a higher price for the fuel so you’ll get any money invested back. The downside is that you’ll have extra upfront costs. By contrast, if you choose to air dry, most of the time you will achieve a good product but sometimes you won’t. This might cause complaints from customers, and you don't want that. Also, it will be a lower value product. So, you might be getting it in the ear and seeing less cash in your pocket. On balance, it’s a good idea to actively dry the product.",
        cost: 135,
      },
    },
    options: [
      {
        id: OptionId.dry_air,
        title: 'Air dry',
        subtitle: 'No cost to drying',
      },
      {
        id: OptionId.dry_force,
        title: 'Use some of your crop to force dry',
        subtitle: '£10 per tonne to dry, but £35 per tonne extra profit when selling crop',
        carbonEmissions: 20,
        onChosen: status => {
          const pricePerTonne = GameLogicError.assertNonNullish(status.prices.pricePerTonne);
          status.prices.pricePerTonne = pricePerTonne + 35;
          status.prices.dryCostPerTonne = 10;
        },
        shortName: 'force drying',
      },
    ],
  },
  beetles: {
    id: QuestionId.beetles,
    subtitle: 'Who likes beetles? These little bugs feed on leaves and can defoliate the plant.',
    title: 'Do you want to do something to prevent beetles?',
    shouldAsk: status => status.crop?.id === CropId.willow && isPlantingYearPlus(status, 1),
    help: {
      text: 'A beetle infestation can significantly reduce yields.',
      links: [{url: 'https://extension.umn.edu/yard-and-garden-insects/flea-beetles'}],
      neighbour: {
        text: 'Personally, I always get twitchy when I see critters on my crops. My gut feeling would be to spray ‘em. My daughter’s at agricultural college though and they advise that farmers need to work in harmony with nature.',
      },
      expert: {
        text: "Pest control with biomass crops is best achieved through mixing varieties. If you haven't done that at the start then there is a chance that beetles and other pests will eat their way through your crop. This doesn't happen every year though as pest populations often go through a boom and bust cycle. So if you accept a beetle infestation one year, chances are that the crop will grow back OK the next year. However, you will see an impact on yield. Much better to plant a hard mixture and avoid this. Spraying doesn't tend to work that well in these crops. They are tall for one thing that means you can only really spray the outsides. Also, some sprays take out beneficial insects as well (e.g. bees). On balance it is best to avoid the expense and let nature take its course. It won't look great but the crop should bounce back.",
        cost: 125,
      },
    },
    options: [
      {
        id: OptionId.beetles_no_spray,
        title: 'Let it be...',
      },
      {
        id: OptionId.beetles_spray,
        title: 'Spray',
        buyToolId: ToolId.beetles_spray,
        carbonEmissions: 10,
      },
    ],
    enableTools: [ToolId.beetles_spray],
  },
  councilTransport: {
    id: QuestionId.councilTransport,
    heading: 'Opportunity Knocks',
    subtitle:
      'The Council has made you an offer to transport the fuel from your farm. It’s for a fixed price that will reduce the price they pay for your fuel, but will save on your costs.',
    title: 'Do you accept their offer?',
    shouldAsk: status => {
      if (questionEverAsked(status, QuestionId.councilTransport)) return false;
      if (status.currentYear === 3 && status.history[status.currentYear].choices.length === 0) return true;
      if (status.currentYear === 4) return true;
      return false;
      // TODO: Randomise order of this and other opportunity knock questions
    },
    help: {
      neighbour: {
        text: "Sounds almost too good to be true! A lot less hassle for you, and no complaints about getting mud or chip on the road. I'd bite their hand off!",
      },
      expert: {
        text: 'A fixed price for transport is a good idea as the diesel price seems to be going up. This way you have a better idea of knowing what you will get, whereas by paying for transport yourself may eat into your profits. It is definitely the less risky option. It will also reduce your insurance premiums and any liability when delivering at the school.',
        cost: 150,
      },
    },
    options: [
      {
        id: OptionId.councilTransport_collect,
        title: 'Council collect',
        onChosen: status => {
          const pricePerTonne = GameLogicError.assertNonNullish(status.prices.pricePerTonne);
          status.prices.pricePerTonne = pricePerTonne - 10;
          status.prices.ownTransport = false;
        },
      },
      {
        id: OptionId.councilTransport_own,
        title: 'Own transport',
        onChosen: _status => {
          // No effect
        },
      },
    ],
  },
  newContractor_willow: {
    id: QuestionId.newContractor_willow,
    heading: 'Opportunity Knocks',
    subtitle:
      "You've received a leaflet through the door from a new contractor who covers your region. His catchphrase is “I’ve got a brand new biomass harvester, I'll cut down your tree”. He promises 10% extra yield from precision cutting.",
    title: "What's your choice?",
    shouldAsk: status => {
      if (status.crop?.id !== CropId.willow || questionEverAsked(status, QuestionId.newContractor_willow)) return false;
      if (status.currentYear === 5 && status.history[status.currentYear].choices.length === 0) return true;
      if (status.currentYear === 6) return true;
      return false;
      // TODO: Randomise order of this and other opportunity knock questions
    },
    help: {
      neighbour: {
        text: 'Regular Joe contractors might have old technology but they do get the job done. If I were you, I would go and see the kit in action first and make sure it works before committing.',
      },
      expert: {
        text: 'Improved biomass harvesting technology has been slow to develop but interest in the sector has led to greater investment and novel equipment that is better suited to the UK climate. This will definitely lead to improved yields, save on costs in the long run, be more efficient, and have lower greenhouse gas emissions.',
        cost: 150,
      },
    },
    options: [
      {
        id: OptionId.newContractor_stay,
        title: 'Stay with existing contractor',
        onChosen: _status => {
          // No effect
        },
      },
      {
        id: OptionId.newContractor_change,
        title: 'Use contractor with precision technology',
        onChosen: status => {
          scaleYieldPermanently(status, 1.1);
          status.prices.harvestCostPerTonne = 2;
        },
        shortName: 'Changing to a new contractor',
      },
    ],
  },
  newContractor_misc: {
    id: QuestionId.newContractor_misc,
    heading: 'Opportunity Knocks',
    subtitle:
      'You get a leaflet through the door from a new contractor in the local area. Their catchphrase is “Whether you want chips or bales, my kit never fails”. The leaflet promises 10% extra yield from precision cutting.',
    title: "What's your choice?",
    shouldAsk: status => {
      if (status.crop?.id !== CropId.miscanthus || questionEverAsked(status, QuestionId.newContractor_misc))
        return false;
      if (status.currentYear === 4 && status.history[status.currentYear].choices.length === 0) return true;
      if (status.currentYear === 5) return true;
      return false;
      // TODO: Randomise order of this and other opportunity knock questions
    },
    help: {
      neighbour: {
        text: 'Regular Joe contractors might have old technology but they do get the job done. If I were you, I would go and see the kit in action first and make sure it works before committing.',
      },
      expert: {
        text: 'Improved biomass harvesting technology has been slow to develop but interest in the sector has led to greater investment and novel equipment that is better suited to the UK climate. This will definitely lead to improved yields, save on costs in the long run, be more efficient, and have lower greenhouse gas emissions.',
        cost: 150,
      },
    },
    options: [
      {
        id: OptionId.newContractor_stay,
        title: 'Stay with existing contractor',
        onChosen: _status => {
          // No effect
        },
      },
      {
        id: OptionId.newContractor_change,
        title: 'Use contractor with precision technology',
        onChosen: status => {
          scaleYieldPermanently(status, 1.1);
          status.prices.harvestCostPerTonne = 2;
        },
        shortName: 'Changing to a new contractor',
      },
    ],
  },
  newMarket: {
    id: QuestionId.newMarket,
    heading: 'Opportunity Knocks',
    subtitle:
      "It’s all change at the Council. The new administration want to be even greener. They’ve decided to upgrade the school boiler but to one that isn't suitable for Miscanthus. Not to worry, they have other plans for your crop!",
    title: "What's your choice?",
    shouldAsk: status => {
      if (status.crop?.id !== CropId.miscanthus || questionEverAsked(status, QuestionId.newMarket)) return false;
      if (status.currentYear === 5 && status.history[status.currentYear].choices.length === 0) return true;
      if (status.currentYear === 6) return true;
      return false;
      // TODO: Randomise order of this and other opportunity knock questions
    },
    help: {
      neighbour: {
        text: "I'd sell it for bedding. That’s definitely backing the right horse. I went down to Farmer Palmer’s Country Stores the other day and I did a double take when I saw the price! You'll be laughing all the way to the bank.",
      },
      expert: {
        text: 'Both markets are lucrative and you could get a better price than selling the biomass for combustion. You’ll get a similar price for both bedding and as a raw material for miscrete. However, the miscrete option has a hidden benefit. Miscrete blocks lock carbon away in a stable manner for years whilst horse bedding rots and emits CO2 directly back into the atmosphere. As carbon trading takes off and farmers get paid a fair amount for this public service, any grower producing miscrete is likely to get a better return from their crop.',
        cost: 150,
      },
    },
    options: [
      {
        id: OptionId.newMarket_bedding,
        title: 'Animal bedding in the Community Farm',
        onChosen: status => {
          const pricePerTonne = GameLogicError.assertNonNullish(status.prices.pricePerTonne);
          status.prices.pricePerTonne = pricePerTonne + 20;
        },
      },
      {
        id: OptionId.newMarket_miscrete,
        title: 'Miscrete (eco concrete) blocks for a new youth club',
        onChosen: status => {
          const pricePerTonne = GameLogicError.assertNonNullish(status.prices.pricePerTonne);
          status.prices.pricePerTonne = pricePerTonne + 20;
        },
      },
    ],
  },
  application: {
    id: QuestionId.application,
    heading: 'Opportunity Knocks',
    subtitle:
      'There is a new Government backed scheme to reward projects involving biomass crops for the amount of carbon saved by the project. You can apply yourself or allow the council to fill out the forms on your behalf.',
    title: "What's your choice?",
    shouldAsk: status => {
      if (questionEverAsked(status, QuestionId.application)) return false;
      if (status.currentYear === 7 && status.history[status.currentYear].choices.length === 0) return true;
      if (status.currentYear === 8) return true;
      return false;
      // TODO: Randomise order of this and other opportunity knock questions
    },
    help: {
      neighbour: {
        text: 'There are way too many forms in farming as it is. If someone is willing to sort out all the red tape then that sounds great! I think I would let them deal with the hassle and just accept what they offer.',
      },
      expert: {
        text: 'Growers should be in a good position to benefit from trading their carbon in the marketplace. Being part of an accredited scheme is very important as this means that the project is less likely to be seen as green washing. The scheme is likely to be straightforward enough if you are prepared to read the guidance notes. This will mean more money in their pocket. They should be aware that they are not getting paid for every tonne of biomass produced. They will be paid for the carbon savings compared to the fossil fuel alternative. In this case by using 1 tonne of biomass you are saving 0.75 tonnes of carbon.',
        cost: 150,
      },
    },
    options: [
      {
        id: OptionId.application_myself,
        title: 'Apply myself',
        subtitle: 'Council pay grower a flat fee of £200 per year',
        onChosen: status => {
          status.prices.carbonCreditFlat = 200;
        },
      },
      {
        id: OptionId.application_council,
        title: 'Let the council apply for me',
        subtitle: 'Grower gets £{} per tonne C saved',
        compute: (state, option) => {
          const miscreteChosen = choiceMade(state, QuestionId.newMarket, OptionId.newMarket_miscrete);
          const creditAmount = miscreteChosen ? 25 : 20;
          option.subtitle = format(option.subtitle || '', creditAmount.toString());
          return option;
        },
        onChosen: status => {
          const miscreteChosen = choiceMade(status, QuestionId.newMarket, OptionId.newMarket_miscrete);
          const creditAmount = miscreteChosen ? 25 : 20;
          status.prices.carbonCreditPerTonne = creditAmount;
        },
      },
    ],
  },
};

export const getQuestion = (id: QuestionId) => QUESTIONS[id];
export const getOption = (questionId: QuestionId, optionId: OptionId) => {
  const question = QUESTIONS[questionId];
  if (!question) return undefined;
  return question.options.find(o => o.id === optionId);
};
