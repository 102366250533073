// Moisture Content (%) -> Calorific Value (kWh/tonne) for Residue Straws
// Taken from 'PEC Info'!R3:S13
const cvResidueStraws = {
  10: 4414,
  11: 4366,
  12: 4317,
  13: 4269,
  14: 4220,
  15: 4172,
  16: 4115,
};

export default cvResidueStraws;
