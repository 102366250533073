import Cookies from 'universal-cookie';

enum ServerCookie {
  authToken = 'authToken',
  newsletterClosed = 'newsletterClosed',
  cookieConsentGiven = 'cookieConsentGiven',
}

export const cookies = new Cookies();

// 400 days in seconds. This is the recommended limit for cookie's max-age according to the spec:
// https://httpwg.org/http-extensions/draft-ietf-httpbis-rfc6265bis.html#name-the-max-age-attribute
export const FOUR_HUNDRED_DAYS = 60 * 60 * 24 * 400;

export function setCookieConsentGiven() {
  cookies.set(ServerCookie.cookieConsentGiven, true, {
    maxAge: FOUR_HUNDRED_DAYS,
    path: '/',
  });
}

export function removeCookieConsentGiven() {
  cookies.remove(ServerCookie.cookieConsentGiven, {path: '/'});
}

export function getCookieConsentGiven() {
  return !!cookies.get(ServerCookie.cookieConsentGiven);
}

export default ServerCookie;
