// Source: https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/488478/Bulk_Data_Transfer_-_additional_validation_valid_from_12_November_2015.pdf
export const UK_POSTCODE_REGEX =
  '^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\\s*[0-9][A-Za-z]{2})$';

export async function requestGeolocatePostcode(postcode: string): Promise<{lat: string; lng: string}> {
  const response = await fetch(`https://postcodes.io/postcodes/${postcode}`);
  if (response.ok) {
    const json = await response.json();
    const lat = json?.result?.latitude;
    const lng = json?.result?.longitude;
    if (!lat || !lng) {
      throw new Error('Could not get coordinates');
    }
    return {lat, lng};
  }

  if (response.status === 404) {
    throw new Error('Invalid postcode');
  }

  throw new Error('An error occurred geolocating postcode');
}
