import classNames from 'classnames';
import React, {ComponentProps, ForwardedRef, PropsWithChildren, ReactElement} from 'react';

const Row = (
  {className, ...rest}: PropsWithChildren<ComponentProps<'div'>>,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement => {
  return <div ref={ref} className={classNames('flex flex-row', className)} {...rest} />;
};

export default React.forwardRef(Row);
