import {XMarkIcon} from '@heroicons/react/24/outline';
import React, {ReactElement, useCallback, useEffect, useState} from 'react';

import {CrownIcon} from '../../../../resources/Icons';
import Col from '../../../common/Col';
import Row from '../../../common/Row';
import {ScenarioInfo} from '../../../query/graphql';
import {fileToDataURL} from '../../util/image';
import SquareButton from '../buttons/SquareButton';
import {ImagePickerResult} from '../ImagePicker';
import Modal from '../Modal';
import EditScenarioForm from './EditScenarioForm';
import ScenarioImageButton from './ScenarioImageButton';
import UnlockForm from './UnlockForm';

type Props = {
  isOpen: boolean;
  title: string;
  scenario?: ScenarioInfo; // undefined means new scenario
  onSave?: (scenarioId: string) => void;
  onCancel?: () => void; // if undefined, close button is not displayed
  locked?: boolean;
};

const NewScenarioModal = ({isOpen, title, scenario, onSave, onCancel, locked}: Props): ReactElement => {
  const [error, setError] = useState<string | undefined>();
  const [newLogo, setNewLogo] = useState<File>();
  const [newLogoDataURL, setNewLogoDataURL] = useState<string>();
  const [loading, setLoading] = useState(false);
  const handleStartLoading = useCallback(() => setLoading(true), []);
  const handleEndLoading = useCallback(() => setLoading(false), []);

  useEffect(() => {
    const doAsync = async () => setNewLogoDataURL(newLogo ? await fileToDataURL(newLogo) : undefined);
    void doAsync();
  }, [newLogo]);

  const handleImageChange = useCallback(async ({file, error}: ImagePickerResult): Promise<void> => {
    if (error) {
      setError(error.message);
    } else {
      setError(undefined);
      setNewLogo(file);
    }
  }, []);

  return (
    <Modal isOpen={isOpen} title={title} className={'relative !grow-0'}>
      {onCancel && (
        <SquareButton
          className={'!absolute top-xs right-xs border-primary-800'}
          variant={'secondary'}
          onClick={onCancel}
          disabled={loading}
        >
          <XMarkIcon className={'w-sm'} />
        </SquareButton>
      )}
      <Col className={'mt-lg mb-md mx-xl gap-md'}>
        {locked && (
          <Row className={'mb-2 gap-4 text-sm text-primary-300'}>
            <CrownIcon className={'w-[1.5rem]'} />
            Premium feature
          </Row>
        )}
        <h1 className="font-semibold text-3xl leading-none">{title}</h1>
        {!locked && (
          <Col className={'gap-xs'}>
            <Row className={'gap-sm items-center'}>
              <ScenarioImageButton onChange={handleImageChange} src={newLogoDataURL} />
              <span className={'text-xs text-gray-200'}>
                Add logo
                <br /> or image
              </span>
            </Row>
            {error && <p className={'text-xs text-red-200'}>{error}</p>}
          </Col>
        )}
      </Col>
      {locked ? (
        <UnlockForm />
      ) : (
        <EditScenarioForm
          variant={'vertical'}
          scenario={scenario}
          logoFile={newLogo}
          onStartLoading={handleStartLoading}
          onEndLoading={handleEndLoading}
          onSave={onSave}
        />
      )}
    </Modal>
  );
};

export default NewScenarioModal;
