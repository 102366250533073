import {GraphQLError} from 'graphql/error';

export const DEFAULT_ERROR = 'internal-error';
export const DEFAULT_AUTH_ERROR = 'auth/internal-error';
export const SCENARIO_NOT_FOUND = 'scenario-not-found';
export const SCENARIO_LIMIT_REACHED = 'scenario-limit-reached';
export const SCENARIO_NAME_CONFLICT = 'scenario-name-conflict';
export const SCENARIO_NAME_TOO_LONG = 'scenario-name-too-long';
export const CANNOT_DELETE_LAST_SCENARIO = 'cannot-delete-last-scenario';
export const EMAIL_NOT_VERIFIED = 'email-not-verified';
export const MISSING_AUTHENTICATION = 'missing-authentication';
export const INSUFFICIENT_PERMISSION = 'insufficient-permission';
export const PRIVACY_CONSENT_REQUIRED = 'privacy-consent-required';
export const DB_CONNECTION_ERROR = 'db-connection-error';
export const INVALID_FILE_TYPE = 'invalid-file-type';
export const MAX_UPLOAD_SIZE_EXCEEDED = 'max-upload-size-exceeded';
export const INPUTS_LOCKED = 'inputs-locked';

export class GraphQLSingleError extends Error {
  constructor(
    public readonly message: string,
    public readonly locations: {line: number; column: number}[],
    public readonly path: string[],
    public readonly extensions: Record<any, any>
  ) {
    super(message);
  }
}

export class GraphQLReceivedError extends Error {
  constructor(public readonly message: string, public readonly errors: GraphQLSingleError[]) {
    super(message);
  }

  static parse(errors: any) {
    if (Array.isArray(errors) && errors.length) {
      return new GraphQLReceivedError(
        errors[0].message,
        errors.map((error: any) => new GraphQLSingleError(error.message, error.locations, error.path, error.extensions))
      );
    }
  }
}

type FieldError = {message: string; path: string[]};

export class EnvirocropsValidationError extends GraphQLError {
  constructor(public readonly message: string, fieldErrors: FieldError[]) {
    super(message);
    this.extensions.envirocrops = {name: 'ValidationError', details: {errors: fieldErrors}};
  }
}

export class CommonValidationError extends Error {
  constructor(public readonly message: string, public readonly fieldErrors: FieldError[], cause?: Error) {
    super(message);
    this.fieldErrors = fieldErrors;
    this.cause = cause;
  }
}

export function convertError(e: GraphQLSingleError): CommonValidationError | GraphQLSingleError {
  let validationErrors;
  const strapi = e.extensions?.strapi;
  const envirocrops = e.extensions?.envirocrops;

  if (strapi) {
    if (strapi.name === 'ValidationError' && Array.isArray(strapi.details.errors) && strapi.details.errors.length) {
      validationErrors = strapi.details.errors;
    }
  } else if (envirocrops) {
    if (
      envirocrops.name === 'ValidationError' &&
      Array.isArray(envirocrops.details.errors) &&
      envirocrops.details.errors.length
    ) {
      validationErrors = envirocrops.details.errors;
    }
  }

  if (validationErrors) {
    return new CommonValidationError(
      e.message,
      validationErrors.map((e: any) => ({message: e.message || 'Unknown error', path: e.path})),
      e
    );
  }

  return e;
}
