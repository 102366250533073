import classNames from 'classnames';
import React, {FC} from 'react';

import InfoCircle from '../../../../resources/InfoCircle';

type PropsType = {
  error?: string;
  info?: JSX.Element;
  className?: string;
};

const AdditionalInfo: FC<PropsType> = props => {
  const {error, info, className} = props;
  return (
    <div className={classNames('mt-3 text-xs text-center text-gray-200', className)}>
      {
        error ? (
          <span className={'text-red-200'}>
            <InfoCircle className={'inline mr-2'} />
            {error}
          </span>
        ) : info ? (
          info
        ) : (
          '\u00A0'
        ) // Add non-breaking space ensure the div has the same size regardless of error status (to avoid flickering)
      }
    </div>
  );
};

export default AdditionalInfo;
