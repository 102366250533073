import classNames from 'classnames';
import React, {ComponentProps} from 'react';

export type RichTextProps = Omit<ComponentProps<'div'>, 'children'> & {
  content?: string;
};

const RichText = ({className, content, ...props}: RichTextProps) => {
  if (!content) return null;
  return (
    <div
      className={classNames(
        '[&>*]:mt-0 [&>*:not(:last-child)]:mb-6',
        '[&>.image-style-align-left]:float-left [&>.image-style-align-left]:max-w-[50%] [&>.image-style-align-left]:mr-xs',
        '[&>.image-style-align-right]:float-right [&>.image-style-align-right]:max-w-[50%] [&>.image-style-align-right]:ml-xs',
        '[&>.image-style-align-center]:mx-auto [&>.image-style-align-center_img]:mx-auto',
        '[&_figcaption]:text-center [&_figcaption]:text-xs [&_figcaption]:text-gray-200 [&_figcaption]:italic',
        '[&_.image_figcaption]:mt-1',
        '[&_.table]:mx-auto',
        '[&_.table_figcaption]:table-caption [&_.table_figcaption]:caption-top [&_.table_figcaption]:mb-1',
        '[&_.table_th]:p-3 [&_.table_th]:border-thin [&_.table_th]:border-gray-100 [&_.table_th]:bg-gray-50',
        '[&_.table_td]:p-3 [&_.table_td]:border-thin [&_.table_td]:border-gray-100',
        className
      )}
      dangerouslySetInnerHTML={{__html: content}}
      {...props}
    />
  );
};

export default RichText;
