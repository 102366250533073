import classNames from 'classnames';
import React, {PropsWithChildren, ReactElement, useCallback} from 'react';

import {Option, SimpleValue} from '../decision/types';

type Props<T extends SimpleValue> = PropsWithChildren<{
  options: Option<T>[];
  selectedValue?: T | undefined;
  onOptionSelected: (value: T) => void;
  blurOnSelect?: boolean;
  // Applied to each option
  className?: string;
}>;

const DropdownOptions = <T extends SimpleValue>(props: Props<T>): ReactElement => {
  const {options, selectedValue, onOptionSelected, blurOnSelect, className} = props;

  const blurActiveElement = useCallback(() => {
    setTimeout(() => {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }, 0);
  }, []);

  return (
    <>
      {options.map((opt, idx) => (
        <button
          key={idx}
          type={'button'}
          className={classNames(
            'h-full flex flex-grow text-left flex-col p-4 !font-normal bg-white enabled:hover:bg-primary-100 outline-none border-thin border-transparent focus:border-primary-300 disabled:text-gray-200',
            className
          )}
          onClick={_ => {
            onOptionSelected(opt.value);
            if (blurOnSelect) blurActiveElement();
          }}
          disabled={opt.value == selectedValue}
        >
          {opt.label}
        </button>
      ))}
    </>
  );
};

export default DropdownOptions;
