import React, {ReactElement, useState} from 'react';

import DeerAnimation from '../../../../resources/animations/Deer.json';
import GameAudio from '../GameAudio';
import LottieAnimation from '../LottieAnimation';

const Deer = (): ReactElement => {
  const [isAnimating, setIsAnimating] = useState(true);
  return (
    <div className="absolute -bottom-20 left-0">
      <LottieAnimation
        className="w-[60rem] animate-deer"
        onAnimationEnd={() => setIsAnimating(false)}
        animation={DeerAnimation}
        loop={true}
      />
      <GameAudio src="sounds/deerRunning.mp3" volume={0.5} play={isAnimating} />
    </div>
  );
};

export default Deer;
