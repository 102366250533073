export enum CostOperationId {
  // Establishment - Detailed Land Preparation
  est_ploughing = 'ploughing',
  est_powerHarrowing = 'powerHarrowing',
  est_rolling = 'rolling',
  est_subSoiling = 'subSoiling',
  est_discing = 'discing',
  est_fertiliserSpreading = 'fertiliserSpreading',
  est_prePlantSpraying = 'prePlantSpraying',
  // Establishment - Detailed Weed Control
  est_spraying = 'spraying',
  est_prePlantingGlyphosate = 'prePlantingGlyphosate',
  est_postPlantingPreMWeedStompAqua = 'postPlantingPreMWeedStompAqua', // Miscanthus only
  est_postMWeedsHarmony = 'postMWeedsHarmony', // Miscanthus only
  est_postMWeedsStarane = 'postMWeedsStarane', // Miscanthus only
  est_postPlantingStompPendimethalin = 'est_postPlantingStompPendimethalin', // Willow only
  est_postPlantingFlexidorIsoxaben = 'est_postPlantingFlexidorIsoxaben', // Willow only
  est_postCutBackStompPendimethalin = 'est_postCutBackStompPendimethalin', // Willow only
  // Establishment - Detailed  Planting & plant material
  est_contractPlanting = 'contractPlanting',
  est_plantMaterial = 'plantMaterial',
  // Establishment - Detailed Pest Control
  est_rabbitFencing = 'rabbitFencing',
  est_grazers = 'grazers',
  est_sorghumStrip = 'sorghumStrip',
  est_treeGuards = 'est_treeGuards',
  est_shooting = 'shooting',
  // Establishment - Detailed  Remedial actions
  est_cuttingBack = 'cuttingBack',
  est_gapUpRods = 'gapUpRods',
  est_gappingUp = 'gappingUp',
  est_weedControlQuadBike = 'weedControlQuadBike',
  est_weedControlInterRowCultivator = 'weedControlInterRowCultivator',
  // Yr 1 & 2 costs
  yr1and2_weedControl = 'yr1and2_weedControl',
  yr1and2_pestControl = 'yr1and2_pestControl',
  // Growth
  growth_weedControl = 'growth_weedControl',
  growth_pruning = 'growth_pruning',
  growth_fertiliserSpreading = 'growth_fertiliserSpreading',
  // Harvest - Harvesting
  harvest_forageHarvester = 'harvest_forageHarvester',
  harvest_treeSheerGrab = 'harvest_treeSheerGrab',
  harvest_baling = 'harvest_baling',
  harvest_spraying = 'harvest_spraying',
  harvest_fertiliser = 'harvest_fertiliser',
  harvest_felling = 'harvest_felling',
  harvest_thinningAndHarvesting = 'harvest_thinningAndHarvesting',
  harvest_managementCost = 'harvest_managementCost',
  // Harvest - Transport
  harvest_transportFromFieldToStore = 'harvest_transportFromFieldToStore',
  harvest_transportFromStoreToClient = 'harvest_transportFromStoreToClient',
  harvest_chipping = 'harvest_chipping',
  harvest_drying = 'harvest_drying',
  // Management/Consultancy Charges
  mcc_consultant = 'mcc_consultant',
  mcc_professionalCharges = 'mcc_professionalCharges',
  mcc_annualConsultant = 'mcc_annualConsultant',
  mcc_RHIConsultant = 'mcc_RHIConsultant',
  mcc_BslSfrRegistration = 'mcc_BslSfrRegistration',
  // Clearfell
  clearfell_clearfell = 'clearfell_clearfell',
  clearfell_managementCost = 'clearfell_managementCost',
}
