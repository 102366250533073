import {PowerCapacityId} from '../../query/graphql';

export type PowerCapacity = {
  id: PowerCapacityId;
  description: string;
  fuelRequirement: number; // tonnes
};

export const POWER_CAPACITIES: {[key in PowerCapacityId]: {id: key} & PowerCapacity} = {
  small05: {id: PowerCapacityId.Small05, description: '05 MW Small', fuelRequirement: 32500},
  small10: {id: PowerCapacityId.Small10, description: '10 MW Small', fuelRequirement: 55000},
  medium30: {id: PowerCapacityId.Medium30, description: '30 MW Medium', fuelRequirement: 200000},
  large50: {id: PowerCapacityId.Large50, description: '50 MW Large', fuelRequirement: 320000},
};
