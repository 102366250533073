import {ComponentProps, FC} from 'react';

const InfoCircle: FC<ComponentProps<'svg'>> = props => (
  <svg height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="1.5">
      <circle cx="9" cy="8.817123" r="8" />
      <g strokeLinecap="round" strokeLinejoin="round" transform="translate(5.4 5.217123)">
        <path d="m3.59987848 6.18552472c-.11127285 0-.20147733.09020447-.20147733.20147733s.09020448.20147733.20147733.20147733c.11127286 0 .20147733-.09020447.20147733-.20147733s-.09020447-.20147733-.20147733-.20147733z" />
        <path d="m3.6.517217v3.73848" />
      </g>
    </g>
  </svg>
);

export default InfoCircle;
