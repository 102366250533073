import {CustomCost, UserGoal} from '../../query/graphql';
import COSTS from '../resources/costs';
import {CostOperationId} from '../resources/costs/operationNames';
import {CropId} from '../resources/crops';
import cvChip from './data/cvChip';
import cvPellets from './data/cvPellets';
import cvResidueStraws from './data/cvResidueStraws';
import cvStraw from './data/cvStraw';
import cvWoodPellets from './data/cvWoodPellets';
import {
  AnnualFinance,
  AnnualHarvest,
  CostCollection,
  CostCollectionDefinition,
  CostCollectionDefinitionSet,
  CostCollectionSet,
  CostOperation,
  CropMap,
  Fuel,
  HarvestYearDetails,
  Inputs,
  LandReport,
  WithCrop,
} from './types';
import {buildCropMap, sum} from './util';
import {requireValidPercentage} from './validation';

export const HECTARES_IN_50KM_CIRCLE = 785000; // PI * 50000^2 / 10000

export const ACRES_IN_ONE_HECTARE = 2.4710538146717;

/**
 * Inputs:
 * - fuelAmountDry: Amount of fuel dry matter
 * - fuelMC: Fuel Moisture Content (% - 0 to 100)
 * Output:
 * - Amount of green fuel at the given MC
 */
export function getFuelGreen(inputs: {fuelAmountDry: number; fuelMC: number}): number {
  requireValidPercentage(inputs.fuelMC, 'moisture content');
  return inputs.fuelAmountDry / (1 - inputs.fuelMC / 100);
}

/**
 * Inputs:
 * - fuelAmountGreen: Amount of fuel with the given Moisture Content (tonnes)
 * - fuelMC: Fuel Moisture Content (% - 0 to 100)
 * Output:
 * - amount of dry matter present in the given amount of fuel at the given MC
 */
export function getFuelDryMatter(inputs: {fuelAmountGreen: number; fuelMC: number}): number {
  return inputs.fuelAmountGreen * (1 - inputs.fuelMC / 100);
}

function minMaxCV(cvValues: object): {min: number; max: number} {
  const numericKeys = Object.keys(cvValues)
    .map(parseFloat)
    .filter(e => !isNaN(e));
  const min = Math.min(...numericKeys);
  const max = Math.max(...numericKeys);
  return {min, max};
}

/**
 * Inputs:
 * - cropType: The type of fuel to look up
 * - fuelMC: The moisture content of our fuel, if biomass (% - min and max defined by the type of fuel)
 * Output:
 * - calorific value of the given fuel (at the given moisture content)
 */
export function lookupFuelCV(inputs: {fuelType: Fuel | CropId; fuelMC?: number}): number {
  function requireUndefinedMC() {
    if (inputs.fuelMC !== undefined) {
      throw new Error(`moisture content does not apply to ${inputs.fuelType}`);
    }
  }

  function requireValue(cvValues: object) {
    if (inputs.fuelMC === undefined) {
      throw new Error(`moisture content is required for ${inputs.fuelType}`);
    }
    const value = (cvValues as any)[inputs.fuelMC.toFixed().toString()];
    if (value === undefined) {
      const {min, max} = minMaxCV(cvValues);
      throw new Error(`Moisture content for ${inputs.fuelType} must be between ${min}% and ${max}%`);
    }
    return value;
  }

  switch (inputs.fuelType) {
    case Fuel.oil:
      requireUndefinedMC();
      return 10.5; // TODO: Move to external file?
    case Fuel.lpg:
      requireUndefinedMC();
      return 6.8; // TODO: Move to external file?
    case Fuel.gas:
    case Fuel.electric:
      requireUndefinedMC();
      return 1;
    case Fuel.chip:
    case Fuel.woodchip:
    case CropId.willowChip:
      return requireValue(cvChip);
    case Fuel.straw:
    case CropId.miscanthusChip:
    case CropId.miscanthusBale:
      return requireValue(cvStraw);
    case Fuel.pellets:
      return requireValue(cvPellets);
    case Fuel.residue_straw:
      return requireValue(cvResidueStraws);
    case Fuel.wood_pellets:
      return requireValue(cvWoodPellets);
  }

  throw new Error(`invalid fuel: ${inputs.fuelType}`);
}

export const extractCropMap = <T extends Record<CropId, O>, O extends Record<K, V>, K extends string, V>(
  cropMap: T,
  key: K
): Record<CropId, T[CropId][K]> => {
  return buildCropMap(cropId => cropMap[cropId][key]);
};

// Function overload
export function cropArrayToCropMap<T>(cropArray: WithCrop<T | null> | null, getDefault?: undefined): CropMap<T | null>;

// Function overload
export function cropArrayToCropMap<T>(
  cropArray: WithCrop<T | null> | null,
  getDefault: (cropId: CropId) => T
): CropMap<T>;

/**
 * Return an object whose keys are crop IDs and values are matching element values from the given cropArray. If a
 * getDefault function is provided, it is used to get default values in case an element's value is `null`. Otherwise,
 * returned values for any of the crops may be `null`.
 *
 * @param cropArray the array to extract values from
 * @param getDefault if provided, it is used to return a default value when a value is `null`
 */
export function cropArrayToCropMap<T>(
  cropArray: WithCrop<T | null> | null,
  getDefault: ((cropId: CropId) => T) | undefined
): CropMap<T | null> {
  const getValue = (cropId: CropId) => cropArray?.find(e => e.cropId === cropId)?.value ?? getDefault?.(cropId) ?? null;

  return buildCropMap(getValue);
}

/**
 * Update the given cropArray, by adding or replacing the element matching the given cropId with the given value.
 * If value is null, the element is simply removed from the array. If the resulting array is empty, return null instead.
 * Original array  is not modified.
 *
 * @param cropId used to match the array element to add, update or remove
 * @param value the value to store along with cropId
 * @param cropArray the array to modify
 */
export const updateCropArray = <T extends any>(
  cropId: CropId,
  value: T,
  cropArray: WithCrop<T> | null
): WithCrop<T> | null => {
  const result = cropArray?.filter(e => e.cropId !== cropId) ?? [];

  if (value !== null) {
    result.push({cropId, value});
  }

  return result.length === 0 ? null : result;
};

/**
 * Merge a cost collection definition set with custom costs from user inputs.
 *
 * @param definition defines cost collection set categories and default values
 * @param costs custom costs from user inputs
 * @param cropId custom costs for this crop type are included in the returned object
 */
export function generateCostCollectionSet(
  definition: CostCollectionDefinitionSet,
  costs: Inputs['costs'],
  cropId: CropId
): CostCollectionSet {
  const generateCategory = (category: keyof CostCollectionDefinitionSet): CostCollection | null => {
    const categoryDefinition = definition[category];
    if (categoryDefinition === null) return null;
    return generateCostCollection(categoryDefinition, costs?.[category]?.find(e => e.cropId === cropId)?.value);
  };

  return {
    establishmentLand: generateCategory('establishmentLand'),
    establishmentPest: generateCategory('establishmentPest'),
    establishmentPlanting: generateCategory('establishmentPlanting'),
    establishmentRemedial: generateCategory('establishmentRemedial'),
    establishmentWeed: generateCategory('establishmentWeed'),
    yearOneAndTwo: generateCategory('yearOneAndTwo'),
    growth: generateCategory('growth'),
    harvestHarvesting: generateCategory('harvestHarvesting'),
    harvestTransport: generateCategory('harvestTransport'),
    managementAnnual: generateCategory('managementAnnual'),
    managementEstablishment: generateCategory('managementEstablishment'),
    clearfell: generateCategory('clearfell'),
  };
}

/**
 * Merge a cost collection definition with custom costs from user inputs.
 *
 * @param collection defines cost collection categories and default values
 * @param costs custom costs from user inputs
 */
export function generateCostCollection(
  collection: CostCollectionDefinition,
  costs: CustomCost[] | null | undefined
): CostCollection {
  return {
    ...collection,
    operations: collection.operations.map(op => {
      const customCost = costs?.find(c => (c.id as CostOperationId) === op.id);
      return {
        id: op.id,
        name: op.name,
        defaultCost: op.cost,
        modifier: op.modifier,
        cost: customCost?.cost ?? null,
        repetitions: customCost?.repetitions ?? op.repetitions ?? 1,
        enabled: customCost?.enabled ?? op.enabled,
        isDefault: !customCost,
      };
    }),
  };
}

/**
 * Build an array of CustomCost elements based on operations from the given cost collection. Only operations that differ
 * from the defaults in some way are included in the result.
 *
 * @param collection contains the operations to consider
 * @param cropId used to compare with default value
 * @param category used to compare with default value
 */
export function customCostsFromCostCollection(
  collection: CostCollection | null,
  cropId: CropId,
  category: keyof CostCollectionSet
): CustomCost[] | null {
  if (collection === null || collection.operations.length === 0) return null;

  const result: CustomCost[] = [];

  for (const operation of collection.operations) {
    if (operation.cost === null) {
      const defaultOp = COSTS[cropId][category]?.operations.find(op => op.id === operation.id);
      if (
        defaultOp &&
        defaultOp.enabled === operation.enabled &&
        (defaultOp.repetitions ?? 1) === operation.repetitions
      ) {
        // This operation is not different from the default, so we remove it from the result
        continue;
      }
    }
    result.push({
      id: operation.id,
      cost: operation.cost,
      enabled: operation.enabled,
      repetitions: operation.repetitions,
    });
  }

  return result.length === 0 ? null : result;
}

export function totalOperationCost(operation: CostOperation) {
  return operation.enabled ? (operation.cost ?? operation.defaultCost) * operation.repetitions : 0;
}

export function totalCollectionCost(collection: CostCollection, details?: HarvestYearDetails) {
  const operations = !details
    ? collection.operations
    : collection.operations.map(op => (op.modifier === undefined ? op : op.modifier(op, details)));
  return sum(operations.map(totalOperationCost));
}

export function absoluteCollectionCost(
  collection: CostCollection,
  landSize: number,
  yearHarvest: number,
  details: HarvestYearDetails
) {
  switch (collection.costUnit) {
    case 'flat':
      return totalCollectionCost(collection, details);
    case 'hectare':
      return totalCollectionCost(collection, details) * landSize;
    case 'tonne':
      return totalCollectionCost(collection, details) * yearHarvest;
  }
}

export function partialCollectionSetCost(
  set: CostCollectionSet,
  categories: readonly (keyof CostCollectionSet)[] = []
) {
  let total = 0;
  for (const category of categories) {
    const collection = set[category];
    if (collection) {
      total += totalCollectionCost(collection);
    }
  }
  return total;
}

/**
 * Returns true if the given collection only contains operations fulfilling `operation.isDefault === true`
 *
 * @param collection the collection to test
 */
export function isDefaultCollection(collection: CostCollection) {
  return collection.operations.every(op => op.isDefault);
}

/**
 * Returns true if all the given categories in the given set contain only default data.
 *
 * @param set the cost map containing the categories to test
 * @param categories keys in the cost map containing the categories to test
 */
export function allDefaultCollections(set: CostCollectionSet, categories: (keyof CostCollectionSet)[]) {
  for (const category of categories) {
    const collection = set[category];
    if (!collection) continue;
    if (!isDefaultCollection(collection)) return false;
  }
  return true;
}

// TODO: Document this function
export function switchMC(amount: number, originalMC: number, targetMC: number) {
  return (
    amount -
    (amount * originalMC) / 100 +
    (((amount - (amount * originalMC) / 100) / (1 - targetMC / 100)) * targetMC) / 100
  );
}

/**
 * @param landReport
 * @param targetEmissions kg CO2/MWh
 * @param baseEmissions kg CO2/MWh
 * @returns Number of extra tonnes CO2/year emitted for using the targetEmissions instead of baseEmissions in the current annualConsumption context
 */
export const getComparedEmissions = (
  landReport: LandReport | null | undefined,
  targetEmissions: number,
  baseEmissions: number
) => {
  const isSupply = landReport?.goal === UserGoal.SupplyProperty;
  const {heatConsumption} = (isSupply && landReport) || {};
  if (heatConsumption === null || heatConsumption === undefined)
    throw new Error('Heat consumption not found in land report');
  return Math.round(((targetEmissions - baseEmissions) * heatConsumption) / 1000000);
};

/**
 * @param emissions tonnes CO2
 * @returns Equivalent number of cars needed to produce the same amount of emissions in a year
 */
export const getCarsComparedEmissions = (emissions: number) => {
  // Car emissions estimations
  const emissionsPerKm = 170; // g CO2
  const kmPerYear = 15000;
  const tonnesCO2PerYear = (emissionsPerKm * kmPerYear) / 1000000; // t CO2/y
  const result = Math.round(emissions / tonnesCO2PerYear);
  return result;
};

/**
 * Get the number of years it takes for a crop with the given finance to return the investment. This is the index of the
 * first year in which closing balance is positive. This may be 0 if the crop doesn't have establishment year and the
 * first year already gives benefits.
 *
 * Result may be Infinity if the crop never returns the investment within plantation lifetime (final balance is always
 * negative).
 *
 * @param finance annual finance data
 */
export const getReturnOfInvestmentYear = (finance: AnnualFinance[]): number => {
  for (let i = 0; i < finance.length; i++) {
    if (finance[i].closingBalance > 0) return i;
  }
  return Infinity;
};

/**
 * Convert the given hectares to acres if imperialUnits is true, otherwise return the given hectares.
 *
 * @param hectares the given area in hectares
 * @param imperialUnits whether the output should be in acres
 */
export function convertArea(hectares: number, imperialUnits: boolean) {
  return imperialUnits ? hectares * ACRES_IN_ONE_HECTARE : hectares;
}

/**
 * Return the given area in hectares. If imperialUnits is true, the given area is assumed to be in acres and is converted,
 * otherwise the given area is returned as is.
 *
 * @param area the given area in hectares (if imperialUnits is false) or acres (if imperialUnits is true)
 * @param imperialUnits whether the input is in acres
 */
export function convertAreaReverse(area: number, imperialUnits: boolean) {
  return imperialUnits ? area / ACRES_IN_ONE_HECTARE : area;
}

export function convertValuePerHa(valuePerHa: number, imperialUnits: boolean) {
  return imperialUnits ? valuePerHa / ACRES_IN_ONE_HECTARE : valuePerHa;
}

export function convertValuePerAreaReverse(valuePerArea: number, imperialUnits: boolean) {
  return imperialUnits ? valuePerArea * ACRES_IN_ONE_HECTARE : valuePerArea;
}

export function getYearsToPeak(annualHarvest: AnnualHarvest[]): number {
  const result = annualHarvest.reduce(
    (acc, curr, currentIndex) => {
      const yearHarvest = sum(curr.actualHarvest);
      return yearHarvest <= acc.maxHarvest ? acc : {maxHarvest: yearHarvest, year: currentIndex};
    },
    {maxHarvest: 0, year: annualHarvest.length - 1}
  );

  return result.year + 1;
}
