import {XMarkIcon} from '@heroicons/react/24/outline';
import Image from 'next/legacy/image';
import {useCallback, useEffect, useState} from 'react';

import ServerCookie, {cookies, FOUR_HUNDRED_DAYS} from '../../../../cookies';
import Col from '../../../common/Col';
import {useEnvironment} from '../../../common/EnvironmentProvider';
import Button from '../buttons/Button';
import Modal from '../Modal';

const NewsletterModal = ({startOpen}: {startOpen: boolean}) => {
  const environment = useEnvironment();
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (startOpen) {
      setOpen(true);
    }
  }, [startOpen]);

  const handleClose = useCallback(() => {
    cookies.set(ServerCookie.newsletterClosed, true, {maxAge: FOUR_HUNDRED_DAYS});
    setOpen(false);
  }, []);

  return (
    <Modal isOpen={isOpen} className={'!grow-0 w-fit !rounded-[3rem]'}>
      <div className="bg-primary-600 text-white relative h-full w-full py-24 mobile:pb-12 mobile:pt-32 px-48 mobile:px-12">
        <div
          className="absolute top-6 right-6 p-4 rounded-2xl cursor-pointer border-thin border-white hover:border-primary-300"
          onClick={handleClose}
        >
          <XMarkIcon className="h-8 w-8" />
        </div>
        <Col className="items-center text-center h-full">
          <Image src="/img/newsletter-image.svg" height={110} width={314} alt="Newsletter image" />
          <div className="mt-md text-3xl font-semibold">{environment?.newsletter.title}</div>
          <div className="mt-4 text-xl">{environment?.newsletter.subtitle}</div>
          <Button
            variant="primary"
            className="mt-sm w-fit hover:bg-primary-700"
            href={environment?.newsletter.url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleClose}
          >
            {environment?.newsletter.button_text}
          </Button>
        </Col>
      </div>
    </Modal>
  );
};

export default NewsletterModal;
