import classNames from 'classnames';
import React, {ChangeEvent, ChangeEventHandler, FC, useCallback, useMemo} from 'react';

import Col from '../../../common/Col';
import Row from '../../../common/Row';
import {toFixed} from '../../decision/util';
import AdditionalInfo from './AdditionalInfo';

type PropsType = {
  value: [string, string, string] | undefined;
  total: number;
  units: string;
  onChange: (value: [string, string, string]) => void | Promise<void>;
  error?: string;
  disabled?: boolean;
};

const AreaInput = (props: {
  name: string;
  value: string;
  label: string;
  units: string;
  isError: boolean;
  disabled?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}) => {
  return (
    <Col className={'items-center gap-4'}>
      <label className={'mb-2 text-center'}>{props.label}</label>
      <Row className={'items-center gap-4'}>
        <input
          name={props.name}
          type={'text'}
          value={props.value}
          onChange={props.onChange}
          className={classNames('w-40', {error: props.isError})}
          pattern={'^(0|[1-9]\\d*)(\\.\\d+)?$'}
          title={'A positive number'}
          disabled={props.disabled}
        />
        <span>{props.units}</span>
      </Row>
      <AdditionalInfo error={props.isError ? 'Input needed' : undefined} />
    </Col>
  );
};

const QuestionTripleInput: FC<PropsType> = props => {
  const {units, total, onChange, error, disabled} = props;
  const value = useMemo(() => props.value ?? ['', '', toFixed(total, 2).toString()], [props.value, total]);
  const distributed = value[0] === value[1] && value[1] === value[2];

  const errors = value.map(e => {
    const number = parseFloat(e);
    return isNaN(number) || number <= 0;
  });

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const name = event.currentTarget.name;
      const currentValue = event.currentTarget.value;
      const currentNumber = parseFloat(currentValue);
      const otherValue = name === 'area1' ? value[1] : value[0];
      const otherNumber = parseFloat(otherValue);
      const thirdValue = toFixed(total - (currentNumber || 0) - (otherNumber || 0), 2).toString();

      if (name === 'area1') {
        await onChange([currentValue, otherValue, thirdValue]);
      } else {
        await onChange([otherValue, currentValue, thirdValue]);
      }
    },
    [onChange, total, value]
  );

  const handleDistribute = useCallback(async () => {
    const size = String(toFixed(total / 3, 2));
    await onChange([size, size, size]);
  }, [onChange, total]);

  return (
    <fieldset className={'flex flex-col items-stretch'}>
      <Row className={'items-center justify-center gap-md flex-wrap'}>
        <AreaInput
          name={'area1'}
          value={value[0] || ''}
          label={'Area planted year 1'}
          units={units}
          isError={!!error && errors[0]}
          disabled={disabled}
          onChange={handleChange}
        />
        <AreaInput
          name={'area2'}
          value={value[1] || ''}
          label={'Area planted year 2'}
          units={units}
          isError={!!error && errors[1]}
          disabled={disabled}
          onChange={handleChange}
        />
        <AreaInput
          name={'area3'}
          value={value[2] || ''}
          label={'Area planted year 3'}
          units={units}
          isError={!!error && errors[2]}
          disabled
          onChange={handleChange}
        />
      </Row>
      {!distributed && (
        <button type={'button'} className={'mt-4 text-sm text-primary-300'} onClick={handleDistribute}>
          Distribute value evenly
        </button>
      )}
    </fieldset>
  );
};

export default QuestionTripleInput;
