import classNames from 'classnames';
import React, {useCallback} from 'react';

import Col from '../../../common/Col';
import Row from '../../../common/Row';
import {useBreakpoint} from '../../../common/util/useBreakpoint';
import {Option, OptionValue} from '../../decision/types';
import AdditionalInfo from './AdditionalInfo';
import OptionView from './OptionView';

type PropsType<T extends OptionValue> = {
  options: Option<T>[];
  value: T | undefined;
  defaultValue?: T;
  onChange: (value: T) => void | Promise<void>;
  orientation?: 'vertical' | 'horizontal';
  error?: string;
};

const QuestionSelect = <T extends OptionValue>(props: PropsType<T>) => {
  const {options, value, defaultValue, onChange, orientation = 'vertical', error} = props;
  const {isMobile} = useBreakpoint('mobile');

  const Container = orientation === 'vertical' || isMobile ? Col : Row;

  const handleChange = useCallback(
    async (checked: boolean, val: T) => {
      if (checked) {
        await onChange(val);
      }
    },
    [onChange]
  );

  return (
    <>
      <Container className={classNames('gap-xs flex-wrap', {'text-center': orientation === 'horizontal'})}>
        {options?.map((opt, idx) => (
          <OptionView key={idx} option={opt} checked={value === opt.value ?? defaultValue} onChange={handleChange} />
        ))}
      </Container>
      <AdditionalInfo error={error} />
    </>
  );
};

export default QuestionSelect;
