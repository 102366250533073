import {CostCollectionDefinitionSet} from '../../decision/types';
import {CropId} from '../crops';
import glausescensEucalyptus1600Costs from './glausescensEucalyptus1600';
import glausescensEucalyptus2500Costs from './glausescensEucalyptus2500';
import hempDualCosts from './hempDual';
import hempFibreCosts from './hempFibre';
import hempSeedCosts from './hempSeed';
import miscanthusBaleCosts from './miscanthusBale';
import miscanthusChipCosts from './miscanthusChip';
import nitensEucalyptus1600Costs from './nitensEucalyptus1600';
import nitensEucalyptus2500Costs from './nitensEucalyptus2500';
import rcgCosts from './rcg';
import srcPoplarCosts from './srcPoplar';
import srcWillowCosts from './srcWillow';
import srfPoplar1600Costs from './srfPoplar1600';
import srfPoplar6600Costs from './srfPoplar6600';

const COSTS: {[key in CropId]: CostCollectionDefinitionSet} = {
  miscanthusBale: miscanthusBaleCosts,
  miscanthusChip: miscanthusChipCosts,
  willowChip: srcWillowCosts,
  srcPoplar: srcPoplarCosts,
  srfPoplar1600: srfPoplar1600Costs,
  srfPoplar6600: srfPoplar6600Costs,
  rcg: rcgCosts,
  nitensEucalyptus1600: nitensEucalyptus1600Costs,
  nitensEucalyptus2500: nitensEucalyptus2500Costs,
  glausescensEucalyptus1600: glausescensEucalyptus1600Costs,
  glausescensEucalyptus2500: glausescensEucalyptus2500Costs,
  hempFibre: hempFibreCosts,
  hempSeed: hempSeedCosts,
  hempDual: hempDualCosts,
};

export default COSTS;
