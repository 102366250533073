import {useMemo} from 'react';

import {LandUse} from '../types';

export enum LandUseId {
  permanent_pasture = 'permanent pasture',
  rough_grazing = 'rough grazing',
  temp_grassland = 'temporary grassland',
  arable = 'arable',
}

const LAND_USES: LandUse[] = [
  {
    id: LandUseId.arable,
    name: 'Arable',
    description:
      "This is the land on which crops are grown – wheat, barley, potatoes, carrots. The land is already well tended so you don't have to do much before you plant biomass crops. It’s the most valuable farmland.",
    preparationCost: 400,
  },
  {
    id: LandUseId.permanent_pasture,
    name: 'Permanent Pasture',
    description:
      'This land has been used for grazing animals for a long time – cows, sheep and horses. It takes a bit of work to get this land ready for growing biomass crops. Do it wrong and you may end up with lots of weeds and pesky pests. Do it right and you might end up with excellent yields.',
    preparationCost: 600,
  },
  {
    id: LandUseId.rough_grazing,
    name: 'Rough Grazing',
    description:
      'These fields are odd shapes, small, sloping, less nutritious, off the beaten track, and can be difficult to cultivate. Good planning and excellent husbandry is essential to get the best from biomass crops.',
    preparationCost: 700,
  },
  {
    id: LandUseId.temp_grassland,
    name: 'Temporary Grassland',
    description:
      'Green, green grass. Often farmers have a grass crop as part of their arable crop rotation. This helps restore fertility to the soil so subsequent crops can thrive. It’s not that difficult to follow this land use with biomass crops.',
    preparationCost: 450,
  },
];

export const useAllLandUses = () => useMemo(() => LAND_USES, []);
