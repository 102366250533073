import React, {ReactElement, useCallback} from 'react';

import ImagePicker, {ImagePickerResult} from '../ImagePicker';

type Props = {
  scenarioId?: string;
  src?: string;
  onChange: (result: ImagePickerResult, scenarioId?: string) => Promise<void>;
};

const ScenarioImageButton = ({scenarioId, src, onChange}: Props): ReactElement => {
  const handleChange = useCallback(
    async (result: ImagePickerResult) => {
      await onChange(result, scenarioId);
    },
    [onChange, scenarioId]
  );

  return <ImagePicker onChange={handleChange} src={src} alt={'scenario logo'} />;
};

export default ScenarioImageButton;
