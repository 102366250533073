import {PropertyId} from '../../query/graphql';

export type Property = {
  id: PropertyId;
  longDesc: string;
  shortDesc: string;
  annualConsumption: number; // kWh
};

export const PROPERTIES: {[key in PropertyId]: {id: key} & Property} = {
  house3beds: {
    id: PropertyId.House3beds,
    longDesc: '3-bed detached house',
    shortDesc: '3-bed detached house',
    annualConsumption: 20000,
  },
  house5beds: {
    id: PropertyId.House5beds,
    longDesc: '5-bed detached house',
    shortDesc: '5-bed detached house',
    annualConsumption: 45000,
  },
  largeFarmhouse: {
    id: PropertyId.LargeFarmhouse,
    longDesc: 'Large farmhouse, cottage and converted barn',
    shortDesc: 'Large farmhouse',
    annualConsumption: 100000,
  },
  golfClubhouse: {
    id: PropertyId.GolfClubhouse,
    longDesc: 'Golf clubhouse',
    shortDesc: 'Golf clubhouse',
    annualConsumption: 150000,
  },
  primarySchool: {
    id: PropertyId.PrimarySchool,
    longDesc: 'Primary school (200 pupils)',
    shortDesc: 'Primary school',
    annualConsumption: 200000,
  },
  smallPoultry: {
    id: PropertyId.SmallPoultry,
    longDesc: 'Small poultry producer (40,000 birds)',
    shortDesc: 'Small poultry producer',
    annualConsumption: 225000,
  },
  networkFarmhouse: {
    id: PropertyId.NetworkFarmhouse,
    longDesc: 'Network farmhouse, 8 newbuild holiday lets and swimming pool',
    shortDesc: 'Network farmhouse',
    annualConsumption: 350000,
  },
  careHome: {
    id: PropertyId.CareHome,
    longDesc: 'Care home (60 residents)',
    shortDesc: 'Care home',
    annualConsumption: 600000,
  },
  glasshouses: {
    id: PropertyId.Glasshouses,
    longDesc: 'Glasshouses (20,000 sq m)',
    shortDesc: 'Glasshouses',
    annualConsumption: 750000,
  },
  secondarySchool: {
    id: PropertyId.SecondarySchool,
    longDesc: 'Secondary school with pool (1500 pupils)',
    shortDesc: 'Secondary school',
    annualConsumption: 1000000,
  },
  villageHeating: {
    id: PropertyId.VillageHeating,
    longDesc: 'Village district heating scheme (66 properties)',
    shortDesc: 'Village district heating',
    annualConsumption: 1125000,
  },
};
