import {useQueryClient, UseQueryOptions, UseQueryResult} from '@tanstack/react-query';

import {
  Flow,
  ScenarioQuery,
  useResetScenariosMutation,
  useScenarioQuery,
  useSetTrackingMutation,
  useUpdateInputsMutation,
} from './graphql';
import queryClient from './queryClient';
import {invalidateAllFlowEdited, invalidateFlowEdited, useUpdateFlowEdited} from './useFlowEdited';
import {invalidateAllFlowProgress, invalidateFlowProgress} from './useFlowProgress';

export const emptyInputs: ScenarioQuery['scenario']['inputs'] = Object.freeze({
  goal: null,
  propertyType: null,
  heatConsumption: null,
  powerCapacity: null,
  fuelRequirement: null,
  cv: null,
  salePrice: null,
  grants: null,
  subsidies: null,
  isBorrowing: null,
  annualBorrowing: null,
  upfrontBorrowing: null,
  annualBorrowRepayment: null,
  stagger: null,
  fieldSizes: null,
  landSize: null,
  localYield: null,
  plantationLifetime: null,
  plantingYear: null,
  postcode: null,
  coordinates: null,
  costs: null,
  alreadyPlanted: null,
  plantedCropId: null,
  sortBy: null,
});

const scenarioQueryKey = (scenarioId: string) => useScenarioQuery.getKey({uuid: scenarioId});

export const useScenarioQueryWithDefaults = <T extends unknown>(
  scenarioId: string,
  options: UseQueryOptions<ScenarioQuery, Error, T>
) => {
  return useScenarioQuery(
    {uuid: scenarioId},
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      queryKey: scenarioQueryKey(scenarioId),
      useErrorBoundary: true, // Throw error instead of setting error property
      ...options,
    }
  );
};
export const useScenario = (scenarioId: string): UseQueryResult<ScenarioQuery['scenario']> =>
  useScenarioQueryWithDefaults(scenarioId, {select: data => data.scenario});

export const useInputs = (scenarioId: string): UseQueryResult<ScenarioQuery['scenario']['inputs']> =>
  useScenarioQueryWithDefaults(scenarioId, {
    select: data => data.scenario.inputs ?? emptyInputs,
  });

export const useTracking = (scenarioId: string) =>
  useScenarioQueryWithDefaults(scenarioId, {
    select: data => data.scenario.tracking,
  });

export const useUpdateInputs = (scenarioId: string, flow?: Flow, onError?: (error: unknown) => void) => {
  const queryClient = useQueryClient();
  const updateFlowEdited = useUpdateFlowEdited(scenarioId, flow ?? null);
  const mutation = useUpdateInputsMutation({
    onMutate: async newScenario => {
      // Snapshot the previous value
      const data = queryClient.getQueryData<ScenarioQuery>(scenarioQueryKey(scenarioId));
      const previousScenario = data?.scenario;
      const result = {scenario: {...previousScenario, inputs: {...previousScenario?.inputs, ...newScenario.inputs}}};
      // Optimistically update to the new value
      queryClient.setQueryData(scenarioQueryKey(scenarioId), result);
    },
    // Always refetch after error or success:
    onSettled: () => {
      invalidateScenario(scenarioId);
    },
    onSuccess: () => {
      if (flow) updateFlowEdited(true);
    },
    onError,
  });
  return mutation.mutateAsync;
};

export const useSetTracking = () => {
  const queryClient = useQueryClient();

  const mutation = useSetTrackingMutation({
    onMutate: async ({scenarioId, tracking}) => {
      // Snapshot the previous value
      const data = queryClient.getQueryData<ScenarioQuery>(scenarioQueryKey(scenarioId));
      const previousScenario = data?.scenario;
      const result = {scenario: {...previousScenario, tracking}};
      // Optimistically update to the new value
      queryClient.setQueryData(scenarioQueryKey(scenarioId), result);
    },
    // Always refetch after error or success:
    onSettled: (_d, _e, variables) => {
      invalidateScenario(variables.scenarioId);
    },
  });
  return mutation.mutateAsync;
};

export const useResetScenarios = () => {
  const mutation = useResetScenariosMutation({onSuccess: invalidateAllScenarios});
  return async () => await mutation.mutateAsync({});
};

export const invalidateScenario = (scenarioId: string) => {
  queryClient.invalidateQueries(scenarioQueryKey(scenarioId));
  invalidateFlowProgress(scenarioId);
  invalidateFlowEdited(scenarioId);
};

export const invalidateAllScenarios = () => {
  // Pop variables to invalidate query for all scenarios
  queryClient.invalidateQueries(scenarioQueryKey('x').slice(0, -1));

  invalidateAllFlowProgress();
  invalidateAllFlowEdited();
};

export default useScenario;
