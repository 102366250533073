export enum CropFamilyId {
  miscanthus = 'miscanthus',
  willow = 'willow',
  eucalyptus = 'eucalyptus',
  srfPoplar = 'srfPoplar',
  rcg = 'rcg',
  srcPoplar = 'srcPoplar',
  hemp = 'hemp',
}

export type CropFamily = {
  id: CropFamilyId;
  name: string;
  isWoody: boolean;
};

export const CROP_FAMILIES: CropFamily[] = [
  {id: CropFamilyId.miscanthus, name: 'Miscanthus', isWoody: false},
  {id: CropFamilyId.willow, name: 'Willow', isWoody: true},
  {id: CropFamilyId.eucalyptus, name: 'Eucalyptus', isWoody: true},
  {id: CropFamilyId.srfPoplar, name: 'SRF Poplar', isWoody: true},
  {id: CropFamilyId.rcg, name: 'Reed Canary Grass', isWoody: false},
  {id: CropFamilyId.srcPoplar, name: 'SRC Poplar', isWoody: true},
  {id: CropFamilyId.hemp, name: 'Hemp', isWoody: false},
];
