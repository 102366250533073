// Moisture Content (%) -> Calorific Value (kWh/tonne) for Pellets
// Taken from 'PEC Info'!L4:M44
const cvPellets = {
  10: 4700,
  11: 4650,
  12: 4600,
  13: 4550,
  14: 4500,
  15: 4450,
  16: 4400,
  17: 4350,
  18: 4300,
  19: 4250,
  20: 4200,
  21: 4150,
  22: 4100,
  23: 4050,
  24: 4000,
  25: 3950,
  26: 3900,
  27: 3850,
  28: 3800,
  29: 3750,
  30: 3700,
  31: 3650,
  32: 3600,
  33: 3550,
  34: 3500,
  35: 3450,
  36: 3400,
  37: 3350,
  38: 3300,
  39: 3250,
  40: 3200,
  41: 3150,
  42: 3100,
  43: 3050,
  44: 3000,
  45: 2950,
  46: 2900,
  47: 2850,
  48: 2800,
  49: 2750,
  50: 2700,
};

export default cvPellets;
