import classNames from 'classnames';
import React, {ChangeEvent, ComponentProps, FC, useCallback} from 'react';
import ReactSlider from 'react-slider';

import Row from '../../../common/Row';
import AdditionalInfo from './AdditionalInfo';

type PropsType = Omit<ComponentProps<'input'>, 'onChange'> & {
  value: string | undefined;
  min: number;
  max: number;
  units?: string;
  unitsInSlider?: boolean;
  onChange: (value: string) => void | Promise<void>;
  error?: string;
  info?: JSX.Element;
};

const QuestionSlider: FC<PropsType> = props => {
  const {value, min, max, units, unitsInSlider = true, onChange, error, info, ...otherProps} = props;
  let numericValue = parseFloat(value ?? '0');
  if (isNaN(numericValue)) numericValue = 0;

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const currentValue = event.currentTarget.value;
      await onChange(currentValue);
    },
    [onChange]
  );

  const handleChangeSlider = useCallback(
    async (value: number) => {
      await onChange(value.toString());
    },
    [onChange]
  );

  return (
    <fieldset className={'flex flex-col items-stretch'}>
      <Row className={'items-center'}>
        <span className={'flex-1'} />
        <input
          type={'number'}
          value={value}
          onChange={handleChange}
          min={min}
          max={max}
          className={classNames({error: error !== undefined}, {'flex-1': !units}, {'w-40': !!units})}
          {...otherProps}
        />
        <span className={'flex-1'}>
          <span className={'ml-4'}>{units}</span>
        </span>
      </Row>
      <ReactSlider
        className="slider"
        thumbClassName="slider-thumb"
        trackClassName="slider-track"
        value={numericValue}
        onChange={handleChangeSlider}
        min={min}
        max={max}
      />
      <Row className={'mt-7 justify-between'}>
        <span>
          {min}
          {unitsInSlider && units}
        </span>
        <span>
          {max}
          {unitsInSlider && units}
        </span>
      </Row>
      <AdditionalInfo error={error} info={info} />
    </fieldset>
  );
};

export default QuestionSlider;
