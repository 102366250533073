import classNames from 'classnames';
import React, {ReactElement} from 'react';

import Weed from '../../../../resources/animations/Weed.json';
import Row from '../../../common/Row';
import {useBreakpoint} from '../../../common/util/useBreakpoint';
import LottieAnimation from '../LottieAnimation';

const BASE_BOTTOM_DISTANCE = 4; // rem
const CROP_HEIGHT = 3; // rem
const N_CROPS_WEB = 8;
const N_CROPS_MOBILE = 4;

const Weeds = (): ReactElement => {
  const {isMobile} = useBreakpoint('mobile');
  const cropsPerRow = isMobile ? N_CROPS_MOBILE : N_CROPS_WEB;
  return (
    <>
      {[cropsPerRow, cropsPerRow - 1].map((nCrops, rowIdx) => (
        <Row
          key={`weed_row_${rowIdx}`}
          className="absolute w-full justify-center gap-x-24"
          style={{bottom: `${BASE_BOTTOM_DISTANCE + CROP_HEIGHT * rowIdx}rem`}}
        >
          {Array(nCrops)
            .fill(null)
            .map((_, colIdx) => {
              const inverted = Math.random() > 0.5;
              return (
                <LottieAnimation
                  key={`weed_${rowIdx}_${colIdx}`}
                  className={classNames('w-[10rem]', {'-scale-x-100': inverted})}
                  animation={Weed}
                />
              );
            })}
        </Row>
      ))}
    </>
  );
};

export default Weeds;
