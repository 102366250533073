import classNames from 'classnames';
import React, {PropsWithChildren, ReactElement} from 'react';

import Row from '../../../common/Row';

type Props = {
  className?: string;
};

const QuestionFooter = ({children, className}: PropsWithChildren<Props>): ReactElement => {
  return (
    <footer className={classNames('w-full py-sm border-t border-gray-100 flex justify-center bg-white', className)}>
      <Row className="max-w-limit w-full mx-sm justify-between">{children}</Row>
    </footer>
  );
};

export default QuestionFooter;
