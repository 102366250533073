import React, {FC, useEffect, useState} from 'react';

import {Flow} from '../../../query/graphql';
import {useUpdateFlowProgress} from '../../../query/useFlowProgress';

type PropsType = {
  max: number;
  current: number;
  persistAs?: Flow;
  scenarioId: string | null;
};

const ProgressBar: FC<PropsType> = props => {
  const {max, current, persistAs, scenarioId} = props;
  const [lastCurrent, setLastCurrent] = useState(current);
  const [progress, setProgress] = useState((current / max) * 100);
  const updateFlowProgress = useUpdateFlowProgress(scenarioId, persistAs ?? null);

  useEffect(() => {
    const newProgress = (current / max) * 100;

    if (current >= lastCurrent) {
      // Bar can only grow
      setProgress(prevState => (newProgress > prevState ? newProgress : prevState));
    } else {
      // Bar can only shrink
      setProgress(prevState => (newProgress < prevState ? newProgress : prevState));
    }

    setLastCurrent(current);
  }, [current, lastCurrent, max]);

  useEffect(() => {
    if (scenarioId && persistAs && typeof progress === 'number') updateFlowProgress(progress / 100);
  }, [persistAs, progress, scenarioId, updateFlowProgress]);

  const innerStyle = {width: `${progress}%`};
  return (
    <div className={'sticky top-0 shrink-0 h-[0.8rem] w-full bg-gray-100'}>
      <div className={'h-full bg-primary-300 transition-width duration-200'} style={innerStyle} />
    </div>
  );
};

export default ProgressBar;
