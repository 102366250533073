import classNames from 'classnames';
import Image from 'next/image';
import {StaticImageData} from 'next/legacy/image';
import React, {CSSProperties, ReactElement} from 'react';

import {PhotoIcon, Spinner} from '../../../resources/Icons';
import Row from '../../common/Row';

type Props = {
  size: 'tiny' | 'smaller' | 'small' | 'medium' | 'largish' | 'large';
  src: string | StaticImageData | undefined | null;
  alt: string;
  // If true, default photo icon appears over the image on hover
  hoverEffect?: boolean;
  loading?: boolean;
  unoptimized?: boolean;
  className?: string;
  imageFit?: CSSProperties['objectFit'];
};

const CircleImage = ({
  size,
  src,
  alt,
  hoverEffect,
  loading,
  unoptimized,
  className,
  imageFit = 'cover',
}: Props): ReactElement => {
  const imageSrc = src;

  return (
    <div
      className={classNames(
        'relative rounded-full shrink-0 overflow-hidden border border-gray-100 flex items-center justify-center text-white',
        imageSrc ? 'bg-white' : 'bg-gray-100',
        !loading && hoverEffect && 'hover:!border-primary-300 ',
        {
          'w-[2.4rem] h-[2.4rem]': size === 'tiny',
          'w-[2.9rem] h-[2.9rem]': size === 'smaller',
          'w-[5rem] h-[5rem]': size === 'small',
          'w-[6.5rem] h-[6.5rem]': size === 'medium',
          'w-[10rem] h-[10rem]': size === 'largish',
          'w-[15rem] h-[15rem]': size === 'large',
        },
        className
      )}
    >
      {imageSrc && (
        <Image
          src={imageSrc}
          alt={imageSrc ? alt : 'default image'}
          fill
          style={{objectFit: imageFit}}
          className={classNames(imageFit === 'contain' && 'p-xs')}
          unoptimized={unoptimized}
        />
      )}
      {(!imageSrc || loading || hoverEffect) && (
        <Row
          className={classNames(
            'absolute inset-0 items-center justify-center',
            imageSrc && !loading && hoverEffect && '[&>*]:invisible [&>*]:hover:visible',
            loading && 'bg-primary-700 bg-opacity-25',
            hoverEffect && 'hover:bg-primary-700 hover:bg-opacity-25'
          )}
        >
          {loading ? (
            <Spinner />
          ) : (
            <PhotoIcon className={classNames((size === 'smaller' || size === 'tiny') && 'w-[1.6rem]')} />
          )}
        </Row>
      )}
    </div>
  );
};

export default CircleImage;

function isValidUrl(url: string): boolean {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
}
