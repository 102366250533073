import {useRouter} from 'next/router';
import React, {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {notify} from 'src/features/common/util/bugsnag';

import {PRIVACY_CONSENT_REQUIRED} from '../../../../controllers/errors';
import Col from '../../../common/Col';
import {useEnvironment, useFirebaseAuth} from '../../../common/EnvironmentProvider';
import Row from '../../../common/Row';
import {CreateUserInput} from '../../../query/graphql';
import {useUpdateUser} from '../../../query/useCurrentUser';
import Button from '../buttons/Button';
import Checkbox from '../Checkbox';
import Modal from '../Modal';
import RichText from '../RichText';

type FormFields = Pick<CreateUserInput, 'consentPrivacy' | 'consentNews'>;

type Props = {
  isOpen: boolean;
};

const PrivacyConsentModal = ({isOpen}: Props) => {
  const router = useRouter();
  const environment = useEnvironment();
  const signup = environment?.signup;
  const updateUser = useUpdateUser();
  const firebaseAuth = useFirebaseAuth();

  const {
    register,
    handleSubmit,
    setError,
    formState: {errors},
  } = useForm<FormFields>();

  const onSubmit = useCallback(
    async ({consentPrivacy, consentNews}: FormFields) => {
      try {
        await updateUser({data: {consentPrivacy, consentNews}});
      } catch (error) {
        const message = error instanceof Error ? error.message : undefined;
        switch (message) {
          case PRIVACY_CONSENT_REQUIRED:
            return setError('consentPrivacy', {type: 'custom', message: 'You must consent to our privacy policy'});
          default:
            notify(error instanceof Error ? error : String(error));
            return setError('consentNews', {type: 'custom', message: 'Something went wrong. Please try again later'});
        }
      }
    },
    [setError, updateUser]
  );

  const handleSignOut = useCallback(async () => {
    await router.push({query: {...router.query, signout: true}});
    await firebaseAuth?.signOut(); // This will cause a redirect to Home
  }, [firebaseAuth, router]);

  return (
    <Modal isOpen={isOpen} className={'!grow-0 w-fit'}>
      <form className="grow flex flex-col p-sm space-y-sm" onSubmit={handleSubmit(onSubmit)}>
        <h1>{signup?.privacy_modal_title}</h1>
        <RichText content={signup?.privacy_modal_message} />
        <Col className={'gap-1'}>
          <Row className={'gap-3'}>
            <Checkbox id="consent_privacy" {...register('consentPrivacy')} />
            <label htmlFor="consent_privacy">
              <RichText content={signup?.consent_privacy} />
            </label>
          </Row>
          {errors.consentPrivacy && <p className="text-xs text-red-200">{errors.consentPrivacy.message}</p>}
        </Col>
        <Col className={'gap-1'}>
          <Row className={'gap-3'}>
            <Checkbox id="consent_news" {...register('consentNews')} />
            <label htmlFor="consent_news">
              <RichText content={signup?.consent_news} />
            </label>
          </Row>
          {errors.consentNews && <p className="text-xs text-red-200">{errors.consentNews.message}</p>}
        </Col>
        <Row className={'justify-between gap-xs pt-sm'}>
          <Button type="button" variant={'secondary'} onClick={handleSignOut}>
            Sign out
          </Button>
          <Button type="submit" variant="primary">
            Submit
          </Button>
        </Row>
      </form>
    </Modal>
  );
};

export default PrivacyConsentModal;
