import {GameLogicError} from './errors';
import {CropId} from './resources/crops';
import {EventId} from './resources/events';
import {getOption, OptionId, QuestionId} from './resources/questions';
import {requireToolById, ToolId} from './resources/tools';
import {ComputedQuestion, Crop, Option, Question, Status} from './types';

// Returns whether we are on planting year
export const isPlantingYear = (status: Status) => {
  return status.currentYear === status.plantingYear;
};

// Returns whether we are `years` years after planting year
export const isPlantingYearPlus = (status: Status, years: number) => {
  return status.currentYear === status.plantingYear + years;
};

// Returns whether we are in any year after planting year
export const isAfterPlantingYear = (status: Status) => {
  return status.currentYear > status.plantingYear;
};

/**
 * Returns the number of years elapsed of the current harvest season, including current year.
 * - If have never harvested yet, that's the number of years since planting year (included)
 * - If we have harvested, that's the number of years since last harvest year (excluded).
 * Example: We planted on year 0, we haven't yet harvested, and we are on year 2. Length returned --> 3
 * Example: We last harvested on year 2, and we are on year 4. Length returned --> 2
 */

export const harvestSeasonLength = ({
  crop,
  currentYear,
  plantingYear,
  harvestYears,
}: {
  crop?: Crop;
  currentYear: number;
  plantingYear: number;
  harvestYears: number[];
}) => {
  if (!crop) return 0;
  const lastHarvestYear = harvestYears[harvestYears.length - 1];
  if (lastHarvestYear === undefined) {
    return crop.id === CropId.willow ? currentYear - plantingYear + 1 : currentYear - plantingYear;
  } else {
    return currentYear - lastHarvestYear;
  }
};

/**
 * Returns whether this should be a harvest year, disregarding events that happened this year.
 */
export const shouldBeHarvestYear = (status: Status) => {
  const {currentYear, plantingYear} = status;
  if (currentYear <= plantingYear) return false;
  const crop = GameLogicError.assertNonNullish(status.crop);

  return harvestSeasonLength(status) >= crop.harvestingPeriod;
};

/**
 * Returns whether this is a harvest year, that is, this `shouldBeHarvestYear` and no events occurred this year that
 * prevent harvesting. Event that prevent harvesting is `EventId.flood`.
 *
 * WARNING: Don't call this function if `phase` is lower than `events`, as it can give the wrong result.
 * This will work properly when called exactly at `events` phase, as long as all the events preventing harvesting
 * (e.g. `flood`) occur during the `jeopardy` phase.
 */
export const isHarvestYear = (status: Status) => {
  return shouldBeHarvestYear(status) && !eventOccurredAtYear(status, status.currentYear, EventId.flood);
};

/**
 * Returns how many years we have to wait until harvest. If 0, this is a harvest year. If negative, harvest is due,
 * but we couldn't harvest for some reason. If undefined, we haven't yet chosen a crop.
 */
export const yearsLeftForHarvesting = (status: Status) => {
  if (!status.crop) return undefined;
  return status.crop.harvestingPeriod - harvestSeasonLength(status);
};

/**
 * Return true if ANY of the given events occurred at all.
 */
export const eventOccurred = (status: Status, eventId: EventId | EventId[]) => {
  return status.history.some((_, year) => eventOccurredAtYear(status, year, eventId));
};

/**
 * Return true if ANY of the given events occurred on the given year.
 */
export const eventOccurredAtYear = (status: Status, year: number, eventId: EventId | EventId[]) => {
  return status.history[year].events.some(e => (Array.isArray(eventId) ? eventId.includes(e.id) : e.id === eventId));
};

/**
 * Finds a choice with matching question ID in year. Returns the first match
 */
export const findChoiceInYear = (status: Status, year: number, questionID: QuestionId) => {
  for (const choice of status.history[year].choices) {
    if (choice.question === questionID) return choice;
  }
  return undefined;
};

/**
 * Finds a choice with matching question ID in history. Returns the first match
 */
export const findChoiceInHistory = (status: Status, questionID: QuestionId) => {
  for (const year of status.history) {
    for (const choice of year.choices) {
      if (choice.question === questionID) return choice;
    }
  }
  return undefined;
};

export const choiceMadeInYear = (status: Status, year: number, questionId: QuestionId, optionId: OptionId) => {
  const choice = findChoiceInYear(status, year, questionId);
  if (!choice || !choice.option) return false;
  return choice.option.id === optionId;
};

export const choiceMade = (status: Status, questionId: QuestionId, optionId: OptionId) => {
  const choice = findChoiceInHistory(status, questionId);
  if (!choice || !choice.option) return false;
  return choice.option.id === optionId;
};

/**
 * Returns true if we are on the N-th harvest year.
 *
 * WARNING: The same restriction described in `isHarvestYear` applies here.
 */
export const isNHarvest = (status: Status, nHarvest: number) => {
  // There have been n-1 harvests, and it's a harvesting year
  return status.harvestYears.length === nHarvest - 1 && isHarvestYear(status);
};

export const normalizeMoney = (money: number) => Math.abs(Math.floor(money));

export const formatMoney = (money: number) => {
  return `${money < 0 ? '-' : ''}£${normalizeMoney(money).toLocaleString('en-UK')}`;
};

/**
 * Round the given amount to 2 fraction digits. If the resulting number has a non-zero fractional part, choose exactly 2
 * fraction digits. Otherwise, choose 0. Return the resulting number localised, with prepended '£' and (if negative)
 * its sign.
 */
export const formatSmallMoney = (money: number) => {
  const rounded = Math.round(money * 100) / 100; // Round to 2 fraction digits
  const fractionDigits = rounded === Math.trunc(rounded) ? 0 : 2; // If any number of fraction digit is needed, pick 2 digits

  return `${money < 0 ? '-' : ''}£${Math.abs(money).toLocaleString('en-UK', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })}`;
};

export const computeCost = (status: Status, costPerHa?: number) => {
  if (!costPerHa) return undefined;
  const landSize = GameLogicError.assertNonNullish(status.landSize);
  return normalizeMoney(costPerHa * landSize);
};

export const computeTonnesHarvested = (status: Status) => {
  const currentYield = GameLogicError.assertNonNullish(status.currentYield);
  const landSize = GameLogicError.assertNonNullish(status.landSize);
  return currentYield * landSize * harvestSeasonLength(status);
};

export const computeFuelPrice = (status: Status) => {
  const pricePerTonne = GameLogicError.assertNonNullish(status.prices.pricePerTonne);
  return pricePerTonne * status.fuelQuality;
};

export const computeHarvestEarnings = (status: Status) => {
  const tonnesHarvested = computeTonnesHarvested(status);
  const price = computeFuelPrice(status);
  const harvest = status.prices.harvestCostPerTonne;
  const drying = status.prices.dryCostPerTonne;
  const transport = computeSeasonTransportCostPerTonne(status);
  const carbon = computeSeasonCarbonEarningsPerTonne(status);
  const carbonFlat = status.prices.carbonCreditFlat; // TODO: This is per season. Should it be per year instead? (multiply by harvestSeasonLength())

  const incomePerTonne = price + carbon - harvest - drying - transport;
  return normalizeMoney(tonnesHarvested * incomePerTonne + carbonFlat);
};

export const computeSeasonTransportCostPerTonne = (status: Status) => {
  return status.prices.ownTransport ? status.prices.transportCostPerTonne : 0;
};

export const computeSeasonCarbonEarningsPerTonne = (status: Status) => {
  return status.prices.carbonCreditPerTonne * 0.75;
};

export function questionEverAsked(status: Status, questionId: QuestionId): boolean {
  return status.history.some(year => year.choices.some(choice => choice.question == questionId));
}

export function computeQuestion(status: Status, question: Question): ComputedQuestion {
  const computed: ComputedQuestion = question.compute ? question.compute(status, question) : question;
  return computed;
}

export function computeOption(status: Status, questionId: QuestionId, optionId: OptionId): Option | undefined {
  const option = getOption(questionId, optionId);
  if (!option) return option;
  let computed: Option = {...option};
  if (option.buyToolId) {
    const tool = requireToolById(option.buyToolId);
    computed.cost = tool.price ?? computeCost(status, tool.pricePerHa);
  }
  // Specific cases
  if (option.compute) {
    computed = option.compute(status, {...option});
  }
  return computed;
}

export function toolIsPurchased(status: Status, toolId: ToolId) {
  return status.tools.some(t => t.id == toolId);
}

/**
 * Scale current yield by the given factor. Change will be reset on next harvest season.
 */
export const scaleYield = (state: Status, factor: number) => {
  state.currentYield = factor * GameLogicError.assertNonNullish(state.currentYield);
};

/**
 * Scale current yield by the given factor. The same scaling is applied to base yield, so the change will be persisted
 * on subsequent harvest seasons.
 */
export const scaleYieldPermanently = (state: Status, factor: number) => {
  state.baseYield = factor * GameLogicError.assertNonNullish(state.baseYield);
  state.currentYield = factor * GameLogicError.assertNonNullish(state.currentYield);
};

/**
 * Scale current fuel quality by the given factor.
 */
export const scaleFuelQuality = (state: Status, factor: number) => {
  state.fuelQuality = factor * GameLogicError.assertNonNullish(state.fuelQuality);
};
