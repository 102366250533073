// Moisture Content (%) -> Calorific Value (kWh/tonne) for Woodchip/Logs
// Taken from 'PEC Info'!H4:I44
const cvChip = {
  10: 4500,
  11: 4450,
  12: 4400,
  13: 4350,
  14: 4300,
  15: 4250,
  16: 4200,
  17: 4150,
  18: 4100,
  19: 4050,
  20: 4000,
  21: 3950,
  22: 3900,
  23: 3850,
  24: 3800,
  25: 3750,
  26: 3700,
  27: 3650,
  28: 3600,
  29: 3550,
  30: 3500,
  31: 3450,
  32: 3400,
  33: 3350,
  34: 3300,
  35: 3250,
  36: 3200,
  37: 3150,
  38: 3100,
  39: 3050,
  40: 3000,
  41: 2950,
  42: 2900,
  43: 2850,
  44: 2800,
  45: 2750,
  46: 2700,
  47: 2650,
  48: 2600,
  49: 2550,
  50: 2500,
};

export default cvChip;
