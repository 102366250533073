import {useQuery, UseQueryOptions, UseQueryResult} from '@tanstack/react-query';

import {useInputs} from '../../query/useScenario';
import {cropArrayToCropMap} from '../decision/calculations';
import {LandPlanningInput, runReport, validators} from '../decision/landPlanning';
import {LandReport} from '../decision/types';
import {fillWithDefaults, getFuelRequirement, getHeatConsumption, getStaggerStatus} from '../decision/util';
import {validate, ValidationError} from '../decision/validation';
import {getCrop} from '../resources/crops';
import {getFakeYieldMap} from '../util/fakeYield';

type UseLandReportResult = {
  report: LandReport | null;
  error: ValidationError | null;
};

const landReportMainKey = 'LandReport';

const getLandReportQueryKey = (scenarioId: string, inputs: any) => [
  scenarioId,
  landReportMainKey,
  JSON.stringify(inputs),
];

export const useLandReport = (
  scenarioId: string,
  options?: Pick<UseQueryOptions, 'enabled' | 'keepPreviousData'>
): UseQueryResult<UseLandReportResult, Error> => {
  const {data: inputs, isSuccess} = useInputs(scenarioId);

  return useQuery<UseLandReportResult, Error>({
    ...options,
    staleTime: Infinity,
    queryKey: getLandReportQueryKey(scenarioId, inputs),
    enabled: isSuccess,
    queryFn: () => {
      if (!inputs) return {report: null, error: null};

      try {
        const stagger = cropArrayToCropMap(inputs.stagger, cropId => (getCrop(cropId).canStagger ? 3 : 1));
        const fieldSizes = cropArrayToCropMap(inputs.fieldSizes, cropId => {
          const {isStaggering, defaultFieldSizes} = getStaggerStatus(cropId, inputs.landSize, stagger[cropId], null);
          return (isStaggering && defaultFieldSizes) || [inputs.landSize ?? 0];
        });
        const cv = cropArrayToCropMap(inputs.cv, cropId => getCrop(cropId).cv);
        const localYield = getFakeYieldMap(
          cropArrayToCropMap(inputs.localYield, cropId => getCrop(cropId).defaultYield)
        );
        const plantationLifetime = cropArrayToCropMap(
          inputs.plantationLifetime,
          cropId => getCrop(cropId).plantationLifetime
        );
        const heatConsumption = getHeatConsumption(inputs);
        const fuelRequirement = getFuelRequirement(inputs);
        const validatedInputs = validate<LandPlanningInput>(
          {
            ...fillWithDefaults(inputs),
            stagger,
            fieldSizes,
            localYield,
            cv,
            plantationLifetime,
            heatConsumption,
            fuelRequirement,
          },
          inputs,
          validators
        );
        console.log('Generating land report');

        const report = runReport(validatedInputs);
        return {report, error: null};
      } catch (e: any) {
        if (e instanceof ValidationError) {
          return {report: null, error: e};
        } else {
          throw e;
        }
      }
    },
  });
};
