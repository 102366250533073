import React, {ReactElement, useMemo} from 'react';

import {RevertIcon, WarningSign} from '../../../../resources/Icons';
import Row from '../../../common/Row';
import {SimpleValue} from '../../decision/types';
import {formatSimpleValue} from '../../decision/util';
import AdditionalInfo from './AdditionalInfo';

type Props = {
  show: 'default' | 'reset' | 'none';
  onReset: () => {};
  defaultValue?: SimpleValue;
};

const DefaultValueMessages = ({show, defaultValue, onReset}: Props): ReactElement => {
  const Reset = useMemo(
    () => (
      <button type={'button'} className={'hover:text-primary-800'} onClick={onReset}>
        <Row className={'items-start justify-center gap-[8px]'}>
          <RevertIcon className={'shrink-0 w-[14px] h-[14px] mt-[4px]'} />
          <span>Revert back to default value:</span>
          <span className={'italic'}>{formatSimpleValue(defaultValue)}</span>
        </Row>
      </button>
    ),
    [defaultValue, onReset]
  );

  const Default = useMemo(
    () => (
      <Row className={'items-start justify-center gap-[8px]'}>
        <WarningSign className={'mt-[4px]'} />
        <span>Pre-filled with an estimated value, input your details for an accurate result.</span>
      </Row>
    ),
    []
  );

  return (
    <AdditionalInfo
      info={show === 'reset' ? Reset : show === 'default' ? Default : undefined}
      className={'min-h-[2.4rem]'}
    />
  );
};

export default DefaultValueMessages;
