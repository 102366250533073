import {Walkthrough} from '../types';

export enum WalkthroughId {
  timeline,
  total_balance,
  production,
  feedback,
  get_help,
}

let WALKTHROUGH: Walkthrough[] = [
  {
    id: WalkthroughId.timeline,
    title: 'Timeline',
    contentLabel:
      'During this game, you will have 10 years to produce 800 tonnes of biomass using either Willow or Miscanthus. Throughout each year you will be presented with a number of random encounters such as wildlife and pests, as well as facing the elements.\n\nYou will come across numerous hurdles to navigate during each year where you will need to make certain decisions which will determine the fate of your crop. Keep an eye on the years as they go by and make sure you choose wisely and don’t leave anything too late!',
    highlightElementWithId: 'timeline',
    canGoBack: false,
  },
  {
    id: WalkthroughId.total_balance,
    title: 'Total Balance',
    contentLabel:
      'You will start the game with £25,000 but be aware, as this can dwindle fast! The aim of the game is to harvest as much crop as possible, without going bankrupt and losing all your money. Overspending can result in you having negative funds, and to win this game you will need to at least recover your initial £25,000. So while splashing the cash is possible, it may not be the wisest decision! Certain events will result in more cash coming in, so making decisions to balance both yield, and budget are vital.\n\nKeep an eye on your Total Balance on the left of the screen as this will be a very important factor when it comes to how much crop you harvest over the 10 years.',
    highlightElementWithId: 'totalBalance',
    canGoBack: true,
    highlightedClassname: 'mobile:fixed mobile:top-[40px] mobile:left-1/2 mobile:-translate-x-1/2',
  },
  {
    id: WalkthroughId.production,
    title: 'Production',
    contentLabel:
      'The main aim of the game is to produce 800 tonnes of crop over the course of 10 years. Keep a close eye on the Production counter on the left of the screen as this will total up your crop each year.\n\nIt won’t be plain sailing, as there will be events throughout the years which affect your yield, so be sure to make the right decisions, even if that means asking for a little help!',
    highlightElementWithId: 'production',
    canGoBack: true,
    highlightedClassname: 'mobile:fixed mobile:top-[40px] mobile:left-1/2 mobile:-translate-x-1/2',
  },
  {
    id: WalkthroughId.feedback,
    title: 'Provide feedback',
    contentLabel:
      'Once you have played the game or whilst you are playing, it would be great to get your feedback on your thoughts of the cropper game',
    highlightElementWithId: 'provideFeedback',
    canGoBack: true,
    highlightedClassname: 'mobile:fixed mobile:top-[58px] mobile:left-1/2 mobile:-translate-x-1/2',
  },
  {
    id: WalkthroughId.get_help,
    title: 'Get help!',
    contentLabel:
      'Some decisions you make throughout the years may need an extra pair of helping hands. If you aren’t sure on which path to take, you’ll have the option to talk to a neighbouring farmer, or spend some of your money to speak with an expert in the industry to guide you using the ‘Help me decide’ button on the right of the screen.\n\nYour neighbours won’t be able to give you a definitive answer, but will try and help to point you in the right direction with some free advice. However, the expert will give you the correct decision to make, but be careful as this advice will come at a cost!',
    highlightElementWithId: 'helpMeDecide',
    canGoBack: false,
    highlightedClassname: 'mobile:fixed mobile:top-[58px] mobile:left-1/2 mobile:-translate-x-1/2',
  },
];

export const getWalkthroughById = (walkthroughId: WalkthroughId | null) =>
  WALKTHROUGH.find(walkthrough => walkthrough.id === walkthroughId);
