// Moisture Content (%) -> Calorific Value (kWh/tonne) for Wood Pellets
// Taken from 'PEC Info'!X3:Y10
const cvWoodPellets = {
  5: 4775.0,
  6: 4724.74,
  7: 4674.47,
  8: 4624.21,
  9: 4573.95,
  10: 4523.68,
  11: 4473.42,
  12: 4423.16,
};

export default cvWoodPellets;
