import classNames from 'classnames';
import React, {ReactElement, useMemo} from 'react';

const MIN_N_RUSTS = 4;
const MAX_N_RUSTS = 10;
const MIN_TOP = 0.3; // %
const MAX_TOP = 0.5; // %
const MIN_LEFT = 0.2; // %
const MAX_LEFT = 0.8; // %

const RUST_COLORS = ['bg-[#fAff1a]', 'bg-[#2D2D42]', 'bg-[#8E634D]', 'bg-[#FFFFFF]'];

const Rust = (): ReactElement => {
  const rusts = useMemo(() => {
    const size = Math.floor(Math.random() * (MAX_N_RUSTS - MIN_N_RUSTS + 1) + MIN_N_RUSTS);
    return Array(size)
      .fill(null)
      .map(() => ({
        top: Math.random() * (MAX_TOP - MIN_TOP + MIN_TOP) * 100,
        left: Math.random() * (MAX_LEFT - MIN_LEFT + MIN_LEFT) * 100,
      }));
  }, []);

  return (
    <>
      {rusts.map(({top, left}, idx) => {
        const animationDelay = `${Math.floor(Math.random() * 3)}s`;
        const colorCode = RUST_COLORS[Math.floor(Math.random() * RUST_COLORS.length)];
        return (
          <div
            key={`rust_${idx}`}
            className={classNames('absolute opacity-0 animate-rust rounded-full w-[1rem] h-[1rem]', colorCode)}
            style={{top, left, animationDelay}}
          />
        );
      })}
    </>
  );
};

export default Rust;
