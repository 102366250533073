import classNames from 'classnames';
import React, {ChangeEvent, ComponentProps, FC, useCallback} from 'react';

import Row from '../../../common/Row';
import PreferredUnitsDropdown from '../reports/PreferredUnitsDropdown';
import AdditionalInfo from './AdditionalInfo';

type PropsType = Omit<ComponentProps<'input'>, 'onChange'> & {
  value: string | undefined;
  units?: string;
  wide?: boolean;
  onChange: (value: string | null) => void | Promise<void>;
  error?: string;
  info?: JSX.Element;
  isArea?: boolean;
};

const QuestionInput: FC<PropsType> = props => {
  const {value, units, wide, onChange, error, info, isArea, ...otherProps} = props;

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const currentValue = event.currentTarget.value;
      await onChange(currentValue ?? null);
    },
    [onChange]
  );

  return (
    <fieldset className={'flex flex-col items-stretch'}>
      <Row className={'items-center'}>
        {(!!units || isArea) && <span className={'basis-0 grow'} />}
        <input
          type={'text'}
          value={value || ''}
          onChange={handleChange}
          className={classNames(
            {error: error !== undefined},
            {'flex-1': !units && !isArea},
            units && (wide ? 'w-60' : 'w-40')
          )}
          {...otherProps}
        />
        {isArea && (
          <Row className={'basis-0 grow ml-xs'}>
            <PreferredUnitsDropdown />
          </Row>
        )}
        {!isArea && units && (
          <span className={'basis-0 grow'}>
            <span className={'ml-4'}>{units}</span>
          </span>
        )}
      </Row>
      <AdditionalInfo error={error} info={info} />
    </fieldset>
  );
};

export default QuestionInput;
