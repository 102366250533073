import Cookies, {CookieChangeOptions} from 'universal-cookie';

import ServerCookie from '../../cookies';
import {resetOwnSupplier} from './supplierHooks';
import {invalidateCurrentUser} from './useCurrentUser';
import {invalidateAllScenarios} from './useScenario';

const authCookieChangeHandler = ({name}: CookieChangeOptions) => {
  console.log('Cookie change', name);
  if (name === ServerCookie.authToken) {
    invalidateCurrentUser();
    invalidateAllScenarios();
    void resetOwnSupplier();
  }
};

export const addCookieListener = (cookies: Cookies) => {
  cookies.addChangeListener(authCookieChangeHandler);
};
