import React, {ComponentProps, useCallback} from 'react';

import {FarmerProfile, FarmerType} from '../../../query/graphql';
import {useAppDispatch} from '../../../store';
import {useStartFlow, validators} from '../../decision/startFlow';
import {Option, StartFlow} from '../../decision/types';
import {StartFlowActions} from '../../startFlowSlice';
import QuestionSequence from './QuestionSequence';
import QuestionView from './QuestionView';

const farmerTypeOptions = [
  {label: 'Farmer', value: FarmerType.Farmer},
  {label: 'Land owner', value: FarmerType.LandOwner},
  {label: 'Neither', value: FarmerType.Neither},
];

const farmerProfileOptions: Option<FarmerProfile>[] = [
  {label: 'Somebody looking for more information on biomass crops', value: FarmerProfile.Interested},
  {label: 'Student or researcher', value: FarmerProfile.Researcher},
  {
    label: 'Industry supplier',
    value: FarmerProfile.Supplier,
    tooltip: (
      <span>
        By selecting this option a supplier profile will be
        <br />
        created for you to set up
      </span>
    ),
  },
  {label: 'Somebody looking to purchase products and services', value: FarmerProfile.Purchaser},
];

const StartSequence = (props: Omit<ComponentProps<typeof QuestionSequence>, 'scenarioId'>) => {
  const dispatch = useAppDispatch();
  const values = useStartFlow();

  const handleChangeInput = useCallback(
    (id: keyof StartFlow, value: string | string[] | null) => {
      dispatch(StartFlowActions.setValue({[id]: value}));
    },
    [dispatch]
  );

  return (
    <QuestionSequence {...props} scenarioId={null}>
      <QuestionView
        id={'farmerType'}
        type={'select'}
        orientation={'horizontal'}
        title={'Are you a farmer or land owner?'}
        options={farmerTypeOptions}
        value={values.farmerType}
        defaultValue={undefined}
        validate={validators.farmerType}
        onChange={handleChangeInput}
      />
      <QuestionView
        id={'farmerProfile'}
        type={'multiselect'}
        title={'What would you describe yourself as?'}
        options={farmerProfileOptions}
        value={values.farmerProfile}
        defaultValue={undefined}
        validate={validators.farmerProfile}
        onChange={handleChangeInput}
      />
    </QuestionSequence>
  );
};

export default StartSequence;
