export const TWENTY_FOUR_HOURS_IN_SECONDS = 24 * 60 * 60;

export function isNullish<T>(value: T | null | undefined): value is null | undefined {
  return value === undefined || value === null;
}

export function isNonNullish<T>(value: T | null | undefined): value is T {
  return !isNullish(value);
}

export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number) => unknown) {
  if (array.length > 0) {
    for (let i = array.length - 1; i >= 0; i--) {
      if (predicate(array[i], i)) return i;
    }
  }
  return -1;
}

export function emptyStringAsNull(value: any) {
  return value === '' ? null : value;
}

export async function asyncMap<T, R>(arrayOrScalar: T, mapper: (value: T) => Promise<R>): Promise<R>;
export async function asyncMap<T, R>(arrayOrScalar: Array<T>, mapper: (value: T) => Promise<R>): Promise<R[]>;

/**
 * Applies an async mapper function to an array or scalar value. If the input is an array, returns an array containing
 * the results of applying the mapper to each element. Otherwise, returns a scalar.
 */
export async function asyncMap<T, R>(arrayOrScalar: Array<T> | T, mapper: (value: T) => Promise<R>): Promise<R | R[]> {
  if (Array.isArray(arrayOrScalar)) {
    return Promise.all(arrayOrScalar.map(mapper));
  } else {
    return mapper(arrayOrScalar);
  }
}

/**
 * Calculates the change in rounder percentage between `before` and `after` amounts. Examples:
 * - if `before` is 10 and `after is `11, result is 10 meaning +10%
 * - if `before is 20 and after is 10, result is -50, meaning -50%
 * @param before
 * @param after
 */
export function computePercentageChange(before: number, after: number) {
  return Math.round((after / before - 1) * 100);
}

/**
 * Gets whether we are running in development mode, i.e. started with `npm run dev`
 */
export function isDev() {
  // `NODE_ENV` environment variable is an exception and is available in client code even though it doesn't start
  // with `NEXT_PUBLIC`. This is done via a webpack plugin here:
  // https://github.com/vercel/next.js/blob/e451218900b16e721db10413734ca7a964c53677/server/build/webpack.js#L102-L106
  //
  // This behaviour is similar to create-react-app, which clearly documents it in:
  // https://create-react-app.dev/docs/adding-custom-environment-variables/
  return process.env.NODE_ENV === 'development';
}
