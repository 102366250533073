import Modal from '../Modal';
import StartSequence from '../questions/StartSequence';

type Props = {
  isOpen: boolean;
};

const StartFlowModal = ({isOpen}: Props) => {
  return (
    <Modal isOpen={isOpen}>
      <StartSequence
        onFinish={() => {
          /* GlobalInit will detect updates yo startFlow slice and handle user update */
        }}
      />
    </Modal>
  );
};

export default StartFlowModal;
