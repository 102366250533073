import {useMemo} from 'react';

import {Tool} from '../types';

export enum ToolId {
  fence = 'fence',
  employee = 'employee',
  sorghum_strip = 'sorghum_strip',
  beetles_spray = 'beetles_spray',
  herbicide = 'herbicide',
  special_cultivator = 'special_cultivator',
}

let TOOLS: Tool[] = [
  {
    id: ToolId.fence,
    name: 'Erected fence',
    description: 'High fence. Effective against all types of animals.',
    permanent: true,
    pricePerHa: 870,
    group: 'protection',
  },
  {
    id: ToolId.employee,
    name: 'Deploy employee',
    description: 'Hire an employee to shoot rabbits.',
    permanent: false,
    pricePerHa: 150,
    group: 'protection',
  },
  {
    id: ToolId.sorghum_strip,
    name: 'Sorghum strip',
    description: 'A deer deterrent',
    permanent: true,
    pricePerHa: 100,
  },
  {
    id: ToolId.beetles_spray,
    name: 'Spray',
    description: 'Reduces the likelihood of pests',
    permanent: true,
    pricePerHa: 50,
  },
  {
    id: ToolId.herbicide,
    name: 'Herbicide',
    description: 'Reduces the likelihood of weeds',
    permanent: false,
    pricePerHa: 100,
  },
  {
    id: ToolId.special_cultivator,
    name: 'Special cultivator',
    description: 'TBC',
    permanent: true,
    pricePerHa: 200,
  },
];

export const useAllTools = () => useMemo(() => TOOLS, []);

export const getToolById = (toolId: ToolId) => TOOLS.find(tool => tool.id === toolId);

export const requireToolById = (toolId: ToolId) => {
  const tool = getToolById(toolId);
  if (!tool) throw Error(`Invalid tool id: ${toolId}`);
  return tool;
};
