import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {GameSettings as GqlGameSettings} from '../query/graphql';
import {GameSettings} from './types';

// Define the initial state using that type
const initialState: GameSettings = {
  loaded: false,
  audioEnabled: true,
  canPlayAudio: false,
  debug: false,
  showToolsMenu: false,
};

export const gameSettingsSlice = createSlice({
  name: 'gameSettings',
  initialState,
  reducers: {
    setSettings: (state, action: PayloadAction<GqlGameSettings>) => {
      return {...state, ...action.payload, loaded: true};
    },
    activateAudio: state => {
      state.canPlayAudio = true;
    },
    toggleAudio: state => {
      state.audioEnabled = !state.audioEnabled;
    },
  },
});

// Action creators are generated for each case reducer function
export const GameSettingsActions = gameSettingsSlice.actions;

export default gameSettingsSlice.reducer;
