import {useCallback} from 'react';

import {Flow, useFlowEditedQuery, useUpdateInputsMutation} from './graphql';
import queryClient from './queryClient';
import useCurrentUser from './useCurrentUser';

export const useFlowEdited = (scenarioId: string, flow: Flow) => {
  const currentUser = useCurrentUser();
  const flowEditedQuery = useFlowEditedQuery(
    {scenarioId},
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!currentUser,
      queryKey: useFlowEditedQuery.getKey({scenarioId}),
    }
  );
  switch (flow) {
    case Flow.Land:
      return flowEditedQuery?.data?.scenario?.inputs.landEdited ?? false;
    case Flow.Cost:
      return flowEditedQuery?.data?.scenario?.inputs.costEdited ?? false;
  }
};

export const useUpdateFlowEdited = (scenarioId: string, flow: Flow | null) => {
  const mutation = useUpdateInputsMutation({onSuccess: () => invalidateFlowEdited(scenarioId)});

  let key = '';
  if (flow === Flow.Land) key = 'landEdited';
  if (flow === Flow.Cost) key = 'costEdited';

  // It is important to take this function out of "mutation" in order
  // to avoid having "mutation" in the useCallback dependency array
  const mutateAsync = mutation.mutateAsync;

  const mutator = useCallback(
    async (edited: boolean) => {
      if (!key) throw new Error(`Key not defined for flow ${flow}`);
      return await mutateAsync({scenarioId, inputs: {[key]: edited}});
    },
    [flow, key, mutateAsync, scenarioId]
  );

  return mutator;
};

export const invalidateFlowEdited = (scenarioId: string) => {
  queryClient.invalidateQueries(useFlowEditedQuery.getKey({scenarioId}));
};

export const invalidateAllFlowEdited = () => {
  // Pop variables to invalidate query for all scenarios
  queryClient.invalidateQueries(useFlowEditedQuery.getKey({scenarioId: 'x'}).slice(0, -1));
};
