import {User} from '@firebase/auth';
import isAbsoluteUrl from 'is-absolute-url';
import {NextRouter} from 'next/router';
import {ParsedUrlQuery} from 'querystring';

import URLS from './urls';

const handleProtectedRoutes = (query: ParsedUrlQuery, currentUser: User | null | undefined) => {
  if (currentUser) {
    return currentUser.emailVerified ? null : URLS.verifyEmail;
  } else {
    return 'signout' in query ? URLS.home : URLS.signin;
  }
};

const handleSignRoutes = (query: ParsedUrlQuery, currentUser: User | null | undefined) => {
  return currentUser ? getGoUrl(query) ?? URLS.dashboard : null;
};

const handleVerifyEmailRoute = (currentUser: User | null | undefined) => {
  return !currentUser ? URLS.home : currentUser?.emailVerified ? URLS.dashboard : null;
};

export function detectRedirection(router: NextRouter, currentUser: User | null | undefined): string | null {
  const pathname = router.pathname;

  if (pathname.startsWith('/app')) {
    return handleProtectedRoutes(router.query, currentUser);
  }

  switch (pathname) {
    case URLS.signin:
    case URLS.signup:
    case URLS.forgotPassword:
    case URLS.start:
    case URLS.startFlow:
      return handleSignRoutes(router.query, currentUser);
    case URLS.verifyEmail:
      return handleVerifyEmailRoute(currentUser);
  }

  return null;
}

/* Returns the value of the 'go' query parameter, if it's a local (non-absolute) URL; otherwise, returns null */
export function getGoUrl(query: ParsedUrlQuery): string | null {
  const go = query.go;
  return typeof go === 'string' && !isAbsoluteUrl(go) ? go : null;
}
