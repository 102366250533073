import React, {ChangeEvent, ComponentProps, CSSProperties, ReactElement, useCallback, useRef, useState} from 'react';

import {MAX_UPLOAD_SIZE_EXCEEDED} from '../../../controllers/errors';
import {ACCEPTED_TYPES, MAX_UPLOAD_SIZE_BYTES} from '../util/image';
import CircleImage from './CircleImage';

export type ImagePickerResult = {file: File; error?: undefined} | {file?: undefined; error: Error};

type Props = {
  src?: string;
  alt?: string;
  size?: ComponentProps<typeof CircleImage>['size'];
  onChange: (result: ImagePickerResult) => Promise<void>;
  imageFit?: CSSProperties['objectFit'];
};

const ImagePicker = ({src, alt, size = 'medium', onChange, imageFit}: Props): ReactElement => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState(false);

  let unoptimized = false;
  if (src) {
    try {
      const url = new URL(src);
      unoptimized = url.pathname.endsWith('.svg');
    } catch (e) {
      console.log(e);
    }
  }

  const handleLogoClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleFileChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.currentTarget.files?.[0];
      if (!file) return;
      if (file.size > MAX_UPLOAD_SIZE_BYTES) {
        await onChange({error: new Error(MAX_UPLOAD_SIZE_EXCEEDED)});
        return;
      }

      try {
        setLoading(true);
        await onChange({file});
      } finally {
        setLoading(false);
        // Clear the input so that it can be used again even for the same file
        if (fileInputRef.current) fileInputRef.current.value = '';
      }
    },
    [onChange]
  );

  return (
    <>
      <button type={'button'} onClick={handleLogoClick} disabled={loading}>
        <CircleImage
          size={size}
          src={src}
          alt={alt ?? 'Image picker'}
          hoverEffect
          loading={loading}
          unoptimized={unoptimized}
          imageFit={imageFit}
        />
      </button>
      <input
        type={'file'}
        className={'hidden'}
        ref={fileInputRef}
        onChange={handleFileChange}
        accept={ACCEPTED_TYPES.join(',')}
      />
    </>
  );
};

export default ImagePicker;
