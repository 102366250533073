import {
  ComponentProps,
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import {useGameSettings} from '../hooks/useGameSettings';

type Props = ComponentProps<'audio'> & {
  play?: boolean;
  volume?: number; // [0,1]
};
export type GameAudioHandle = {
  play: () => void;
  stop: () => void;
};

const GameAudio = ({play, volume, autoPlay, ...rest}: Props, ref: ForwardedRef<GameAudioHandle>) => {
  const settings = useGameSettings();
  const audioRef = useRef<HTMLAudioElement>(null);
  const audioEnabled = settings.canPlayAudio && settings.audioEnabled;

  const currentVolume = useMemo(() => (volume === undefined || volume > 1 ? 1 : volume), [volume]);

  const playAudio = useCallback(() => {
    if (!audioRef.current) return;
    try {
      void audioRef.current.play();
      audioRef.current.volume = currentVolume;
    } catch (e) {
      console.log(e);
    }
  }, [currentVolume]);

  const stopAudio = useCallback(() => {
    if (!audioRef.current) return;
    void audioRef.current.pause();
    audioRef.current.currentTime = 0;
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      play: () => audioEnabled && playAudio(),
      stop: () => stopAudio(),
    }),
    [audioEnabled, playAudio, stopAudio]
  );

  useEffect(() => {
    if (!audioEnabled || !play) stopAudio();
    else playAudio();
  }, [play, playAudio, stopAudio, audioEnabled]);

  return <audio ref={audioRef} preload={'auto'} autoPlay={audioEnabled && autoPlay} {...rest} />;
};

export default forwardRef(GameAudio);
