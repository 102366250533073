import {MatomoProvider, useMatomo} from '@jonkoops/matomo-tracker-react';
import {useRouter} from 'next/router';
import React, {PropsWithChildren, ReactElement, useCallback, useEffect, useMemo, useState} from 'react';

import ServerCookie, {
  cookies,
  getCookieConsentGiven,
  removeCookieConsentGiven,
  setCookieConsentGiven,
} from '../../cookies';
import URLS from '../../urls';
import Button from '../tool/components/buttons/Button';
import {useEnvironment} from './EnvironmentProvider';
import Row from './Row';
import {isDev} from './util';
import {createMatomoInstance, Instruction} from './util/matomo';

if (isDev()) {
  console.log('Development build detected. Matomo initialisation will be skipped.');
}

const MatomoAnalytics = ({children}: PropsWithChildren): ReactElement | null => {
  const environment = useEnvironment();
  // 'DISABLE' value will disable Matomo analytics (but the corresponding env variable must still be present)
  const matomoEnabled = !isDev() && environment && environment.matomo.urlBase.toUpperCase() !== 'DISABLE';

  const matomoInstance = useMemo(
    () => (matomoEnabled ? createMatomoInstance(environment.matomo) : undefined),
    [environment?.matomo, matomoEnabled]
  );
  if (!matomoInstance) return <>{children}</>;

  return (
    <MatomoProvider value={matomoInstance}>
      <MatomoPageTracker>{children}</MatomoPageTracker>
    </MatomoProvider>
  );
};

export default MatomoAnalytics;

const MatomoPageTracker = ({children}: PropsWithChildren): ReactElement => {
  const router = useRouter();
  const {trackPageView, pushInstruction} = useMatomo();
  const [showBanner, setShowBanner] = useState<boolean>();

  const updateMatomoCookie = useCallback(
    (consentGiven: boolean) => {
      if (consentGiven) {
        pushInstruction(Instruction.rememberCookieConsentGiven);
      } else {
        pushInstruction(Instruction.forgetCookieConsentGiven);
      }
    },
    [pushInstruction]
  );

  const handleAccept = useCallback(() => {
    setCookieConsentGiven();
    setShowBanner(false);
  }, []);

  const handleReject = useCallback(() => {
    removeCookieConsentGiven();
    setShowBanner(false);
  }, []);

  useEffect(() => {
    cookies.addChangeListener(cookie => {
      if (cookie.name === ServerCookie.cookieConsentGiven) {
        updateMatomoCookie(!!cookie.value);
      }
    });
    pushInstruction(Instruction.requireCookieConsent); // Ensure no Matomo cookies are set before consent is given
    updateMatomoCookie(getCookieConsentGiven());
    setShowBanner(!getCookieConsentGiven());
  }, [pushInstruction, updateMatomoCookie]);

  /** Track page view on path change */
  useEffect(() => {
    if (router.asPath) {
      trackPageView();
    }
  }, [pushInstruction, router.asPath, trackPageView]);

  return (
    <>
      {children}
      {showBanner === true && (
        <Row className={'fixed bottom-0 w-full p-md items-center gap-xs flex-wrap bg-primary-600 text-sm text-white'}>
          <span className={'grow'}>
            Are you happy to provide anonymous data about your use of the website to help us improve the experience?
          </span>
          <Row className={'gap-xs flex-wrap'}>
            <Button variant={'primary'} className={'shrink-0 hover:!border-primary-700'} onClick={handleAccept}>
              OK
            </Button>
            <Button variant={'primary'} className={'shrink-0 hover:!border-primary-700'} onClick={handleReject}>
              No thanks
            </Button>
            <Button variant={'primary'} className={'shrink-0 hover:!border-primary-700'} href={URLS.privacyPolicy}>
              Privacy policy
            </Button>
          </Row>
        </Row>
      )}
    </>
  );
};
