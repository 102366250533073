import React from 'react';

import {CropId} from './resources/crops';
import {EventId} from './resources/events';
import {JeopardyWheelId} from './resources/jeopardies';
import {LandUseId} from './resources/landUses';
import {PlaceId} from './resources/places';
import {OptionId, QuestionId} from './resources/questions';
import {ToolId} from './resources/tools';
import {WalkthroughId} from './resources/walkthrough';

export type Place = {
  id: PlaceId;
  name: string;
  description: string;
  avgYield: {[key in CropId]: number}; // tonnes per ha per year
  bgClass: string;
};

export type Crop = {
  id: CropId;
  name: string;
  description: string;
  plantationCost: number; // £ per hectare
  harvestingPeriod: number; // Years for the crop to grow
  pricePerTonne: number; // £ per tonne
};

export type LandUse = {
  id: LandUseId;
  name: string;
  description: string;
  preparationCost: number; // £ per hectare
};

type ToolGroup = 'protection';

export type Tool = {
  id: ToolId;
  name: string;
  description: string;
  price?: number; // Total price
  pricePerHa: number; // £ per ha
  permanent: boolean;
  group?: ToolGroup;
};

export type Event = {
  id: EventId;
  icon?: React.FunctionComponent<{className: string}>;
  name: string;
  description: string;
  silent?: boolean;
  animation?: React.FunctionComponent;
  /**
   * Determines where the event animation is placed:
   * - 'background': One instance of the animation behind the crops
   * - 'foreground': One instance of the animation in front of the crops (DEFAULT)
   * - 'stem': One instance of the animation per plant stem, relatively positioned
   */
  animationPosition?: 'background' | 'foreground' | 'stem';
  /*
  hasHappened will receive the current gameStatus and has to decide
  whether the event is going to be executed. Inside this function there
  are some aspects to taken into account to take the decision, for example:
  - Can this even occur in the current year?
  - What is the probability of this event to happen?
  - Does the place / land use affect this event?
  - Check if the gamer bought tools that affect the probability of this event to happen
  */
  hasHappened: (status: Status) => boolean;
  /*s
  runEffects will receive the current gameStatus snd has to make the necessary
  changes on it. It will be called from a reducer to the status has to be mutated here
  (no dispatch / actions).
  */
  runEffects: (status: Status) => void;
};

export type JeopardyWheel = {
  id: JeopardyWheelId;
  title: string;
  description: string;
  resultTitle?: string;
  eventsIds: EventId[];
  wheelClass: string;
  canDisplay: (status: Status) => boolean;
};

export enum GamePhase {
  welcome = 0,
  preparation = 1,
  questions = 2,
  jeopardies = 3,
  events = 4,
  wrapup = 5,
  harvest = 6,
  finished = 7,
}

export type EventLog = {
  id: EventId;
  // If and only if `effect` is not undefined, the event has be triggered
  effect?: {
    yieldBefore: number;
    yieldAfter: number;
    moneyBefore: number;
    moneyAfter: number;
  };
};

export type ChoiceLog = {
  question: QuestionId;
  option?: {
    id: OptionId;
    yieldBefore: number;
    yieldAfter: number;
    penaltyPoints: number;
  };
};

export type HistoryYear = {
  choices: ChoiceLog[];
  events: EventLog[];
};

export type PlantingTime = 'march' | 'may' | 'july' | 'september';
export type PlantationType = 'monoculture' | 'light_mixture' | 'hard_mixture';
export type HarvestTime = 'october' | 'december' | 'february' | 'april';

export type Walkthrough = {
  id: WalkthroughId;
  title: string;
  contentLabel: string;
  highlightElementWithId: string;
  canGoBack: boolean;
  highlightedClassname?: string;
};
export type WalkthroughProgress = {
  current: WalkthroughId | null;
  last: WalkthroughId | null;
};

export type GameSettings = {
  loaded: boolean;
  canPlayAudio: boolean;
  audioEnabled: boolean;
  debug: boolean;
  showToolsMenu: boolean;
};

export type Status = {
  phase: GamePhase;
  initialMoney: number;
  money: number;
  totalProduction: number;
  currentYear: number;
  totalYears: number;
  plantingYear: number;
  harvestYears: number[];
  // Avg. yield for the selected place/crop plus permanent modifications
  baseYield?: number;
  // Reset to baseYield after each harvest
  currentYield?: number;
  prices: {
    // Incomes
    pricePerTonne: number | undefined;
    carbonCreditFlat: number;
    carbonCreditPerTonne: number;
    // Expenses
    dryCostPerTonne: number;
    ownTransport: boolean;
    transportCostPerTonne: number;
    harvestCostPerTonne: number;
  };
  place?: Place;
  landUse?: LandUse;
  crop?: Crop;
  landSize?: number; // Hectares
  plantingTime?: PlantingTime;
  plantationType?: PlantationType;
  harvestTime?: HarvestTime;
  isHarvesting: boolean;
  fuelQuality: number;
  carbonEmissions: number; // tonnes
  enabledTools: string[];
  tools: Tool[];
  expertAdvices: QuestionId[];
  productionGoal: number;
  walkthroughProgress: WalkthroughProgress;
  history: HistoryYear[];
  endOfGameTracked?: boolean;
  scoreAdded?: boolean;
};

export type Option = {
  id: OptionId;
  subtitle?: string;
  title: string;
  buyToolId?: ToolId;
  cost?: number;
  costPerHa?: number;
  carbonEmissions?: number; // tonnes
  compute?: (status: Status, option: Option) => Option;
  onChosen?: (status: Status) => void;
  // Used in phrases like `${shortName} decreased your yield by 10%`
  shortName?: string;
  penaltyPoints?: number;
};

export type ExpertAdvice = {
  text: string;
  cost: number;
};

export type Link = {
  url: string;
  title?: string;
};

export type QuestionHelp = {
  text?: string;
  links?: Link[];
  neighbour?: Omit<ExpertAdvice, 'cost'>;
  expert?: ExpertAdvice;
};

export type Question = {
  id: QuestionId;
  heading?: string;
  subtitle: string;
  title: string;
  shouldAsk: (status: Status) => boolean;
  options: Option[];
  enableTools?: string[];
  help?: QuestionHelp;
  compute?: (status: Status, question: Question) => Question;
  onAnswered?: (status: Status, option: Option) => void;
};

export type ComputedQuestion = Question & {
  // Add computed fields here
};
