import {switchMC} from '../decision/calculations';
import {CropMap} from '../decision/types';
import {CropId, getCrop} from '../resources/crops';

const DEBUG_FAKE_YIELD_SRCWILLOW_TRACKING_KEY = 'debug-fake-yield-srcwillow-tracking-key'; // Key for session storage
const DEBUG_FAKE_YIELD_MISCANTHUS_TRACKING_KEY = 'debug-fake-yield-miscanthus-tracking-key'; // Key for session storage
const DEBUG_FAKE_YIELD_SRCPOPLAR_TRACKING_KEY = 'debug-fake-yield-srcpoplar-tracking-key'; // Key for session storage

export const FAKEABLE_TYPES = {
  miscanthus: DEBUG_FAKE_YIELD_MISCANTHUS_TRACKING_KEY,
  srcWillow: DEBUG_FAKE_YIELD_SRCWILLOW_TRACKING_KEY,
  srcPoplar: DEBUG_FAKE_YIELD_SRCPOPLAR_TRACKING_KEY,
};

export const setFakeYield = (cropId: keyof typeof FAKEABLE_TYPES, matureYield: number | null) => {
  if (matureYield === null) {
    window.sessionStorage.removeItem(FAKEABLE_TYPES[cropId]);
  } else {
    window.sessionStorage.setItem(FAKEABLE_TYPES[cropId], JSON.stringify(matureYield));
  }
};

export const getFakeYield = (cropId: keyof typeof FAKEABLE_TYPES) =>
  window.sessionStorage.getItem(FAKEABLE_TYPES[cropId]);

export const getFakeYieldMap = (yieldMap: CropMap<number>): CropMap<number> => {
  const willowChip = getFakeYield('srcWillow');
  const srcPoplar = getFakeYield('srcPoplar');
  const miscanthus = getFakeYield('miscanthus');

  const convertToDryMatter = (cropId: CropId, strValue: string | null, defaultValue: number): number => {
    if (strValue === null) return defaultValue;
    const num = JSON.parse(strValue);
    if (typeof num === 'number') {
      return switchMC(num, getCrop(cropId)['harvestedMC'], 0);
    }
    return defaultValue;
  };

  return {
    ...yieldMap,
    willowChip: convertToDryMatter(CropId.willowChip, willowChip, yieldMap.willowChip),
    srcPoplar: convertToDryMatter(CropId.srcPoplar, srcPoplar, yieldMap.srcPoplar),
    miscanthusChip: convertToDryMatter(CropId.miscanthusChip, miscanthus, yieldMap.miscanthusChip),
    miscanthusBale: convertToDryMatter(CropId.miscanthusBale, miscanthus, yieldMap.miscanthusBale),
  };
};
