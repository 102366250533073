import {configureStore} from '@reduxjs/toolkit';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE} from 'redux-persist';
import {PersistMigrate} from 'redux-persist/es/types';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';

import gameDebugReducer from '../game/debugSlice';
import gameSettingsReducer from '../game/gameSettingsSlice';
import gameReducer from '../game/gameSlice';
import startFlowReducer from '../tool/startFlowSlice';

/**
 * Migration strategy for persisted reducer, that resets the state (i.e. use initial state) if current reducer version
 * is different from persisted reducer version.
 */
const resetStateOnVersionChange: PersistMigrate = (persistedState, currentVersion) => {
  const persistedVersion = persistedState?._persist.version;
  if (persistedVersion !== currentVersion) {
    console.log(`Migrating reducer from version ${persistedVersion} to ${currentVersion}. State will be reset.`);
    return Promise.resolve(undefined); // Use reducer's initial state
  }
  return Promise.resolve(persistedState);
};

const persistedGameReducer = persistReducer(
  {
    key: 'game',
    version: 3, // Important: Increase version if you make breaking changes to this reducer
    migrate: resetStateOnVersionChange,
    storage,
  },
  gameReducer
);

const persistedGameSettingsReducer = persistReducer(
  {
    key: 'gameSettings',
    version: 1, // Important: Increase version if you make breaking changes to this reducer
    migrate: resetStateOnVersionChange,
    storage,
  },
  gameSettingsReducer
);

const persistedStartFlowReducer = persistReducer(
  {
    key: 'startFlow',
    version: 0, // Important: Increase version if you make breaking changes to this reducer
    migrate: resetStateOnVersionChange,
    storage: storageSession,
  },
  startFlowReducer
);

const store = configureStore({
  reducer: {
    game: persistedGameReducer,
    gameSettings: persistedGameSettingsReducer,
    gameDebug: gameDebugReducer,
    startFlow: persistedStartFlowReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
