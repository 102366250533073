import React, {ReactElement, useId} from 'react';

import {CrownIcon} from '../../../../resources/Icons';
import Row from '../../../common/Row';
import Button from '../buttons/Button';
import SimpleTooltip from '../SimpleTooltip';

type Props = {};

const UnlockForm = ({}: Props): ReactElement => {
  const tooltipId = useId();

  return (
    <form className={'flex flex-col'}>
      <p className={'mx-xl mb-lg text-sm'}>
        Creating a new scenario will allow you to create a new dashboard to manage a new piece of land, you will be able
        to plan your land to help determine which crop is best, as well as the estimated yields and timescales.
      </p>
      <Row className={'p-sm border-t border-gray-100 justify-end'}>
        <span data-tooltip-id={tooltipId}>
          <Button type={'button'} variant={'primary'} accessory={'forward'} disabled>
            <Row>
              <CrownIcon className={'w-[1.4rem] text-primary-300'} />
              <span className={'mx-md'}>Unlock this feature</span>
            </Row>
          </Button>
          <SimpleTooltip id={tooltipId}>Coming soon!</SimpleTooltip>
        </span>
      </Row>
    </form>
  );
};

export default UnlockForm;
