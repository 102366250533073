import React, {ReactElement} from 'react';

import {TractorImage} from '../../../../resources/Icons';

const StuckHarvester = (): ReactElement => {
  return (
    <div className="absolute bottom-[4rem] -left-[30rem]">
      <TractorImage className="w-[90rem]" />
    </div>
  );
};

export default StuckHarvester;
