import classNames from 'classnames';
import React, {ReactElement, useEffect, useState} from 'react';

import {ChevronUpDownIcon} from '../../../../resources/Icons';
import Row from '../../../common/Row';
import {useCurrentUserQuery} from '../../../query/graphql';
import {useUpdateUserProps} from '../../../query/useCurrentUser';
import Dropdown from '../Dropdown';
import DropdownOptions from '../DropdownOptions';

const options = [
  {value: false, label: 'Hectares (ha)'},
  {value: true, label: 'Acres'},
];

type Props = {
  title?: string;
  className?: string;
};

const PreferredUnitsDropdown = ({title, className}: Props): ReactElement => {
  const updateUserProps = useUpdateUserProps();
  const {data: currentUser, isFetching} = useCurrentUserQuery();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(!currentUser || isFetching);
  }, [currentUser, isFetching]);

  const handleChange = async (value: boolean) => {
    setDisabled(true);
    await updateUserProps({prefersImperial: value});
    setDisabled(false);
  };

  const prefersImperial = !!currentUser?.currentUser?.props.prefersImperial;

  return (
    <Dropdown
      variant={'secondary'}
      floating
      hideChevron
      disabled={disabled}
      buttonContent={
        <Row className={classNames('items-center gap-2')}>
          {title && <div className={'leading-tight'}>{title}</div>}
          <Row className={'items-center font-semibold text-primary-600 whitespace-nowrap'}>
            <span className={'grow'}>{prefersImperial ? options[1].label : options[0].label}</span>
            <ChevronUpDownIcon className={'inline w-[15px] h-[15px] text-primary-600 ml-[4px]'} />
          </Row>
        </Row>
      }
      className={classNames(disabled && 'opacity-50', className)}
    >
      <DropdownOptions
        blurOnSelect
        options={options}
        selectedValue={prefersImperial}
        onOptionSelected={handleChange}
        className={'whitespace-nowrap'}
      />
    </Dropdown>
  );
};

export default PreferredUnitsDropdown;
