import {useMemo} from 'react';

import {GameLogicError} from '../errors';
import {Crop} from '../types';

export enum CropId {
  miscanthus = 'miscanthus',
  willow = 'willow',
}

const CROPS: Crop[] = [
  {
    id: CropId.miscanthus,
    name: 'Miscanthus',
    description: '',
    plantationCost: 1250,
    harvestingPeriod: 1,
    pricePerTonne: 85,
  },
  {id: CropId.willow, name: 'Willow', description: '', plantationCost: 1250, harvestingPeriod: 3, pricePerTonne: 75},
];

export const getCrop = (id: CropId): Crop => GameLogicError.assertNonNullish(CROPS.find(c => c.id === id));
export const useAllCrops = () => useMemo(() => CROPS, []);
