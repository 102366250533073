import {ComponentProps} from 'react';

const EnvirocropsLogo = (props: ComponentProps<'svg'>) => (
  <svg height="46" viewBox="0 0 208 46" width="208" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="#096b62" fillRule="nonzero" transform="translate(24.395 10.605)">
        <path d="m0 24.395h16.135v-3.465h-12.39v-7.315h11.375v-3.465h-11.375v-6.685h12.39v-3.465h-16.135z" />
        <path d="m29.89 6.825c-2.24 0-4.2.805-5.39 2.45v-2.135h-3.605v17.255h3.605v-7.175c0-3.675.63-7.245 4.34-7.245 2.975 0 3.36 2.345 3.36 4.375v10.045h3.605v-10.955c0-4.235-2.1-6.615-5.915-6.615z" />
        <path d="m45.045 24.395h3.01l7.035-17.255h-3.92l-4.62 12.6-4.585-12.6h-3.955z" />
        <path d="m57.785 3.78h3.85v-3.78h-3.85zm.14 20.615h3.605v-17.255h-3.605z" />
        <path d="m75.32 6.825c-1.89 0-3.885.98-4.76 3.15v-2.835h-3.64v17.255h3.605v-8.995c0-3.43 1.505-5.145 4.095-5.145.805 0 1.47.21 2.135.595l1.75-3.15c-1.05-.63-2.065-.875-3.185-.875z" />
        <path d="m87.01 24.71c5.18 0 8.505-4.025 8.505-8.96 0-4.97-3.325-8.925-8.505-8.925-5.215 0-8.54 3.955-8.54 8.925 0 4.935 3.325 8.96 8.54 8.96zm0-3.15c-3.36 0-4.935-2.625-4.935-5.81s1.575-5.775 4.935-5.775c3.325 0 4.9 2.59 4.9 5.775 0 3.15-1.54 5.81-4.9 5.81z" />
        <path d="m106.085 24.71c3.325 0 5.95-1.785 7.105-5.005l-3.29-1.26c-.525 1.925-1.995 3.115-3.815 3.115-3.22 0-4.48-2.73-4.48-5.74s1.295-5.845 4.445-5.845c1.89 0 3.325 1.225 3.885 3.15l3.43-1.33c-1.33-3.36-3.99-4.97-7.315-4.97-5.495 0-8.12 4.445-8.12 8.995 0 4.62 2.73 8.89 8.155 8.89z" />
        <path d="m125.265 6.825c-1.89 0-3.885.98-4.76 3.15v-2.835h-3.64v17.255h3.605v-8.995c0-3.43 1.505-5.145 4.095-5.145.805 0 1.47.21 2.135.595l1.75-3.15c-1.05-.63-2.065-.875-3.185-.875z" />
        <path d="m136.955 24.71c5.18 0 8.505-4.025 8.505-8.96 0-4.97-3.325-8.925-8.505-8.925-5.215 0-8.54 3.955-8.54 8.925 0 4.935 3.325 8.96 8.54 8.96zm0-3.15c-3.36 0-4.935-2.625-4.935-5.81s1.575-5.775 4.935-5.775c3.325 0 4.9 2.59 4.9 5.775 0 3.15-1.54 5.81-4.9 5.81z" />
        <path d="m158.2 6.79c-2.03 0-4.025.7-5.215 2.275v-1.925h-3.605v24.395h3.605v-9.1c1.085 1.4 3.045 2.275 5.215 2.275 4.48 0 7.385-3.465 7.385-8.925 0-5.25-2.66-8.995-7.385-8.995zm-.84 14.77c-3.22 0-4.725-2.485-4.725-5.81 0-3.43 1.575-5.775 4.76-5.775 3.045 0 4.585 2.135 4.585 5.775 0 3.535-1.505 5.81-4.62 5.81z" />
        <path d="m175 24.71c3.29 0 6.545-1.75 6.545-5.6 0-6.37-9.345-4.48-9.345-7.595 0-1.05 1.015-1.785 2.625-1.785 1.995 0 3.08 1.085 3.745 2.31l2.8-1.54c-1.26-2.38-3.5-3.675-6.405-3.675-3.395 0-6.195 1.75-6.195 4.97 0 6.3 9.24 4.06 9.24 7.525 0 1.225-1.085 2.345-3.15 2.345-2.45 0-3.605-1.54-4.13-2.975l-2.905 1.295c.84 2.695 3.605 4.725 7.175 4.725z" />
      </g>
      <g transform="translate(0 10.2717)">
        <path
          d="m6.08995942 7.81622159h-6.08995942c0-2.50460475 1.20602111-4.73435483 3.08213039-6.16507754 1.35737572-1.0349051 3.064931-1.65114405 4.91971303-1.65114405h6.08995938c0 2.15851636-.8957441 4.11273976-2.3439326 5.52666206-1.4475005 1.41459431-3.44813337 2.28955953-5.65791078 2.28955953"
          fill="#096b62"
        />
        <path
          d="m6.08995942 15.9130772h-6.08995942c0-2.1585164.89574414-4.1127398 2.34393264-5.5266621 1.44750052-1.41459427 3.44813337-2.2895595 5.65791078-2.2895595h6.08995938c0 2.595999-1.2961459 4.8969828-3.2905869 6.3182973-1.32160103.9421668-2.95003912 1.4979243-4.71125648 1.4979243"
          fill="#04978a"
        />
        <path
          d="m6.08995942 24.01h-6.08995942c0-2.0946748.843458-3.9964809 2.21665717-5.399651 1.4564442-1.4891881 3.51005117-2.4165706 5.78518625-2.4165706h6.08995938c0 2.4568916-1.1606147 4.6490088-2.9748062 6.0817475-1.3738871 1.0846343-3.12272091 1.7344741-5.02703718 1.7344741"
          fill="#02dd97"
        />
      </g>
    </g>
  </svg>
);

export default EnvirocropsLogo;
