import {UserGoal} from '../../query/graphql';
import {CropId} from '../resources/crops';
import {EditableInputs, WithNulls} from './types';

export type ValidationResult = {valid: true} | {valid: false; error: string};

export class ValidationError extends Error {
  validation: string[];

  constructor(message: string, validation?: string[]) {
    super(message);
    this.validation = validation ?? [];
  }

  addError(error: string) {
    this.validation.push(error);
  }
}

export type Validator<V> = (value: V | null) => ValidationResult;

export type ValidatorWithInputs<V> = (value: V | null, inputs: EditableInputs) => ValidationResult;

export type ObjectValidator<T> = {
  [key in keyof Required<T>]: Validator<T[key] | null> | ValidatorWithInputs<T[key] | null>;
};

export const PLANTATION_LIFETIME = {
  min: 1,
  max: 26,
};

export function mergeValidators<V>(...validators: Validator<V>[]): Validator<V> {
  return (value: V | null) => {
    for (const validator of validators) {
      const validation = validator(value);
      if (!validation.valid) return validation;
    }
    return {valid: true};
  };
}

export function validate<T>(reportInputs: WithNulls<T>, allInputs: EditableInputs, validators: ObjectValidator<T>): T {
  const error = new ValidationError('Invalid input');

  for (const key in validators) {
    const validKey = key as keyof T;
    const value = reportInputs[validKey] as any;

    const validation = validators[validKey](value, allInputs);
    if (!validation.valid) error.addError(`${key}: ${validation.error}`);
  }

  if (error.validation.length) {
    throw error;
  }

  return reportInputs as T; // TODO: Try to avoid type casting
}

export function isEmpty(value: any): value is undefined | null | '' {
  return value === undefined || value == null || value === '';
}

export function nonEmpty<T>(value: T | null | undefined): value is T {
  return value !== undefined && value !== null && !(typeof value === 'string' && value === '');
}

export function noopValidator(_value: unknown): ValidationResult {
  return {valid: true};
}

export function validateRequired<T>(value: T | null): ValidationResult {
  return nonEmpty(value) ? {valid: true} : {valid: false, error: 'required'};
}

export function validateNonEmptyArray<T>(value: T | null): ValidationResult {
  return Array.isArray(value) && value.length > 0
    ? {valid: true}
    : {valid: false, error: 'object must be an array with at least 1 element'};
}

export function validatePositiveNumber<T>(value: T | null): ValidationResult {
  if (value === null) return {valid: true};
  return typeof value !== 'number' || value <= 0
    ? {valid: false, error: 'must be a number greater than 0'}
    : {valid: true};
}

export function validatePlantationLifetime(value: number | null): ValidationResult {
  if (isEmpty(value)) return {valid: false, error: 'required'};
  if (value < PLANTATION_LIFETIME.min) return {valid: false, error: `must be at least ${PLANTATION_LIFETIME.min}`};
  if (value > PLANTATION_LIFETIME.max) return {valid: false, error: `must be ${PLANTATION_LIFETIME.max} or lower`};
  return {valid: true};
}

export const validatePlantationLifetimePerCrop: Validator<{[cropId in CropId]: number | null}> = value => {
  if (value === null) return {valid: true};
  for (const cropId of Object.keys(value)) {
    const cropValue = value[cropId as keyof typeof value];
    const validation = validatePlantationLifetime(cropValue);
    if (!validation.valid) return {valid: false, error: `${cropValue} ${validation.error}`};
  }

  return {valid: true};
};

export const validateHeatConsumption: ValidatorWithInputs<number | null> = (value, inputs) => {
  if (inputs?.goal !== UserGoal.SupplyProperty) return {valid: true};
  if (validateRequired(value).valid) {
    return validatePositiveNumber(value);
  } else {
    return validateRequired(inputs?.propertyType);
  }
};

export const validateFuelRequirement: ValidatorWithInputs<number | null> = (value, inputs) => {
  if (inputs?.goal !== UserGoal.RunPowerStation) return {valid: true};
  if (validateRequired(value).valid) {
    return validatePositiveNumber(value);
  } else {
    return validateRequired(inputs?.fuelRequirement);
  }
};

export const validateFieldSizes: ValidatorWithInputs<{[cropId in CropId]: number[]} | null> = (value, inputs) => {
  // Only validate field sizes for CultivateLand
  if (inputs?.goal !== UserGoal.CultivateLand) return {valid: true};

  // Field sizes are optional
  if (!value) return {valid: true};

  for (const [key, sizes] of Object.entries(value)) {
    for (let i = 0; i < sizes.length; i++) {
      if (sizes[i] <= 0) return {valid: false, error: `${key}: field size ${i + 1} must be greater than 0`};
    }
  }

  return {valid: true};
};

export const validateCropPositiveNumbers: ValidatorWithInputs<{[cropId in CropId]: number} | null> = value => {
  if (!value) return {valid: true};

  for (const key of Object.keys(value)) {
    const validation = validatePositiveNumber(value[key as keyof typeof value]);
    if (!validation.valid) {
      return {...validation, error: `${key} ${validation.error}`};
    }
  }

  return {valid: true};
};

export function requireValidPercentage(percent: number, description: string) {
  if (percent < 0 || percent > 100) {
    throw new Error(`${description} must be between 0 and 100. Got ${percent}`);
  }
}
