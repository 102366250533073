import classNames from 'classnames';
import React, {ReactElement} from 'react';

import moth from '../../../../resources/animations/Moth.json';
import worms from '../../../../resources/animations/Worms.json';
import {useBreakpoint} from '../../../common/util/useBreakpoint';
import GameAudio from '../GameAudio';
import LottieAnimation from '../LottieAnimation';

const N_MOTHS_WEB = 8;
const N_MOTHS_MOBILE = 4;

const N_WORMS_WEB = 4;
const N_WORMS_MOBILE = 2;

const getRandomInterval = (min: number, max: number) => {
  const intervalLength = max - min;
  return Math.random() * intervalLength + min;
};

const Pests = (): ReactElement => {
  const {isMobile} = useBreakpoint('mobile');
  const nMoths = isMobile ? N_MOTHS_MOBILE : N_MOTHS_WEB;
  const nWorms = isMobile ? N_WORMS_MOBILE : N_WORMS_WEB;
  return (
    <div>
      <GameAudio src="sounds/pests.mp3" play={true} autoPlay={true} loop={true} />
      {Array(nMoths)
        .fill(null)
        .map((_, colIdx) => {
          const xTranslation = getRandomInterval(-50, 50);
          const yTranslation = getRandomInterval(-30, 70);
          const scale = getRandomInterval(0.2, 0.4);
          const rotation = getRandomInterval(0, 360);
          return (
            <LottieAnimation
              key={`moth_${colIdx}`}
              className={classNames('absolute top-0 left-0')}
              animation={moth}
              style={{
                transform: `translateY(${yTranslation}%) translateX(${xTranslation}%) scale(${scale}) rotate(${rotation}deg)`,
              }}
              loop
            />
          );
        })}
      <div className="absolute h-[5rem] w-full bottom-[20rem]">
        {Array(nWorms)
          .fill(null)
          .map((_, colIdx) => {
            const randomLeft = getRandomInterval((colIdx * 100) / nWorms - 10, (colIdx * 100) / nWorms + 10);
            const left = Math.max(0, Math.min(80, randomLeft));
            const yTranslation = getRandomInterval(-35, -15);
            const scale = getRandomInterval(0.3, 0.5);
            return (
              <LottieAnimation
                key={`worm_${colIdx}`}
                className={classNames('absolute top-0 left-0')}
                animation={worms}
                startPointFraction={colIdx / nWorms}
                style={{
                  left: `${left}%`,
                  transform: `translateY(${yTranslation}%) scale(${scale})`,
                }}
                loop
              />
            );
          })}
      </div>
    </div>
  );
};

export default Pests;
