import {ComponentProps, FC} from 'react';

const LeftArrow: FC<ComponentProps<'svg'>> = props => (
  <svg height="16" viewBox="0 0 16 16" width="16" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="1.5"
      transform="translate(1 1)"
    >
      <path
        d="m11.8026991 11.8277963h-9.65559258v-9.65559261"
        strokeLinejoin="round"
        transform="matrix(.70710678 .70710678 -.70710678 .70710678 6.992649 -2.881749)"
      />
      <path d="m.507776 7h13.396638" />
    </g>
  </svg>
);

export default LeftArrow;
