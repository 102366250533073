import React, {ReactElement} from 'react';

import {HelpText} from '../../decision/types';
import HelpDialogueButton from './HelpDialogueButton';
import HelpTooltipButton from './HelpTooltipButton';

type Props = Partial<HelpText> & {
  className?: string;
  tooltipClassName?: string;
};

const HelpButton = ({isDialogue, title, content, className, tooltipClassName}: Props): ReactElement | null => {
  if (!content) return null;

  return isDialogue ? (
    <HelpDialogueButton className={className} title={title}>
      {content}
    </HelpDialogueButton>
  ) : (
    <HelpTooltipButton className={className} tooltipClassName={tooltipClassName}>
      {content}
    </HelpTooltipButton>
  );
};

export default HelpButton;
