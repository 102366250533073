import classNames from 'classnames';
import React, {useCallback, useId, useMemo} from 'react';

import Col from '../../../common/Col';
import Row from '../../../common/Row';
import {Option, OptionValue} from '../../decision/types';
import HelpTooltipButton from '../buttons/HelpTooltipButton';

type PropsType<T> = {
  option: Option<T>;
  onChange: (checked: boolean, value: T) => void;
  checked?: boolean;
};

const OptionView = <T extends OptionValue>(props: PropsType<T>) => {
  const {option, onChange, checked} = props;
  const {icon, label, examples, value} = option;
  const optionId = useId();

  const isRich = !!icon || !!examples;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.checked, value);
    },
    [onChange, value]
  );
  const Icon = useMemo(
    () => icon && React.cloneElement(icon, {className: classNames('w-sm h-sm text-primary-500')}),
    [icon]
  );

  return (
    <label className="flex-1">
      <input className={'hidden peer'} type={'checkbox'} checked={checked} onChange={handleChange} />
      <Col
        className={classNames(
          'gap-y-6 justify-center border-thin border-gray-100 rounded-xl cursor-pointer',
          'hover:border-primary-200',
          'peer-checked:border-primary-300 peer-checked:bg-primary-100',
          {'font-semibold text-left text-base leading-snug p-sm': isRich},
          {'h-full text-center font-normal p-4': !isRich}
        )}
      >
        {Icon}
        <Row className={'gap-4 items-center'}>
          {label}
          {option.tooltip && (
            <HelpTooltipButton className={'mb-1'} tooltipProps={{place: 'bottom', offset: 24}}>
              {option.tooltip}
            </HelpTooltipButton>
          )}
        </Row>
        {!!examples && examples.length > 0 && (
          <Col className="gap-y-3 text-2xs">
            <div className="text-primary-600 font-semibold">Example</div>
            <ul className="space-y-2">
              {examples.map((example, i) => (
                <li key={`${optionId}-example-${i}`} className="font-normal">
                  {example}
                </li>
              ))}
            </ul>
          </Col>
        )}
      </Col>
    </label>
  );
};

export default OptionView;
