import {useMemo} from 'react';

import {emptyInputs, useInputs} from '../../query/useScenario';
import {generateCostCollectionSet} from '../decision/calculations';
import {CostCollectionSet, CropMap} from '../decision/types';
import {buildCropMap} from '../decision/util';
import COSTS from '../resources/costs';

/**
 * Get a map containing actual costs for each crop type, considering default values as well as user inputs.
 */
export function useCostMap(scenarioId: string): CropMap<CostCollectionSet> {
  const {data: inputs} = useInputs(scenarioId);
  const {costs} = inputs ?? emptyInputs;

  return useMemo(() => buildCropMap(cropId => generateCostCollectionSet(COSTS[cropId], costs, cropId)), [costs]);
}
