import React, {ReactElement, ReactNode, useMemo} from 'react';

import QuestionView from '../components/questions/QuestionView';

/**
 * Convert the given React node to an array of elements and return only the ones of `QuestionView` type.
 */
export function useQuestionViewChildren(children: ReactNode) {
  return useMemo(() => {
    const childrenArr = React.Children.toArray(children); // Note this flattens children recursively, so it can erroneously trigger the warning if elements have children
    const result = childrenArr.filter(child => React.isValidElement(child) && child.type === QuestionView);
    if (childrenArr.length !== result.length) {
      console.warn('Children must be of type QuestionView');
    }
    return result as ReactElement<React.ComponentProps<typeof QuestionView>>[];
  }, [children]);
}
