import React, {ReactElement} from 'react';

import flood from '../../../../resources/animations/Flooding.json';
import GameAudio from '../GameAudio';
import LottieAnimation from '../LottieAnimation';

const Flood = (): ReactElement => {
  return (
    <div className="absolute h-[90rem] w-full -bottom-[40rem]">
      <GameAudio src="sounds/water.mp3" play={true} autoPlay={true} loop />
      <LottieAnimation className="w-full h-full" animation={flood} loop />
    </div>
  );
};

export default Flood;
