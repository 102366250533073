/**
 * Throw this error to report failures in game logic, such as a game status that should never happen. This will be
 * caught by an ErrorBoundary and maybe reported to some error logging service (e.g. Bugsnag).
 */
export class GameLogicError extends Error {
  constructor(message: string) {
    super(message);
  }

  static assertNonNullish<T>(value: T | null | undefined): T {
    if (![null, undefined, ''].includes(value as any)) {
      return value as T;
    } else {
      throw new GameLogicError(`unexpected falsy value: ${value}`);
    }
  }
}
