import {useRouter} from 'next/router';
import React, {PropsWithChildren, ReactElement, useEffect, useState} from 'react';

import ServerCookie, {cookies} from '../../cookies';
import {detectRedirection} from '../../redirect';
import {Spinner} from '../../resources/Icons';
import {isPublicPath} from '../../urls';
import {installAuthCookie} from '../firebase/public';
import {useCurrentUserQuery} from '../query/graphql';
import {hasFilledStartFlow, useUpdateUser} from '../query/useCurrentUser';
import {useAppDispatch} from '../store';
import NewsletterModal from '../tool/components/modals/NewsletterModal';
import PrivacyConsentModal from '../tool/components/modals/PrivacyConsentModal';
import StartFlowModal from '../tool/components/modals/StartFlowModal';
import NewScenarioModal from '../tool/components/settings/NewScenarioModal';
import {useStartFlow, validateStartFlow} from '../tool/decision/startFlow';
import {useFirebaseAuth} from './EnvironmentProvider';

const GlobalInit = ({children}: PropsWithChildren): ReactElement => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const {data, error} = useCurrentUserQuery();
  const currentUser = data?.currentUser;
  if (error) {
    // Abort app load if we can't get the current user, which most probably means a database connection error
    throw error;
  }

  const firebaseAuth = useFirebaseAuth();
  const startFlow = useStartFlow();
  const updateUser = useUpdateUser();

  const [openModal, setOpenModal] = useState<'privacy' | 'started' | 'scenario' | 'newsletter'>();
  const firstScenario = currentUser?.scenarios[0];
  const redirectURL = detectRedirection(router, firebaseAuth?.currentUser);

  useEffect(() => {
    (async () => {
      if (redirectURL) {
        if (firebaseAuth?.currentUser) {
          await installAuthCookie(firebaseAuth.currentUser);
        }
        await router.replace(redirectURL);
      }
    })();
  }, [firebaseAuth?.currentUser, redirectURL, router]);

  // Determine the modal to show
  useEffect(() => {
    setOpenModal(() => {
      const isPublic = isPublicPath(router.asPath);

      // Determine open status of 'privacy' modal
      if (!isPublic && currentUser && !currentUser.consentPrivacy) {
        return 'privacy';
      }

      // Determine open status of 'started' modal
      if (!isPublic && currentUser && !hasFilledStartFlow(currentUser)) {
        const {error} = validateStartFlow(startFlow);
        if (error) {
          return 'started';
        } else {
          // Update user with get-started data saved in session storage
          void updateUser({data: {farmerType: startFlow.farmerType, farmerProfile: startFlow.farmerProfile}});
        }
      }

      // Determine open status of 'scenario' modal
      if (!isPublic && firstScenario && !firstScenario.name) {
        return 'scenario';
      }

      // Determine open status of 'newsletter' modal
      const isBannerClosed = cookies.get(ServerCookie.newsletterClosed);
      if (isPublic && !isBannerClosed) {
        return 'newsletter';
      }

      return undefined;
    });
  }, [currentUser, dispatch, firstScenario, router.asPath, startFlow, updateUser]);

  if (redirectURL) {
    return (
      <div className={'w-full py-[200px]'}>
        <Spinner className={'m-auto'} />
      </div>
    );
  }

  return (
    <>
      <PrivacyConsentModal isOpen={openModal === 'privacy'} />
      <StartFlowModal isOpen={openModal === 'started'} />
      <NewScenarioModal isOpen={openModal === 'scenario'} title={'Complete your scenario'} scenario={firstScenario} />
      <NewsletterModal startOpen={openModal === 'newsletter'} />
      {children}
    </>
  );
};

export default GlobalInit;
