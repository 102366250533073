import {ArrowLeftIcon, ArrowRightIcon} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import Link from 'next/link';
import React, {ComponentProps, useMemo} from 'react';
import {UrlObject} from 'url';

import {Spinner} from '../../../../resources/Icons';

type CommonProps = ComponentProps<'button'> & {
  variant: 'primary' | 'secondary' | 'light';
  direction?: 'left' | 'right';
  size?: 'normal' | 'small';
  lookEnabled?: boolean;
  loading?: boolean;
};

type LinkProps = {href: string | UrlObject; disabled?: boolean} & CommonProps & ComponentProps<typeof Link>;

type ButtonProps = {href?: never} & CommonProps & ComponentProps<'button'>;

type Props = LinkProps | ButtonProps;

const SquareButton = ({
  className,
  variant,
  direction,
  size = 'normal',
  children,
  disabled,
  lookEnabled,
  loading,
  ...props
}: Props) => {
  const componentClassName = useMemo(
    () =>
      classNames(
        'relative flex items-center justify-center rounded-[1rem] disabled:pointer-events-none',
        !lookEnabled && 'disabled:bg-white disabled:border-gray-100 disabled:text-gray-150',
        {
          'text-white bg-primary-700 hover:bg-primary-300': variant === 'primary',
          'text-primary-700 border-thin border-gray-100 bg-white hover:border-primary-300': variant === 'secondary',
          'text-white border-thin border-gray-100 hover:border-primary-300': variant === 'light',
          'h-[5rem] w-[5rem]': size === 'normal',
          'h-[4rem] w-[4rem]': size === 'small',
          'p-5': !!direction,
        },
        className
      ),
    [className, direction, lookEnabled, size, variant]
  );

  const Content = useMemo(
    () => (
      <>
        {direction === 'left' && <ArrowLeftIcon className={classNames('h-full w-full', loading && 'invisible')} />}
        {direction === 'right' && <ArrowRightIcon className={classNames('h-full w-full', loading && 'invisible')} />}
        {!direction && <div className={classNames(loading && 'invisible')}>{children}</div>}
        {loading && (
          <div className="flex absolute inset-0 items-center justify-center">
            <Spinner />
          </div>
        )}
      </>
    ),
    [children, direction, loading]
  );

  const isDisabled = disabled || loading;

  if ('href' in props && props.href !== undefined) {
    return !isDisabled ? (
      <Link className={componentClassName} {...props}>
        {Content}
      </Link>
    ) : (
      <button className={componentClassName} disabled={true}>
        {Content}
      </button>
    );
  }

  return (
    <button className={componentClassName} disabled={isDisabled} {...props}>
      {Content}
    </button>
  );
};

export default SquareButton;
