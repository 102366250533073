import {useCallback} from 'react';

import {useCostReport} from '../tool/hooks/useCostReport';
import {useLandReport} from '../tool/hooks/useLandReport';
import {Flow, useFlowProgressQuery, useUpdateInputsMutation} from './graphql';
import queryClient from './queryClient';
import useCurrentUser from './useCurrentUser';

export const useFlowProgress = (scenarioId: string, flow: Flow) => {
  const currentUser = useCurrentUser();
  const {data: landReportData, isLoading: landReportLoading} = useLandReport(scenarioId);
  const {data: costReportData, isLoading: costReportLoading} = useCostReport(scenarioId);

  const flowProgressQuery = useFlowProgressQuery(
    {scenarioId},
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!currentUser && !landReportLoading && !costReportLoading,
      queryKey: useFlowProgressQuery.getKey({scenarioId}),
    }
  );
  switch (flow) {
    case Flow.Land:
      return landReportData?.report ? 1 : flowProgressQuery?.data?.scenario?.inputs.landFlowProgress ?? 0;
    case Flow.Cost:
      return costReportData?.report ? 1 : flowProgressQuery?.data?.scenario?.inputs.costFlowProgress ?? 0;
  }
};

export const useUpdateFlowProgress = (scenarioId: string | null, flow: Flow | null) => {
  const mutation = useUpdateInputsMutation({
    onSuccess: () => {
      if (scenarioId) invalidateFlowProgress(scenarioId);
    },
  });

  let key = '';
  if (flow === Flow.Land) key = 'landFlowProgress';
  if (flow === Flow.Cost) key = 'costFlowProgress';

  // It is important to take this function out of "mutation" in order
  // to avoid having "mutation" in the useCallback dependency array
  const mutateAsync = mutation.mutateAsync;

  const mutator = useCallback(
    async (progress: Number) => {
      if (!scenarioId) throw new Error(`scenarioId not provided`);
      if (!key) throw new Error(`Key not defined for flow ${flow}`);
      return await mutateAsync({scenarioId, inputs: {[key]: progress}});
    },
    [flow, key, mutateAsync, scenarioId]
  );

  return mutator;
};

export const invalidateFlowProgress = (scenarioId: string) => {
  queryClient.invalidateQueries(useFlowProgressQuery.getKey({scenarioId}));
};

export const invalidateAllFlowProgress = () => {
  // Pop variables to invalidate query for all scenarios
  queryClient.invalidateQueries(useFlowProgressQuery.getKey({scenarioId: 'x'}).slice(0, -1));
};
