import Bugsnag, {NotifiableError} from '@bugsnag/js';

export function notify(error: NotifiableError, prefix?: string): NotifiableError {
  const tag = prefix ? `[${prefix}] ` : '';

  if (Bugsnag.isStarted()) {
    console.error(`${tag}Notifying error to Bugsnag:`, error);
    Bugsnag.notify(error);
  } else {
    console.error(`${tag}Bugsnag not started. Skipping error notification:`, error);
  }
  return error;
}

export function subscribeToSecurityPolicyViolation() {
  window.addEventListener('securitypolicyviolation', event => {
    notify(
      new Error(
        `Security Policy Violation: ${JSON.stringify(
          {
            blockedURI: event.blockedURI,
            columnNumber: event.columnNumber,
            disposition: event.disposition,
            documentURI: event.documentURI,
            effectiveDirective: event.effectiveDirective,
            lineNumber: event.lineNumber,
            originalPolicy: event.originalPolicy,
            referrer: event.referrer,
            sample: event.sample,
            sourceFile: event.sourceFile,
            statusCode: event.statusCode,
            violatedDirective: event.violatedDirective,
          },
          undefined,
          2
        )}`
      )
    );
  });
}
