import {XMarkIcon} from '@heroicons/react/24/outline';
import React, {ReactElement, ReactNode} from 'react';

import Col from '../../common/Col';
import Row from '../../common/Row';
import SquareButton from './buttons/SquareButton';
import Modal from './Modal';

export type ModalProps = {
  isOpen: boolean;
  closeable?: boolean; // Defaults to true
  children: React.ReactNode;
  contentLabel?: string;
  contentStyle?: React.CSSProperties;
  actions?: ReactNode;
  onRequestClose?: () => void;
  closeDisabled?: boolean;
};

const ModalWithActions = ({
  isOpen,
  closeable = true,
  children,
  contentLabel,
  actions,
  onRequestClose,
  closeDisabled,
}: ModalProps): ReactElement => {
  return (
    <Modal isOpen={isOpen} closeable={closeable} title={contentLabel} className={'relative !grow-0 w-fit'}>
      {onRequestClose && (
        <SquareButton
          className={'!absolute top-xs right-xs border-primary-800'}
          variant={'secondary'}
          onClick={onRequestClose}
          disabled={closeDisabled}
        >
          <XMarkIcon className={'w-sm'} />
        </SquareButton>
      )}

      <Col className={'px-lg mobile:px-sm py-xs gap-sm'}>{children}</Col>

      {actions && <Row className={'px-lg mobile:px-sm py-xs border-t border-gray-100 justify-between'}>{actions}</Row>}
    </Modal>
  );
};

export default ModalWithActions;
