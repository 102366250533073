import classNames from 'classnames';
import React, {useCallback} from 'react';

import useCurrentUser from '../../../query/useCurrentUser';
import {totalCollectionCost} from '../../decision/calculations';
import {CostCollection, CostOperation} from '../../decision/types';
import {formatMoney} from '../../decision/util';
import AdditionalInfo from './AdditionalInfo';
import CostTableRow from './CostTableRow';

export type Props = {
  value: CostCollection;
  cropName: string;
  onChange: (value: CostCollection) => void;
  error?: string;
};

const QuestionCostsTable = (props: Props) => {
  const {value, cropName, onChange, error} = props;
  const currentUser = useCurrentUser();
  const imperialUnits = !!currentUser?.props.prefersImperial;

  const handleChangeOperation = useCallback(
    (index: number, newOperation: CostOperation) => {
      const newValue = {...value, operations: [...value.operations]};
      newValue.operations[index] = newOperation;
      onChange(newValue);
    },
    [onChange, value]
  );

  return (
    <>
      <p className={'text-sm'}>Select the tick boxes that apply to you and edit the estimated values if needed.</p>
      <h2 className={'mt-md text-base font-semibold'}>
        {value.title} - {cropName}
      </h2>
      <div className={'overflow-x-auto'}>
        <table
          className={classNames(
            'w-full mt-xs border-t-[1px] border-gray-100 text-sm',
            '[&_tr]:border-b-[1px] [&_tr]:border-gray-100',
            '[&_thead_tr]:border-primary-300',
            '[&_th]:p-4 [&_th]:font-semibold',
            '[&_td]:p-4'
          )}
        >
          <thead>
            <tr>
              <th>Operation</th>
              <th className={'bg-primary-300/[.04]'}>
                {value.costUnit === 'hectare'
                  ? imperialUnits
                    ? 'Cost / acre'
                    : 'Cost / ha'
                  : value.costUnit === 'tonne'
                  ? 'Cost / tonne'
                  : 'Cost'}
              </th>
              <th className={'bg-primary-300/[.08]'}>Number of operations</th>
            </tr>
          </thead>
          <tbody>
            {value.operations.map((operation, idx) => (
              <CostTableRow key={idx} index={idx} operation={operation} onChange={handleChangeOperation} />
            ))}
            {value.operations.length > 0 && (
              <tr>
                <td className={'text-right font-bold'}>Total:</td>
                <td className={'text-center'}>£{formatMoney(totalCollectionCost(value), true)}</td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {error && <AdditionalInfo error={error} />}
    </>
  );
};

export default QuestionCostsTable;
