import {useMemo} from 'react';

import {Place} from '../types';

export enum PlaceId {
  sunnyshire = 'sunnyshire',
  windyshire = 'windyshire',
  rainyshire = 'rainyshire',
  dryshire = 'dryshire',
}

const PLACES: Place[] = [
  {
    id: PlaceId.dryshire,
    name: 'Dryshire',
    description:
      'It rains a lot in this green and pleasant land, but Dryshire gets the least rainfall of all. The fields are large, regular shaped, and flat as a pancake.',
    avgYield: {miscanthus: 10, willow: 8},
    bgClass: 'bg-dry',
  },
  {
    id: PlaceId.sunnyshire,
    name: 'Sunnyshire',
    description:
      "You're in the part of the world that everyone flocks to for their summer holidays. There is indeed lots of sunshine, a long growing season, mild winters, and ample rain. It’s not quite the Garden of Eden, but it’s pretty close",
    avgYield: {miscanthus: 12, willow: 10},
    bgClass: 'bg-sunny',
  },
  {
    id: PlaceId.rainyshire,
    name: 'Rainyshire',
    description:
      "Nice weather for ducks! The growing conditions in Rainyshire are decidedly lush and the farmland here produces the greenest grass. The terrain is dominated by hills and valleys - spectacular scenery but don't forget your waterproofs!",
    avgYield: {miscanthus: 9, willow: 12},
    bgClass: 'bg-rainy',
  },
  {
    id: PlaceId.windyshire,
    name: 'Windyshire',
    description:
      "Hold on to your hat! Windyshire gets the full force of the coastal breeze. There’s very little shelter from the elements here and sometimes a bit of salt spray. If you don't like the weather in Windyshire, wait half an hour! You might even get four seasons in one day!",
    avgYield: {miscanthus: 10, willow: 11},
    bgClass: 'bg-windy',
  },
];

export const useAllPlaces = () => useMemo(() => PLACES, []);
