import {UserGoal} from '../../query/graphql';
import {convertArea, convertValuePerHa, getReturnOfInvestmentYear} from '../decision/calculations';
import {CostReport, LandReport} from '../decision/types';
import {formatMoney, formatNumber} from '../decision/util';
import {CropId} from './crops';

export enum CropSortKey {
  productionCost = 'productionCost',
  costPerTonne = 'costPerTonne',
  profitPerHa = 'profitPerHa',
  requiredLand = 'requiredLand',
  quickestReturn = 'quickestReturn',
}

/**
 * Get a description for the given sort key. Used e.g. in sort dropdown options.
 */
export function getDescription(sortKey: CropSortKey, imperial: boolean): string {
  switch (sortKey) {
    case CropSortKey.productionCost:
      return 'Lowest production cost';
    case CropSortKey.costPerTonne:
      return 'Lowest cost per tonne';
    case CropSortKey.profitPerHa:
      return `Highest profit per ${imperial ? 'acre' : 'hectare'}`;
    case CropSortKey.requiredLand:
      return 'Lowest land requirement';
    case CropSortKey.quickestReturn:
      return 'Quickest return';
  }
}

/**
 * Get a label for the given sort key. Used e.g. in table headers.
 */
export function getLabel(args: {sortKey: CropSortKey; imperialUnits: boolean}): string {
  const {sortKey, imperialUnits} = args;
  switch (sortKey) {
    case CropSortKey.productionCost:
      return 'Production cost';
    case CropSortKey.costPerTonne:
      return 'Cost per tonne';
    case CropSortKey.profitPerHa:
      return imperialUnits ? 'Profit per acre' : 'Profit per hectare';
    case CropSortKey.requiredLand:
      return 'Required land';
    case CropSortKey.quickestReturn:
      return 'Return of investment';
  }
}

/**
 * Get the units for the value associated with the given sort key.
 */
export function getUnits(args: {sortKey: CropSortKey; landReport: LandReport; imperialUnits: boolean}): string {
  const {sortKey, landReport, imperialUnits} = args;
  const useGJ = landReport.goal === UserGoal.RunPowerStation;

  switch (sortKey) {
    case CropSortKey.productionCost:
      return useGJ ? '£/GJ' : 'p/kWh';
    case CropSortKey.costPerTonne:
      return '£/tonne';
    case CropSortKey.profitPerHa:
      return imperialUnits ? '£/ac/year' : '£/ha/year';
    case CropSortKey.requiredLand:
      return imperialUnits ? 'acres' : 'ha';
    case CropSortKey.quickestReturn:
      return 'years';
  }
}

/**
 * Get the value used when sorting by the given sort key. Note that values are not converted according to user's
 * preferred units (but obviously this doesn't matter for the sort order).
 */
export function getSortValue(args: {
  sortKey: CropSortKey;
  cropId: CropId;
  landReport: LandReport;
  costReport: CostReport;
}): number {
  const {sortKey, cropId, landReport, costReport} = args;
  const useGJ = landReport.goal === UserGoal.RunPowerStation;

  switch (sortKey) {
    case CropSortKey.productionCost:
      return useGJ
        ? costReport.finance[cropId].lifetime.cost.poundsPerGj
        : costReport.finance[cropId].lifetime.cost.pencePerKwh;

    case CropSortKey.costPerTonne:
      return costReport.finance[cropId].lifetime.cost.poundsPerTonne;

    case CropSortKey.profitPerHa:
      return costReport.finance[cropId].lifetime.profit.poundsPerHaPerYr;

    case CropSortKey.requiredLand:
      return landReport.results[cropId].areaRequired;

    case CropSortKey.quickestReturn:
      return getReturnOfInvestmentYear(costReport.finance[cropId].annualFinance);
  }
}

/**
 * Get the formatted value used when sorting by the given sort key. Returned value is converted according to user's
 * preferred units.
 */
export function getFormattedSortValue(args: {
  sortKey: CropSortKey;
  cropId: CropId;
  landReport: LandReport;
  costReport: CostReport;
  imperialUnits: boolean;
}): string {
  const raw = getSortValue(args);

  switch (args.sortKey) {
    case CropSortKey.productionCost:
      return formatMoney(raw);
    case CropSortKey.costPerTonne:
      return formatMoney(raw);
    case CropSortKey.profitPerHa:
      return formatMoney(convertValuePerHa(raw, args.imperialUnits));
    case CropSortKey.requiredLand:
      return formatNumber(convertArea(raw, args.imperialUnits), 1, 1);
    case CropSortKey.quickestReturn:
      return formatReturn(raw);
  }
}

function formatReturn(returnOfInvestmentYearIndex: number, plantingYear?: number): string {
  if (returnOfInvestmentYearIndex === Infinity) return 'Never';
  return plantingYear === undefined
    ? `${returnOfInvestmentYearIndex}`
    : `${plantingYear + returnOfInvestmentYearIndex} (${returnOfInvestmentYearIndex})`;
}
