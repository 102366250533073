import {createInstance} from '@jonkoops/matomo-tracker-react';

import {Environment} from '../../../pages/api/environment';

export enum Instruction {
  requireCookieConsent = 'requireCookieConsent',
  rememberCookieConsentGiven = 'rememberCookieConsentGiven',
  forgetCookieConsentGiven = 'forgetCookieConsentGiven',
}

export const createMatomoInstance = (matomoConfig: Environment['matomo']) => {
  return createInstance({
    urlBase: matomoConfig.urlBase,
    siteId: matomoConfig.siteId,
    heartBeat: {
      active: matomoConfig.heartBeatActive,
      seconds: matomoConfig.heartBeatSeconds,
    },
    linkTracking: false,
    configurations: {
      disableCookies: false,
      setSecureCookie: false,
    },
  });
};
