import React, {ReactElement} from 'react';

import crack from '../../../../resources/animations/Crack.json';
import {useBreakpoint} from '../../../common/util/useBreakpoint';
import LottieAnimation from '../LottieAnimation';

const N_CRACKS_WEB = 12;
const N_CRACKS_MOBILE = 8;

const getRandomInterval = (min: number, max: number) => {
  const intervalLength = max - min;
  return Math.random() * intervalLength + min;
};

const Drought = (): ReactElement => {
  const {isMobile} = useBreakpoint('mobile');
  const nCracks = isMobile ? N_CRACKS_MOBILE : N_CRACKS_WEB;
  return (
    <div className="absolute w-full h-[10rem] bottom-[15rem]">
      {Array(nCracks)
        .fill(null)
        .map((_, colIdx) => {
          const top = getRandomInterval(0, 100);
          const left = getRandomInterval(0, 100);
          const scale = getRandomInterval(0.4, 1);
          const rotation = getRandomInterval(0, 360);
          return (
            <LottieAnimation
              key={`crack_${colIdx}`}
              className="absolute"
              animation={crack}
              style={{
                transform: `scale(${scale}) rotate(${rotation}deg)`,
                top: `${top}%`,
                left: `${left}%`,
              }}
            />
          );
        })}
    </div>
  );
};

export default Drought;
