import {CheckIcon} from '@heroicons/react/20/solid';
import {XMarkIcon} from '@heroicons/react/24/outline';
import React, {ReactElement, useCallback, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';

import {MAX_UPLOAD_SIZE_EXCEEDED, SCENARIO_NAME_CONFLICT, SCENARIO_NAME_TOO_LONG} from '../../../../controllers/errors';
import Col from '../../../common/Col';
import Row from '../../../common/Row';
import {ScenarioInfo, useAddScenarioMutation, useEditScenarioMutation} from '../../../query/graphql';
import {invalidateCurrentUser} from '../../../query/useCurrentUser';
import {fileToBase64File, MAX_UPLOAD_SIZE_BYTES} from '../../util/image';
import Button from '../buttons/Button';
import SquareButton from '../buttons/SquareButton';

type Props = {
  variant: 'vertical' | 'horizontal';
  scenario: ScenarioInfo | undefined; // undefined means new scenario
  logoFile: File | undefined;
  onSave?: (scenarioId: string) => void;
  onCancel?: () => void; // if undefined, close button is not displayed
  onStartLoading?: () => void;
  onEndLoading?: () => void;
};

type FormFields = {
  logo: File[];
  name: string;
};

const EditScenarioForm = ({
  variant,
  scenario,
  logoFile,
  onSave,
  onCancel,
  onStartLoading,
  onEndLoading,
}: Props): ReactElement => {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<FormFields>();
  const [loading, setLoading] = useState(false);
  const [globalError, setGlobalError] = useState<string | undefined>(undefined);
  const addScenario = useAddScenarioMutation();
  const editScenario = useEditScenarioMutation();

  const onSubmit = useCallback(
    async ({name}: FormFields) => {
      setLoading(true);
      onStartLoading?.();
      setGlobalError(undefined);
      try {
        if (scenario) {
          // Edit existing scenario
          await editScenario.mutateAsync({scenario: {uuid: scenario.uuid, name}});
          onSave?.(scenario.uuid);
        } else {
          // Create new scenario
          const logo = logoFile ? await fileToBase64File(logoFile) : undefined;
          const {addScenario: uuid} = await addScenario.mutateAsync({scenario: {name, logo}}, {});
          onSave?.(uuid);
        }
        invalidateCurrentUser();
      } catch (e) {
        if ((e as any)?.message === MAX_UPLOAD_SIZE_EXCEEDED) {
          setGlobalError(`File can't exceed ${MAX_UPLOAD_SIZE_BYTES / 1_000_000} MB`);
        } else if ((e as any)?.message === SCENARIO_NAME_CONFLICT) {
          setGlobalError('A scenario with this name already exists. Please choose another name.');
        } else if ((e as any)?.message === SCENARIO_NAME_TOO_LONG) {
          const maxLength = (e as any)?.errors?.[0]?.extensions?.maxLength;
          setGlobalError(maxLength ? `Cannot be longer than ${maxLength} characters` : 'Name is too long');
        } else {
          setGlobalError((e as any)?.message || 'An error occurred');
        }
      } finally {
        setLoading(false);
        onEndLoading?.();
      }
    },
    [addScenario, editScenario, logoFile, onEndLoading, onSave, onStartLoading, scenario]
  );

  const nameInput = useMemo(
    () => (
      <>
        <label htmlFor={'text'} className={'flex flex-col gap-2'}>
          Name of company or land*
        </label>
        <input
          id="name"
          type={'text'}
          defaultValue={scenario?.name}
          {...register('name', {required: 'A name is required', minLength: 1, disabled: loading})}
          autoFocus
        />
        {errors.name && <p className="!font-normal text-xs text-red-200">{errors.name.message}</p>}
      </>
    ),
    [errors.name, loading, register, scenario?.name]
  );

  if (variant === 'vertical') {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className={'flex flex-col'}>
        {/* Content */}
        <div className={'pb-lg px-xl flex flex-col gap-sm'}>
          <Col className={'grow gap-2 justify-end'}>{nameInput}</Col>
          {globalError && <p className={'text-xs text-red-200'}>{globalError}</p>}
        </div>
        {/* Buttons */}
        <Row className={'p-sm border-t border-gray-100 justify-end'}>
          <Button type={'submit'} variant={'primary'} accessory={'forward'} loading={loading}>
            Continue
          </Button>
        </Row>
      </form>
    );
  }

  if (variant === 'horizontal') {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className={'grow flex flex-row items-center gap-sm'}>
        <Col className={'grow gap-2 justify-end'}>
          {nameInput}
          {globalError && <p className={'text-xs text-red-200'}>{globalError}</p>}
        </Col>
        <SquareButton type={'submit'} variant={loading ? 'secondary' : 'primary'} size={'normal'} loading={loading}>
          <CheckIcon className={'w-[2rem]'} />
        </SquareButton>
        <SquareButton type={'button'} variant={'secondary'} size={'normal'} onClick={onCancel} disabled={loading}>
          <XMarkIcon className={'w-[2rem]'} />
        </SquareButton>
      </form>
    );
  }

  throw new Error('invalid variant');
};

export default EditScenarioForm;
