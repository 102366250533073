import {CostCollectionDefinition, CostCollectionDefinitionSet} from '../../decision/types';
import {isEstablishmentYear, isHarvestYear, isNotEstablishmentYear} from '../../decision/util';
import {CostOperationId} from './operationNames';

const establishmentLand: CostCollectionDefinition = {
  title: 'Land Preparation Operations',
  costUnit: 'hectare',
  active: isEstablishmentYear,
  operations: [
    {id: CostOperationId.est_ploughing, name: 'Ploughing', cost: 70, enabled: true},
    {id: CostOperationId.est_powerHarrowing, name: 'Power Harrowing', cost: 70, enabled: true},
    {id: CostOperationId.est_rolling, name: 'Rolling', cost: 30, enabled: true},
    {id: CostOperationId.est_subSoiling, name: 'Sub-Soiling', cost: 70, enabled: true},
    {id: CostOperationId.est_discing, name: 'Discing', cost: 50, enabled: false},
    {id: CostOperationId.est_fertiliserSpreading, name: 'Fertiliser Spreading', cost: 200, enabled: true},
    {id: CostOperationId.est_prePlantSpraying, name: 'Pre-Plant Spraying', cost: 0, enabled: false},
  ],
};

const establishmentWeed: CostCollectionDefinition = {
  title: 'Weed Control Operations',
  costUnit: 'hectare',
  active: isEstablishmentYear,
  operations: [
    {id: CostOperationId.est_spraying, name: 'Spraying (conventional tractor & boom)', cost: 23, enabled: true},
    {id: CostOperationId.est_prePlantingGlyphosate, name: 'Pre-Planting - Glyphosate', cost: 32, enabled: true},
    {
      id: CostOperationId.est_postPlantingStompPendimethalin,
      name: 'Post planting - Stomp (Pendimethalin)',
      cost: 50,
      enabled: true,
    },
    {
      id: CostOperationId.est_postPlantingFlexidorIsoxaben,
      name: 'Post Planting - Flexidor (Isoxaben)',
      cost: 30,
      enabled: true,
    },
    {
      id: CostOperationId.est_postCutBackStompPendimethalin,
      name: 'Post Cut Back - Stomp',
      cost: 45,
      enabled: true,
    },
  ],
};

const establishmentPlanting: CostCollectionDefinition = {
  title: 'Planting & Plant Material Operations',
  active: isEstablishmentYear,
  costUnit: 'hectare',
  operations: [
    {id: CostOperationId.est_contractPlanting, name: 'Contract Planting', cost: 500, enabled: true},
    {id: CostOperationId.est_plantMaterial, name: 'Plant Material', cost: 1000, enabled: true},
  ],
};

const establishmentPest: CostCollectionDefinition = {
  title: 'Pest Control Operations',
  active: isEstablishmentYear,
  costUnit: 'hectare',
  operations: [
    {id: CostOperationId.est_rabbitFencing, name: 'Rabbit Fencing', cost: 0, enabled: false},
    // {id: CostOperationId.est_sorghumStrip, name: 'Sorghum Strip (deter deer)', cost: 100, enabled: false}, // TODO: Remove this?
    {id: CostOperationId.est_shooting, name: 'Shooting (control deer numbers)', cost: 0, enabled: false},
  ],
};

const establishmentRemedial: CostCollectionDefinition = {
  title: 'Remedial Actions Operations',
  active: isEstablishmentYear,
  costUnit: 'hectare',
  operations: [
    {id: CostOperationId.est_cuttingBack, name: 'Cutting Back (topper)', cost: 0, enabled: false},
    {id: CostOperationId.est_gapUpRods, name: 'Gap Up Rods', cost: 416, enabled: true},
    {id: CostOperationId.est_gappingUp, name: 'Gapping Up', cost: 132, enabled: true},
  ],
};

const growth: CostCollectionDefinition = {
  title: 'Growth Operations',
  costUnit: 'hectare',
  active: ({yearIndex, isHarvest, hasHarvested}) => yearIndex > 0 && (isHarvest || !hasHarvested),
  operations: [
    // {id: CostOperationId.growth_weedControl, name: 'Weed Control', cost: 100, enabled: true}, // TODO Remove this?
    {id: CostOperationId.growth_pruning, name: 'Pruning', cost: 0, enabled: false},
    {id: CostOperationId.growth_fertiliserSpreading, name: 'Fertiliser Spreading', cost: 100, enabled: true},
    {
      id: CostOperationId.est_weedControlQuadBike,
      name: 'Weed control - quad bike / guarded sprayer',
      cost: 100,
      enabled: true,
    },
    {
      id: CostOperationId.est_weedControlInterRowCultivator,
      name: 'Weed control - inter row cultivator',
      cost: 100,
      enabled: false,
    },
  ],
};

const harvestHarvesting: CostCollectionDefinition = {
  title: 'Harvest Operations',
  costUnit: 'hectare',
  active: isHarvestYear,
  operations: [
    {id: CostOperationId.harvest_forageHarvester, name: 'Forage Harvester', cost: 1030, enabled: true},
    {id: CostOperationId.harvest_baling, name: 'Baling', cost: 0, enabled: false},
    {id: CostOperationId.harvest_spraying, name: 'Spraying', cost: 0, enabled: false},
    {id: CostOperationId.harvest_fertiliser, name: 'Fertiliser', cost: 30, enabled: true},
  ],
};

const harvestTransport: CostCollectionDefinition = {
  title: 'Transport Operations',
  costUnit: 'tonne',
  active: isHarvestYear,
  operations: [
    {
      id: CostOperationId.harvest_transportFromFieldToStore,
      name: 'Transport from field to store',
      cost: 5,
      enabled: true,
    },
    {
      id: CostOperationId.harvest_transportFromStoreToClient,
      name: 'Transport from store to client',
      cost: 10,
      enabled: true,
    },
    {id: CostOperationId.harvest_chipping, name: 'Chipping', cost: 0, enabled: false},
    {id: CostOperationId.harvest_drying, name: 'Drying', cost: 10, enabled: true},
  ],
};

const managementEstablishment: CostCollectionDefinition = {
  title: 'Management/Consultancy Charges (Establishment)',
  costUnit: 'flat',
  active: isEstablishmentYear,
  operations: [{id: CostOperationId.mcc_consultant, name: 'RHI Consultant', cost: 100, enabled: true}],
};

const managementAnnual: CostCollectionDefinition = {
  title: 'Management/Consultancy Charges (Annual)',
  costUnit: 'flat',
  active: isNotEstablishmentYear,
  operations: [
    {id: CostOperationId.mcc_RHIConsultant, name: 'Management Consultant', cost: 300, enabled: true},
    {
      id: CostOperationId.mcc_BslSfrRegistration,
      name: 'BSL/SFR Registration',
      cost: 200,
      enabled: true,
      modifier: (operation, {hasHarvested, isHarvest}) =>
        hasHarvested || isHarvest ? operation : {...operation, enabled: false},
    },
  ],
};

const COSTS: CostCollectionDefinitionSet = {
  establishmentLand,
  establishmentWeed,
  establishmentPlanting,
  establishmentPest,
  establishmentRemedial,
  yearOneAndTwo: null,
  growth,
  harvestHarvesting,
  harvestTransport,
  managementEstablishment,
  managementAnnual,
  clearfell: null,
};

export default COSTS;
