// Moisture Content (%) -> Calorific Value (kWh/tonne) for Straw/Miscanthus
// Taken from 'PEC Info'!J4:K44
const cvStraw = {
  10: 4414,
  11: 4366,
  12: 4317,
  13: 4269,
  14: 4220,
  15: 4172,
  16: 4115,
  17: 4058,
  18: 4000,
  19: 3943,
  20: 3886,
  21: 3829,
  22: 3772,
  23: 3714,
  24: 3657,
  25: 3600,
  26: 3543,
  27: 3486,
  28: 3429,
  29: 3371,
  30: 3314,
  31: 3257,
  32: 3200,
  33: 3143,
  34: 3086,
  35: 3029,
  36: 2972,
  37: 2915,
  38: 2858,
  39: 2801,
  40: 2744,
  41: 2687,
  42: 2630,
  43: 2573,
  44: 2516,
  45: 2459,
  46: 2402,
  47: 2345,
  48: 2288,
  49: 2231,
  50: 2174,
};

export default cvStraw;
