import classNames from 'classnames';
import React, {ChangeEvent, ComponentProps, KeyboardEvent, ReactElement, useCallback, useId, useState} from 'react';

import {MinusCircleIcon, PlusCircleIcon, RevertIcon} from '../../../../resources/Icons';
import Row from '../../../common/Row';
import {CostOperation} from '../../decision/types';
import {formatMoney} from '../../decision/util';
import EditButton from '../buttons/EditButton';
import Checkbox from '../Checkbox';
import SimpleTooltip from '../SimpleTooltip';

type Props = {
  index: number;
  operation: CostOperation;
  onChange: (index: number, operation: CostOperation) => void;
};

const ActionButton = (props: ComponentProps<'button'>): ReactElement => (
  <button type={'button'} className={'text-gray-200 enabled:hover:text-primary-300'} {...props} />
);

const CostTableRow = ({index, operation, onChange}: Props): ReactElement => {
  const [editCost, setEditCost] = useState(false);
  const [currentCost, setCurrentCost] = useState<string | null>(null);

  const decreaseEnabled = operation.repetitions > 1;
  const increaseEnabled = operation.repetitions < 20;
  const resetTooltipId = useId();

  const handleToggleEnabled = useCallback(() => {
    onChange(index, {...operation, enabled: !operation.enabled});
  }, [index, onChange, operation]);

  const handleChangeCost = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setCurrentCost(event.currentTarget.value);
  }, []);

  const handleClickEdit = useCallback(() => {
    setCurrentCost(String(operation.cost ?? ''));
    setEditCost(true);
  }, [operation.cost]);

  const handleBlurCost = useCallback(() => {
    let cost = !currentCost ? null : Number(currentCost);
    if (cost !== null && isNaN(cost)) cost = null;
    onChange(index, {...operation, cost});
    setCurrentCost(null);
    setEditCost(false);
  }, [currentCost, index, onChange, operation]);

  const handleKeyDownCost = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleBlurCost();
      }
    },
    [handleBlurCost]
  );

  const handleResetCost = useCallback(() => {
    setCurrentCost(null);
    onChange(index, {...operation, cost: null});
  }, [index, onChange, operation]);

  const handleDecreaseRepetitions = useCallback(() => {
    if (!decreaseEnabled) return;
    onChange(index, {...operation, repetitions: operation.repetitions - 1});
  }, [decreaseEnabled, index, onChange, operation]);

  const handleIncreaseRepetitions = useCallback(() => {
    if (!increaseEnabled) return;
    onChange(index, {...operation, repetitions: operation.repetitions + 1});
  }, [increaseEnabled, index, onChange, operation]);

  return (
    <tr key={operation.id} className={classNames(!operation.enabled && 'text-gray-200')}>
      <td>
        <Row className={'items-center gap-xs'}>
          <Checkbox id={operation.id.toString()} checked={operation.enabled} onChange={handleToggleEnabled} />
          <label htmlFor={operation.id.toString()}>{operation.name}</label>
        </Row>
      </td>
      <td className={'bg-primary-300/[.04]'}>
        <Row className={'items-center justify-center gap-xs'}>
          <div className={'w-8'}></div>
          <Row className={'w-[6.5rem] h-[3.6rem] items-center justify-center'}>
            {editCost ? (
              <input
                autoFocus
                type={'text'}
                className={'w-full min-h-0'}
                value={currentCost ?? ''}
                pattern={'^(0|[1-9]\\d*)(\\.\\d+)?$'}
                onChange={handleChangeCost}
                onBlur={handleBlurCost}
                onKeyDown={handleKeyDownCost}
              />
            ) : (
              <span>£{formatMoney(operation.cost ?? operation.defaultCost, true)}</span>
            )}
          </Row>
          <Row className={'w-8 items-center gap-[0.8rem]'}>
            {operation.enabled && <EditButton disabled={!operation.enabled} onClick={handleClickEdit} />}
            {operation.enabled && operation.cost !== null && (
              <>
                <ActionButton onClick={handleResetCost} data-tooltip-id={resetTooltipId}>
                  <RevertIcon className={'shrink-0 w-[2.1rem] h-[2.1rem]'} />
                </ActionButton>
                <SimpleTooltip id={resetTooltipId}>
                  Revert back to estimated value: <i>£{operation.defaultCost}</i>
                </SimpleTooltip>
              </>
            )}
          </Row>
        </Row>
      </td>
      <td className={'bg-primary-300/[.08] text-center'}>
        <Row className={'items-center justify-center gap-xs'}>
          {operation.enabled && (
            <ActionButton disabled={!decreaseEnabled} onClick={handleDecreaseRepetitions}>
              <MinusCircleIcon className={'w-[2rem] h-[2rem]'} />
            </ActionButton>
          )}
          <Row className={'items-center justify-center'}>{operation.repetitions}</Row>
          {operation.enabled && (
            <ActionButton disabled={!increaseEnabled}>
              <PlusCircleIcon className={'w-[2rem] h-[2rem]'} onClick={handleIncreaseRepetitions} />
            </ActionButton>
          )}
        </Row>
      </td>
    </tr>
  );
};

export default CostTableRow;
