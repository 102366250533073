import {UserGoal} from '../../query/graphql';
import {Crop, CropId, getCrop} from '../resources/crops';
import {CropSortKey} from '../resources/cropSortKeys';
import {HECTARES_IN_50KM_CIRCLE, switchMC} from './calculations';
import Feedback from './feedback';
import {
  AnnualHarvest,
  CropMap,
  CultivateLandLandReport,
  Inputs,
  LandReport,
  RunPowerStationSupplyLandReport,
  SupplyPropertyLandReport,
  WithoutNullables,
} from './types';
import {buildCropMap, sum} from './util';
import {
  mergeValidators,
  noopValidator,
  validateCropPositiveNumbers,
  validateFieldSizes,
  validateFuelRequirement,
  validateHeatConsumption,
  validatePlantationLifetimePerCrop,
  validatePositiveNumber,
  validateRequired,
  ValidationError,
} from './validation';

export type LandPlanningInput = WithoutNullables<Pick<Inputs, 'goal' | 'plantingYear'>> &
  Pick<Inputs, 'heatConsumption' | 'fuelRequirement' | 'sortBy'> & {
    stagger: CropMap<number>;
    fieldSizes: CropMap<number[]>;
    localYield: CropMap<number>;
    cv: CropMap<number>;
    plantationLifetime: CropMap<number>;
  };

export const validators = {
  goal: validateRequired,
  heatConsumption: validateHeatConsumption,
  fuelRequirement: validateFuelRequirement,
  localYield: validateRequired,
  fieldSizes: validateFieldSizes,
  plantingYear: mergeValidators(validateRequired, validatePositiveNumber),
  plantationLifetime: mergeValidators(validateRequired, validatePlantationLifetimePerCrop),
  cv: validateCropPositiveNumbers,
  sortBy: noopValidator,
  stagger: validateCropPositiveNumbers,
};

function getHarvests(fieldSizes: number[], greenYield: number[], harvestMC: number, inUseMC: number) {
  const actualHarvest = fieldSizes.map((size, idx) => size * greenYield[idx]);
  const dryMatterHarvest = actualHarvest.map(harvest => switchMC(harvest, harvestMC, 0));
  const inUseHarvest = actualHarvest.map(harvest => switchMC(harvest, harvestMC, inUseMC));
  return {actualHarvest, dryMatterHarvest, inUseHarvest};
}

const annualProspection = (
  harvestMC: number,
  inUseMC: number,
  plantingYear: number,
  plantationLifetime: number,
  fieldSizes: number[],
  matureYield: number,
  getYearYield: Crop['getYearYield'],
  secondaryCrop: NonNullable<Crop['combinedData']>['secondaryCrop'] | undefined
): AnnualHarvest[] => {
  const annualData: AnnualHarvest[] = [];
  for (let year = 0; year < plantationLifetime; year++) {
    const season = plantingYear + year;
    const greenMatureYield = switchMC(matureYield, 0, harvestMC);
    const greenYield = fieldSizes.map((_, idx) => getYearYield(greenMatureYield, year - idx));
    let {actualHarvest, dryMatterHarvest, inUseHarvest} = getHarvests(fieldSizes, greenYield, harvestMC, inUseMC);

    let portions: AnnualHarvest['portions'];
    if (secondaryCrop) {
      const secondaryGreenMatureYield = switchMC(secondaryCrop.defaultYield, 0, secondaryCrop.harvestedMC);
      const secondaryGreenYield = fieldSizes.map((_, idx) =>
        secondaryCrop.getYearYield(secondaryGreenMatureYield, year - idx)
      );
      const {
        actualHarvest: secondaryActualHarvest,
        dryMatterHarvest: secondaryDryMatterHarvest,
        inUseHarvest: secondaryInUseHarvest,
      } = getHarvests(fieldSizes, secondaryGreenYield, secondaryCrop.harvestedMC, secondaryCrop.inUseMC);

      portions = {
        primary: {
          dryMatterHarvest,
          actualHarvest,
          inUseHarvest,
        },
        secondary: {
          dryMatterHarvest: secondaryDryMatterHarvest,
          actualHarvest: secondaryActualHarvest,
          inUseHarvest: secondaryInUseHarvest,
        },
      };

      // Make harvests be the combination of primary and secondary harvest
      actualHarvest = actualHarvest.map((e, idx) => e + secondaryActualHarvest[idx]);
      dryMatterHarvest = dryMatterHarvest.map((e, idx) => e + secondaryDryMatterHarvest[idx]);
      inUseHarvest = inUseHarvest.map((e, idx) => e + secondaryInUseHarvest[idx]);
    }

    annualData.push({
      season,
      greenYield,
      dryMatterHarvest,
      actualHarvest,
      inUseHarvest,
      portions,
    });
  }

  return annualData;
};

const supplyPropertyReport = (
  cropId: CropId,
  matureYield: number,
  heatConsumption: number,
  plantingYear: LandPlanningInput['plantingYear'],
  plantationLifetime: number,
  cv: number,
  stagger: boolean
): SupplyPropertyLandReport['results'][CropId] => {
  const {harvestedMC, inUseMC, bulkDensity, yearsToSelfSufficiency, getYearYield, combinedData} = getCrop(cropId);
  const annualRequirement = heatConsumption / cv;
  const driedYield = switchMC(matureYield, 0, inUseMC);
  const areaRequired = annualRequirement / driedYield;
  const storageSpace = annualRequirement / bulkDensity;
  const fieldSizes = stagger ? Array(3).fill(areaRequired / 3) : [areaRequired];

  const annualHarvest = annualProspection(
    harvestedMC,
    inUseMC,
    plantingYear,
    plantationLifetime,
    fieldSizes,
    matureYield,
    getYearYield,
    combinedData?.secondaryCrop
  );

  return {
    matureYield,
    harvestedMC,
    inUseMC,
    cv,
    areaRequired,
    annualHarvest,
    annualRequirement,
    selfSufficiencyYears: yearsToSelfSufficiency,
    bulkDensity,
    storageSpace,
    fieldSizes,
  };
};

const runPowerStationReport = (
  cropId: CropId,
  matureYield: number,
  fuelRequirement: number,
  plantingYear: LandPlanningInput['plantingYear'],
  plantationLifetime: number,
  cv: number,
  stagger: boolean
): RunPowerStationSupplyLandReport['results'][CropId] => {
  const {harvestedMC, inUseMC, getYearYield, combinedData} = getCrop(cropId);
  const driedYield = switchMC(matureYield, 0, inUseMC);
  const areaRequired = fuelRequirement / driedYield;
  const landTake = (areaRequired / HECTARES_IN_50KM_CIRCLE) * 100;
  const fieldSizes = stagger ? Array(3).fill(areaRequired / 3) : [areaRequired];

  const annualHarvest = annualProspection(
    harvestedMC,
    inUseMC,
    plantingYear,
    plantationLifetime,
    fieldSizes,
    matureYield,
    getYearYield,
    combinedData?.secondaryCrop
  );

  return {
    matureYield,
    harvestedMC,
    inUseMC,
    cv,
    areaRequired,
    annualHarvest,
    landTake,
    fieldSizes,
  };
};

const cultivateLandReport = (
  cropId: CropId,
  matureYield: number,
  fieldSizes: number[],
  plantingYear: NonNullable<Inputs['plantingYear']>,
  plantationLifetime: number,
  cv: number
): CultivateLandLandReport['results'][CropId] => {
  const {harvestedMC, inUseMC, bulkDensity, getYearYield, combinedData} = getCrop(cropId);
  const areaRequired = sum(fieldSizes);
  const harvestedYield = switchMC(matureYield, 0, harvestedMC);
  const matureAnnualProduction = harvestedYield * areaRequired;
  const storageSpace = matureAnnualProduction / bulkDensity;

  const annualHarvest = annualProspection(
    harvestedMC,
    inUseMC,
    plantingYear,
    plantationLifetime,
    fieldSizes,
    matureYield,
    getYearYield,
    combinedData?.secondaryCrop
  );

  return {
    fieldSizes,
    matureYield,
    harvestedMC,
    inUseMC,
    cv,
    areaRequired,
    annualHarvest,
    matureAnnualProduction,
    storageSpace,
  };
};

/**
 * @param input Make sure you validate this arg using `validate(input, validators)`
 * @param feedback
 */
export const runReport = (input: LandPlanningInput, feedback?: Feedback): LandReport => {
  const {goal, stagger, fieldSizes, plantingYear, plantationLifetime, localYield, cv, sortBy} = input;

  const sortKey = sortBy
    ? Object.values(<any>CropSortKey).includes(sortBy)
      ? (sortBy as CropSortKey)
      : CropSortKey.productionCost
    : CropSortKey.productionCost;

  switch (goal) {
    case UserGoal.SupplyProperty:
      const heatConsumption = input.heatConsumption;
      if (heatConsumption === null) {
        const error = new ValidationError('Invalid input');
        error.addError(`heatConsumption is required when goal is ${goal}`);
        throw error;
      }

      return {
        goal,
        sortBy: sortKey,
        heatConsumption,
        results: buildCropMap(cropId =>
          supplyPropertyReport(
            cropId,
            localYield[cropId],
            heatConsumption,
            plantingYear,
            plantationLifetime[cropId],
            cv[cropId],
            stagger[cropId] === 3
          )
        ),
      };

    case UserGoal.RunPowerStation:
      const fuelRequirement = input.fuelRequirement;
      if (fuelRequirement === null) {
        const error = new ValidationError('Invalid input');
        error.addError(`fuelRequirement is required when goal is ${goal}`);
        throw error;
      }

      return {
        goal,
        sortBy: sortKey,
        fuelRequirement,
        results: buildCropMap(cropId =>
          runPowerStationReport(
            cropId,
            localYield[cropId],
            fuelRequirement,
            plantingYear,
            plantationLifetime[cropId],
            cv[cropId],
            stagger[cropId] === 3
          )
        ),
      };

    case UserGoal.CultivateLand:
      if (!fieldSizes) throw new Error(`fieldSizes is required when goal is ${goal}`);

      return {
        goal,
        sortBy: sortKey,
        plantingYear,
        results: buildCropMap(cropId =>
          cultivateLandReport(
            cropId,
            localYield[cropId],
            fieldSizes[cropId],
            plantingYear,
            plantationLifetime[cropId],
            cv[cropId]
          )
        ),
      };
  }
};
