import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {GameLogicError} from './errors';
import {
  computeCost,
  computeHarvestEarnings,
  computeOption,
  computeTonnesHarvested,
  isHarvestYear,
  isNHarvest,
} from './mechanics';
import {CropId, getCrop} from './resources/crops';
import {getAllEvents, getEvent} from './resources/events';
import {getQuestion, OptionId, QuestionId, QUESTIONS} from './resources/questions';
import {requireToolById} from './resources/tools';
import {WalkthroughId} from './resources/walkthrough';
import {Event, GamePhase, LandUse, Place, Question, Status, Tool} from './types';

// Define the initial state using that type
const initialState: Status = {
  phase: GamePhase.welcome,
  initialMoney: 25000,
  money: 25000,
  totalProduction: 0,
  currentYear: 0,
  totalYears: 10,
  plantingYear: 0,
  harvestYears: [],
  isHarvesting: false,
  baseYield: undefined,
  currentYield: undefined,
  prices: {
    pricePerTonne: undefined,
    carbonCreditFlat: 0,
    carbonCreditPerTonne: 0,
    dryCostPerTonne: 0,
    ownTransport: true,
    transportCostPerTonne: 8,
    harvestCostPerTonne: 0,
  },
  place: undefined,
  landUse: undefined,
  crop: undefined,
  landSize: 10,
  enabledTools: [],
  tools: [],
  fuelQuality: 1,
  carbonEmissions: 0,
  expertAdvices: [],
  productionGoal: 800,
  walkthroughProgress: {
    current: null,
    last: null,
  },
  history: [],
  endOfGameTracked: false,
  scoreAdded: false,
};

const checkHasMoney = (_status: Status, _amount: number) => {
  // if (!status.settings.allowNegativeBalance && amount > status.money) {
  //   throw new Error("You don't have enough money for this");
  // }
  return true;
};

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    restart: () => initialState,
    startPlaying: state => {
      state.phase = GamePhase.preparation;
    },
    setStatus: (state, action: PayloadAction<Status>) => action.payload,
    setPlace: (state, action: PayloadAction<Place>) => {
      state.place = action.payload;
    },
    prepareGame: (state, action: PayloadAction<LandUse>) => {
      state.landUse = action.payload;
      state.money = state.initialMoney;
      // Initialise first year
      state.history[state.currentYear] = {choices: [], events: []};
      state.phase = GamePhase.questions;
      gameSlice.caseReducers.loadNextQuestion(state);
    },
    loadNextQuestion: state => {
      const currentYearChoices = state.history[state.currentYear].choices;
      const currentYearQuestions = currentYearChoices.map(choice => choice.question);

      // Find the first question that can be asked and hasn't been asked this year
      const next = Object.values(QUESTIONS)
        .filter(q => !currentYearQuestions.includes(q.id))
        .find(q => q.shouldAsk(state));
      if (next) {
        state.history[state.currentYear].choices.push({question: next.id});

        // Trigger walkthrough on first question displayed
        if (state.walkthroughProgress.last === null) {
          state.walkthroughProgress.current = WalkthroughId.timeline;
        } else if (
          !!next.help &&
          state.walkthroughProgress.current === null &&
          state.walkthroughProgress.last === WalkthroughId.feedback
        ) {
          state.walkthroughProgress.current = WalkthroughId.get_help;
        }
      } else {
        gameSlice.caseReducers.runJeopardies(state);
      }
    },
    goToNextYear: state => {
      if (state.currentYear === state.totalYears - 1) state.phase = GamePhase.finished;
      else {
        const didHarvest = isHarvestYear(state);
        const wasFirstHarvest = isNHarvest(state, 1);

        if (didHarvest) {
          // Reset harvesting animation
          state.isHarvesting = false;
          // Add harvest year
          state.harvestYears.push(state.currentYear);
          // Clear non permanent tools (e.g. herbicides)
          state.tools = state.tools.filter(t => t.permanent);
          // Reset yield
          state.currentYield = GameLogicError.assertNonNullish(state.baseYield);
          // Update transportation cost: 8 for 1st harvest, 10 for 2nd harvest, 15 for 3rd and more
          state.prices.transportCostPerTonne = wasFirstHarvest ? 12 : 15;
        }

        state.currentYear += 1;
        // Initialise history entry for current year
        state.history[state.currentYear] = {choices: [], events: []};
        state.phase = GamePhase.questions;
        gameSlice.caseReducers.loadNextQuestion(state);
        state.carbonEmissions = 0;
      }
    },
    enableTools: (state, action: PayloadAction<string[]>) => {
      // Filter to avoid pushing the same id multiple times
      for (const tool of action.payload.filter(t => !state.enabledTools.includes(t))) {
        state.enabledTools.push(tool);
      }
    },
    buyTool: (state, action: PayloadAction<Tool>) => {
      const tool = action.payload;
      const cost = tool.price ?? computeCost(state, tool.pricePerHa) ?? 0;
      // Is the tool enabled?
      if (!state.enabledTools.includes(tool.id)) throw new Error('This tool has not been enabled yet');
      // Do I already have this tool?
      if (state.tools.find(t => t.id === tool.id)) throw new Error('You already have this tool');
      // Do I have enough money?
      checkHasMoney(state, cost);
      // Delete other group items if the group is defined
      // e.g. It is not possible to have 2 kind of fences
      if (tool.group) state.tools = state.tools.filter(t => t.group !== tool.group);
      // Everything looks fine, add the tool to the state and pay for it
      gameSlice.caseReducers.spendMoney(state, Actions.spendMoney(cost));
      state.tools.push(tool);
    },
    spendMoney: (state, action: PayloadAction<number>) => {
      checkHasMoney(state, action.payload);
      state.money -= action.payload;
    },
    buyExpertAdvice: (state, action: PayloadAction<Question>) => {
      // Omit if already bought
      if (state.expertAdvices.includes(action.payload.id)) return;
      // Spend the money and store the result
      const help = GameLogicError.assertNonNullish(action.payload.help);
      const expertAdvice = GameLogicError.assertNonNullish(help.expert);
      gameSlice.caseReducers.spendMoney(state, Actions.spendMoney(expertAdvice.cost));
      state.expertAdvices.push(action.payload.id);
    },
    executeOption(state, action: PayloadAction<{questionId: QuestionId; optionId: OptionId}>) {
      const {questionId, optionId} = action.payload;
      const question = getQuestion(questionId);
      const option = computeOption(state, questionId, optionId);
      try {
        if (!option) throw new GameLogicError('Failed to computeOption');

        const yieldBefore = state.currentYield ?? 0; // Keep record of yield before running effects

        // 1. Enable tools if defined
        if (question.enableTools && question.enableTools.length > 0) {
          gameSlice.caseReducers.enableTools(state, Actions.enableTools(question.enableTools));
        }

        // 2. Execute the option effects
        const {buyToolId, cost, costPerHa, carbonEmissions} = option;
        const computedCost = cost ?? computeCost(state, costPerHa);
        // General case for options that result in buying tools
        if (buyToolId) gameSlice.caseReducers.buyTool(state, Actions.buyTool(requireToolById(buyToolId)));
        // General case for options that result in just spending money
        if (!buyToolId && computedCost) gameSlice.caseReducers.spendMoney(state, Actions.spendMoney(computedCost));
        // General case for options that involve carbon emissions
        if (carbonEmissions) state.carbonEmissions += carbonEmissions;
        // Run custom option effect
        if (option.onChosen) option.onChosen(state);

        // 3. Execute the question effects
        if (question.onAnswered) question.onAnswered(state, option);

        // 4. Store option selection for this question
        const {choices} = state.history[state.currentYear];
        const choice = choices[choices.length - 1];
        if (choice?.question !== question.id) throw new GameLogicError('QuestionId does not match');
        choice.option = {
          id: option.id,
          yieldBefore,
          yieldAfter: state.currentYield ?? 0,
          penaltyPoints: option.penaltyPoints ?? 0,
        };

        gameSlice.caseReducers.loadNextQuestion(state);
      } catch (error) {
        console.error(error);
        alert(error);
      }
    },
    chooseCrop: (state, action: PayloadAction<CropId>) => {
      const crop = getCrop(action.payload);
      if (!crop) throw new Error(`CropId ${action.payload} not found`);
      state.crop = crop;
      state.prices.pricePerTonne = crop.pricePerTonne;
      // Once the crop type and place are selected, we can assign the avg yield
      state.currentYield = state.baseYield = state.place?.avgYield[crop.id];
    },
    runJeopardies: state => {
      state.phase = GamePhase.jeopardies;
      state.history[state.currentYear].events = [];
    },
    triggerEventEffect: (state, action: PayloadAction<{year: number; index: number}>) => {
      const {year, index} = action.payload;
      const eventLog = state.history[year].events[index];
      const event = GameLogicError.assertNonNullish(getEvent(eventLog.id));
      const yieldBefore = state.currentYield ?? 0;
      const moneyBefore = state.money;
      event.runEffects(state);
      const yieldAfter = state.currentYield ?? 0;
      const moneyAfter = state.money;
      state.history[year].events[index].effect = {
        yieldBefore,
        yieldAfter,
        moneyBefore,
        moneyAfter,
      };
    },
    executeEvent: (state, action: PayloadAction<Event['id']>) => {
      // Add event to history (without effect)
      const nextIndex = state.history[state.currentYear].events.length;
      state.history[state.currentYear].events.push({
        id: action.payload,
      });
      // Trigger effect of the event we just added
      gameSlice.caseReducers.triggerEventEffect(
        state,
        Actions.triggerEventEffect({year: state.currentYear, index: nextIndex})
      );
    },
    startEventsPhase: state => {
      // Skip if already at events phase
      if (state.phase === GamePhase.events) return state;

      // Add all events that occurred this year to history, without effect (as they are not triggered yet)
      state.history[state.currentYear].events.push(
        ...getAllEvents()
          .filter(e => e.hasHappened(state))
          .map(e => ({id: e.id}))
      );

      state.phase = GamePhase.events;
    },
    wrapUpYear: state => {
      state.phase = GamePhase.wrapup;
    },
    setIsHarvesting: (state, action: PayloadAction<boolean>) => {
      state.isHarvesting = action.payload;
    },
    harvest: state => {
      state.phase = GamePhase.harvest;
      if (isHarvestYear(state)) {
        const tonnesHarvested = computeTonnesHarvested(state);
        const harvestMoney = computeHarvestEarnings(state);
        state.money += harvestMoney;
        state.totalProduction += tonnesHarvested;
      }
    },
    nextWalkthrouh: state => {
      const newLast = state.walkthroughProgress.current;
      switch (state.walkthroughProgress.current) {
        case WalkthroughId.timeline:
        case WalkthroughId.total_balance:
        case WalkthroughId.production:
          state.walkthroughProgress.current = state.walkthroughProgress.current + 1;
          break;
        case WalkthroughId.feedback:
        case WalkthroughId.get_help:
          state.walkthroughProgress.current = null;
          break;
        default:
          break;
      }
      state.walkthroughProgress.last = newLast;
    },
    previousWalkthrouh: state => {
      if (!state.walkthroughProgress.current) return;
      state.walkthroughProgress.last = state.walkthroughProgress.last ? state.walkthroughProgress.last - 1 : null;
      state.walkthroughProgress.current--;
    },
    skipWalkthrouh: state => {
      switch (state.walkthroughProgress.current) {
        case WalkthroughId.timeline:
        case WalkthroughId.total_balance:
        case WalkthroughId.production:
        case WalkthroughId.feedback:
          state.walkthroughProgress.last = WalkthroughId.feedback;
          break;
        case WalkthroughId.get_help:
          state.walkthroughProgress.last = WalkthroughId.get_help;
          break;
        default:
          break;
      }
      state.walkthroughProgress.current = null;
    },
    setEndOfGameTracked: (state, action: PayloadAction<boolean>) => {
      state.endOfGameTracked = action.payload;
    },
    setScoreAdded: (state, action: PayloadAction<boolean>) => {
      state.scoreAdded = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const Actions = gameSlice.actions;

export default gameSlice.reducer;
