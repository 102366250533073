import {ComponentProps, FC} from 'react';

const RightArrow: FC<ComponentProps<'svg'>> = props => (
  <svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="1.5"
      transform="translate(1 1)"
    >
      <path
        d="m12.0002613 11.8277963h-9.65559263v-9.65559261"
        strokeLinejoin="round"
        transform="matrix(-.70710678 .70710678 .70710678 .70710678 7.294416 -3.021446)"
      />
      <path d="m.051781 7h13.852633" />
    </g>
  </svg>
);

export default RightArrow;
