import classNames from 'classnames';
import React from 'react';

import {Option, SimpleValue} from '../../decision/types';
import Dropdown from '../Dropdown';
import DropdownOptions from '../DropdownOptions';
import AdditionalInfo from './AdditionalInfo';

type PropsType<T extends SimpleValue> = {
  options: Option<T>[];
  value: T | undefined;
  onChange: (value: T) => void | Promise<void>;
  error?: string;
};

const QuestionDropdown = <T extends SimpleValue>(props: PropsType<T>) => {
  const {options, value, onChange, error} = props;
  const currentLabel = value ? options.find(item => item.value == value)?.label : undefined;

  return (
    <>
      <Dropdown
        buttonContent={
          <span className={classNames('truncate', !currentLabel && 'text-gray-200')}>
            {currentLabel ?? 'Select an answer'}
          </span>
        }
        menuClassName={'max-h-[250px]'}
      >
        <DropdownOptions options={options} selectedValue={value} onOptionSelected={value => onChange(value)} />
      </Dropdown>
      <AdditionalInfo error={error} />
    </>
  );
};

export default QuestionDropdown;
