import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {useAppSelector} from '../store';

type Size = {width: number; height: number};

interface GameDebug {
  windowSize?: Size;
  boardSize?: Size;
  resizeText: boolean;
  fontZoom: number;
  minBoardWidth: number;
}

// Define the initial state using that type
const initialState: GameDebug = {
  resizeText: true,
  minBoardWidth: 0,
  fontZoom: 0,
};

export const debugSlice = createSlice({
  name: 'debug',
  initialState,
  reducers: {
    setWindowSize: (state, action: PayloadAction<Size>) => {
      state.windowSize = action.payload;
    },
    setBoardSize: (state, action: PayloadAction<Size>) => {
      state.boardSize = action.payload;
    },
    setResizeText: (state, action: PayloadAction<boolean>) => {
      state.resizeText = action.payload;
    },
    setMinBoardWidth: (state, action: PayloadAction<number>) => {
      state.minBoardWidth = action.payload;
    },
    setFontZoom: (state, action: PayloadAction<number>) => {
      state.fontZoom = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const Actions = debugSlice.actions;

export const useGameDebug = () => useAppSelector(state => state.gameDebug);

export default debugSlice.reducer;
