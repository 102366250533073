import React, {ReactElement} from 'react';

import FireAnimation from '../../../../resources/animations/Fire.json';
import Row from '../../../common/Row';
import {useBreakpoint} from '../../../common/util/useBreakpoint';
import GameAudio from '../GameAudio';
import LottieAnimation from '../LottieAnimation';

const FIRE_COUNT_DESKTOP = 5;
const FIRE_COUNT_MOBILE = 3;

const Fire = (): ReactElement => {
  const {isMobile} = useBreakpoint('mobile');
  const fireCount = isMobile ? FIRE_COUNT_MOBILE : FIRE_COUNT_DESKTOP;

  return (
    <>
      <Row className="absolute bottom-[4rem] left-0 right-0 h-[23rem] justify-center gap-[4rem]">
        {Array(fireCount)
          .fill(null)
          .map((_, idx) => (
            <LottieAnimation key={idx} animation={FireAnimation} loop={true} contain />
          ))}
      </Row>
      <GameAudio src="sounds/Fire.mp3" play={true} />
    </>
  );
};

export default Fire;
