import React, {ReactElement} from 'react';

import {PlantingMachine} from '../../../../resources/Icons';

const StuckPlanter = (): ReactElement => {
  return (
    <div className="absolute bottom-[3rem] -left-[28rem]">
      <PlantingMachine className="w-[90rem]" />
    </div>
  );
};

export default StuckPlanter;
