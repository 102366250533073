import {Base64File} from '../../query/graphql';

// List of Unique file type specifiers accepted when selecting an image to upload. Both extensions and MIME types
//  are accepted (see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file)
export const ACCEPTED_TYPES = [
  'image/jpeg',
  '.jpg',
  '.jpeg',
  '.jfif',
  '.pjpeg',
  '.pjp', // Joint Photographic Expert Group image
  'image/png',
  '.png', //	Portable Network Graphics
  'image/gif',
  '.gif', // Graphics Interchange Format (GIF)
  'image/svg+xml',
  '.svg', // Scalable Vector Graphics (SVG)
  'image/apng',
  '.apng', // APNG Animated Portable Network Graphics
  'image/webp',
  '.webp', // WebP Web Picture format
];

export const MAX_UPLOAD_SIZE_BYTES = 4_000_000;

export function fileToDataURL(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
}

export async function fileToBase64File(file: File): Promise<Base64File> {
  return {name: file.name, data: dataURLtoBase64(await fileToDataURL(file))};
}

export function dataURLtoBase64(dataUrl: string): string {
  return dataUrl.split(',')[1];
}
