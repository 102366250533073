/**
 * Make Tailwind screen breakpoints available at runtime. Inspired on:
 * https://stackoverflow.com/a/71098593/1077412
 */

import {useMediaQuery} from 'react-responsive';

/**
 * Note: We don't use Tailwind's `resolveConfig` here to avoid pulling a lot of dependencies
 * (see https://tailwindcss.com/docs/configuration#referencing-in-java-script)
 * Instead, we import `tailwind.config.js` directly, which works in this case because screen breakpoints are
 * explicitly set in our config file, and not using `extend`.
 */
import config from '../../../../tailwind.config';

const breakpoints = config.theme.screens;

type BreakpointKey = keyof typeof breakpoints;
type Screen = string | {min?: string; max?: string}; // 'raw' key not supported

export function useBreakpoint<K extends BreakpointKey>(breakpointKey: K) {
  const breakpoint = breakpoints[breakpointKey] as Screen;

  let min, max;

  if (typeof breakpoint === 'string') {
    min = breakpoint;
  } else {
    min = breakpoint.min;
    max = breakpoint.max;
  }

  const minWidth = min ? `min-width: ${min}` : '';
  const maxWidth = max ? `max-width: ${max}` : '';
  const query = `(${[minWidth, maxWidth].filter(Boolean).join(',')})`;

  const bool = useMediaQuery({query});
  const capitalizedKey = breakpointKey[0].toUpperCase() + breakpointKey.substring(1);
  type Key = `is${Capitalize<K>}`;
  return {
    [`is${capitalizedKey}`]: bool,
  } as Record<Key, boolean>;
}
