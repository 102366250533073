import {emptyInputs} from '../../query/useScenario';
import {useAppSelector} from '../../store';
import {StartFlow} from './types';
import {validate, validateRequired} from './validation';

export const validators = {
  farmerType: validateRequired,
  farmerProfile: validateRequired,
};

export const validateStartFlow = (inputs: StartFlow) => {
  try {
    validate<StartFlow>(inputs, emptyInputs, validators);
    return {valid: true, error: null};
  } catch (e: any) {
    let error: string = e?.message ?? 'Unknown error';
    e?.validation?.forEach((e: string) => (error += `\n- ${e}`));
    return {valid: false, error};
  }
};

export const useStartFlow = () => useAppSelector(state => state.startFlow);
