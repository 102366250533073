import {Flow} from './features/query/graphql';
import {CropFamilyId} from './features/tool/resources/cropFamilies';
import {CropId} from './features/tool/resources/crops';

const URLS = {
  home: '/',
  crops: {
    index: '/crops',
    crop: (cropFamilyId: CropFamilyId) => `/crops/${cropFamilyId}`,
  },
  about: {
    index: '/about',
    becomeSupplier: '/about/become-supplier',
  },
  game: '/game',
  play: '/play',
  start: '/start',
  startFlow: '/start/flow',
  contact: '/contact',
  dashboard: '/app/dashboard', // Redirects to /app/0/dashboard
  app: (scenarioIndex: number) => ({
    dashboard: `/app/${scenarioIndex}/dashboard`,
    cost: `/app/${scenarioIndex}/cost`,
    costIntro: `/app/${scenarioIndex}/cost/intro`,
    costFlow: `/app/${scenarioIndex}/cost/flow`,
    costEnd: `/app/${scenarioIndex}/cost/end`,
    yield: `/app/${scenarioIndex}/yield`,
    yieldIntro: `/app/${scenarioIndex}/yield/intro`,
    yieldFlow: `/app/${scenarioIndex}/yield/flow`,
    yieldEnd: `/app/${scenarioIndex}/yield/end`,
    track: `/app/${scenarioIndex}/track`,
    trackIntro: `/app/${scenarioIndex}/track/intro`,
    trackFlow: `/app/${scenarioIndex}/track/flow`,
    trackEnd: `/app/${scenarioIndex}/track/end`,
    emissions: `/app/${scenarioIndex}/emissions`,
    report: `/app/${scenarioIndex}/report`,
    printReport: `/app/${scenarioIndex}/report/print`,
    settings: {
      profile: `/app/${scenarioIndex}/settings/profile`,
      scenarios: (editId?: string) => `/app/${scenarioIndex}/settings/scenarios${editId ? `?editId=${editId}` : ''}`,
    },
  }),
  commonApp: {
    supplier: {
      index: `/app/supplier`,
      about: '/app/supplier/about',
      products: '/app/supplier/products',
    },
  },
  signin: '/signin',
  signinAndGo: (go?: string) => `/signin` + (go ? `?go=${go}` : ''),
  signup: '/signup',
  verifyEmail: '/verify-email',
  forgotPassword: '/forgot-password',
  siteMap: '/sitemap',
  draft: '/resources/draft',
  // TODO: Remove draft. Add resources. Unexport getters below
  privacyPolicy: '/privacy-policy',
  marketPlace: {
    suppliers: '/marketplace/suppliers',
    supplier: (slug: string, preview = false) => {
      const pv = preview ? '/preview' : '';
      return {
        about: `/marketplace/suppliers/${slug}${pv}`,
        products: (page?: number) =>
          page === undefined
            ? `/marketplace/suppliers/${slug}${pv}/products`
            : `/marketplace/suppliers/${slug}${pv}/products/${page}`,
        reviews: `/marketplace/suppliers/${slug}${pv}/reviews`,
      };
    },
    products: '/marketplace/products',
  },
};

export const isPublicPath = (path: string) => !path.startsWith('/app/');

export const getResourceURL = (slug: string) => `/resource/${slug}`;
export const getDraftResourceURL = (slug: string) => `/resources/draft/${slug}`;
export const getResourcesURL = (page: number = 1, search?: string, filters?: string[]) => {
  const filterQuery = !filters?.length ? '' : `?filters=${filters.join(',')}`;
  const searchQuery = !search ? '' : `${filterQuery ? '&' : '?'}search=${search}`;
  return `/resources/${page}${filterQuery}${searchQuery}`;
};
export const getCropReportURL = (scenarioIndex: number, cropType: CropId | null, flow?: Flow) =>
  `${URLS.app(scenarioIndex).report}/${cropType ?? ''}${flow ? `#${flow}` : ''}`;

export default URLS;
