import React, {ReactElement} from 'react';

import RabbitAnimation from '../../../../resources/animations/Rabbit.json';
import LottieAnimation from '../LottieAnimation';

const Rabbit = (): ReactElement => {
  return (
    <div className="absolute bottom-0 left-0">
      <LottieAnimation className="w-[30rem] animate-rabbit" animation={RabbitAnimation} loop={true} />
    </div>
  );
};

export default Rabbit;
