import {CostCollectionDefinition, CostCollectionDefinitionSet} from '../../decision/types';
import {alwaysActive, isEstablishmentYear, isHarvestYear} from '../../decision/util';
import {CostOperationId} from './operationNames';

const CLEARFELL_YEAR_INDEX = 15;

const establishmentLand: CostCollectionDefinition = {
  title: 'Land Preparation Operations',
  costUnit: 'hectare',
  active: isEstablishmentYear,
  operations: [
    {id: CostOperationId.est_ploughing, name: 'Ploughing', cost: 0, enabled: false},
    {id: CostOperationId.est_powerHarrowing, name: 'Power Harrowing', cost: 0, enabled: false},
    {id: CostOperationId.est_rolling, name: 'Rolling', cost: 0, enabled: false},
    {id: CostOperationId.est_subSoiling, name: 'Sub-Soiling', cost: 180, enabled: true},
    {id: CostOperationId.est_discing, name: 'Discing', cost: 0, enabled: false},
    {id: CostOperationId.est_fertiliserSpreading, name: 'Fertiliser Spreading', cost: 0, enabled: false},
    {id: CostOperationId.est_prePlantSpraying, name: 'Pre-Plant Spraying', cost: 170, enabled: true},
  ],
};

const establishmentWeed: CostCollectionDefinition = {
  title: 'Weed Control Operations',
  costUnit: 'hectare',
  active: isEstablishmentYear,
  operations: [
    {id: CostOperationId.est_spraying, name: 'Spraying (conventional tractor & boom)', cost: 136, enabled: true},
    {id: CostOperationId.est_prePlantingGlyphosate, name: 'Pre-Planting - Glyphosate', cost: 0, enabled: false},
    {
      id: CostOperationId.est_postPlantingStompPendimethalin,
      name: 'Post planting - Stomp (Pendimethalin)',
      cost: 0,
      enabled: false,
    },
    {
      id: CostOperationId.est_postPlantingFlexidorIsoxaben,
      name: 'Post Planting - Flexidor (Isoxaben)',
      cost: 0,
      enabled: false,
    },
    {
      id: CostOperationId.est_postCutBackStompPendimethalin,
      name: 'Post Cut Back - Stomp (Pendimethalin)',
      cost: 0,
      enabled: false,
    },
  ],
};

const establishmentPlanting: CostCollectionDefinition = {
  title: 'Planting & Plant Material Operations',
  costUnit: 'hectare',
  active: isEstablishmentYear,
  operations: [
    {id: CostOperationId.est_contractPlanting, name: 'Contract Planting', cost: 1440, enabled: true},
    {id: CostOperationId.est_plantMaterial, name: 'Plant Material', cost: 1440, enabled: true},
  ],
};

const establishmentPest: CostCollectionDefinition = {
  title: 'Pest Control Operations',
  costUnit: 'hectare',
  active: isEstablishmentYear,
  operations: [
    {id: CostOperationId.est_rabbitFencing, name: 'Rabbit & Deer Fencing', cost: 0, enabled: false},
    {id: CostOperationId.est_treeGuards, name: 'Tree Guards & Stakes (1600 units)', cost: 1440, enabled: true},
    {id: CostOperationId.est_sorghumStrip, name: 'Sorghum Strip (deter deer)', cost: 0, enabled: false},
    {id: CostOperationId.est_shooting, name: 'Shooting (control deer numbers)', cost: 0, enabled: false},
  ],
};

const establishmentRemedial: CostCollectionDefinition = {
  title: 'Remedial Actions Operations',
  costUnit: 'hectare',
  active: isEstablishmentYear,
  operations: [
    {id: CostOperationId.est_cuttingBack, name: 'Cutting Back (topper)', cost: 0, enabled: false},
    {id: CostOperationId.est_gapUpRods, name: 'Gap Up Rods', cost: 0, enabled: false},
    {id: CostOperationId.est_gappingUp, name: 'Gapping Up', cost: 0, enabled: false},
    {
      id: CostOperationId.est_weedControlQuadBike,
      name: 'Weed control - quad bike / guarded sprayer',
      cost: 0,
      enabled: false,
    },
    {
      id: CostOperationId.est_weedControlInterRowCultivator,
      name: 'Weed control - inter row cultivator',
      cost: 0,
      enabled: false,
    },
  ],
};

const yearOneAndTwo: CostCollectionDefinition = {
  title: 'Year 1 & 2 operations',
  costUnit: 'hectare',
  active: ({yearIndex}) => yearIndex === 1 || yearIndex === 2,
  operations: [
    {id: CostOperationId.yr1and2_weedControl, name: 'Weed control', cost: 170, enabled: true, repetitions: 3},
    {id: CostOperationId.est_gappingUp, name: 'Gapping Up', cost: 250, enabled: true},
  ],
};

const growth: CostCollectionDefinition = {
  title: 'Growth Operations',
  costUnit: 'hectare',
  active: ({yearIndex, isHarvest, hasHarvested}) => yearIndex > 2 && !isHarvest,
  operations: [
    {id: CostOperationId.growth_weedControl, name: 'Weed Control (all years)', cost: 0, enabled: false},
    {id: CostOperationId.growth_pruning, name: 'Pruning', cost: 0, enabled: false},
    {id: CostOperationId.growth_fertiliserSpreading, name: 'Fertiliser Spreading', cost: 0, enabled: false},
    {id: CostOperationId.est_gappingUp, name: 'Gapping up', cost: 0, enabled: false},
  ],
};

const harvestHarvesting: CostCollectionDefinition = {
  title: 'Harvest Operations',
  costUnit: 'hectare',
  active: args => args.yearIndex !== CLEARFELL_YEAR_INDEX && isHarvestYear(args),
  operations: [
    {id: CostOperationId.harvest_thinningAndHarvesting, name: 'Thinning and harvesting', cost: 780, enabled: true},
    {id: CostOperationId.harvest_managementCost, name: 'Management Cost', cost: 60, enabled: true},
    {id: CostOperationId.harvest_spraying, name: 'Spraying', cost: 0, enabled: false},
    {id: CostOperationId.harvest_fertiliser, name: 'Fertiliser', cost: 0, enabled: false},
    {id: CostOperationId.harvest_felling, name: 'Felling', cost: 0, enabled: false},
  ],
};

const harvestTransport: CostCollectionDefinition = {
  title: 'Transport Operations',
  costUnit: 'tonne',
  active: isHarvestYear,
  operations: [
    {
      id: CostOperationId.harvest_transportFromFieldToStore,
      name: 'Transport from field to store',
      cost: 0,
      enabled: false,
    },
    {
      id: CostOperationId.harvest_transportFromStoreToClient,
      name: 'Transport from store to client',
      cost: 12,
      enabled: true,
    },
    {id: CostOperationId.harvest_chipping, name: 'Chipping / Firewood production', cost: 5, enabled: true},
    {id: CostOperationId.harvest_drying, name: 'Drying', cost: 0, enabled: false},
  ],
};

const managementEstablishment: CostCollectionDefinition = {
  title: 'Management/Consultancy Charges (Establishment)',
  costUnit: 'flat',
  active: isEstablishmentYear,
  operations: [
    {id: CostOperationId.mcc_consultant, name: 'Management Consultant', cost: 100, enabled: true},
    {id: CostOperationId.mcc_professionalCharges, name: 'Consultant / Professional Charges', cost: 200, enabled: true},
  ],
};

const managementAnnual: CostCollectionDefinition = {
  title: 'Management/Consultancy Charges (Annual)',
  costUnit: 'flat',
  active: alwaysActive,
  operations: [
    {
      id: CostOperationId.mcc_BslSfrRegistration,
      name: 'BSL/SFR Registration',
      cost: 200,
      enabled: true,
    },
    {id: CostOperationId.mcc_annualConsultant, name: 'Management Consultant', cost: 15, enabled: true},
  ],
};

const clearfell: CostCollectionDefinition = {
  title: 'Clearfell` Operations',
  costUnit: 'hectare',
  active: ({yearIndex}) => yearIndex === CLEARFELL_YEAR_INDEX,
  operations: [
    {id: CostOperationId.clearfell_clearfell, name: 'Clearfell', cost: 6084, enabled: true},
    {id: CostOperationId.clearfell_managementCost, name: 'Management Cost', cost: 70, enabled: true},
  ],
};

const COSTS: CostCollectionDefinitionSet = {
  establishmentLand,
  establishmentWeed,
  establishmentPlanting,
  establishmentPest,
  establishmentRemedial,
  yearOneAndTwo,
  growth,
  harvestHarvesting,
  harvestTransport,
  managementEstablishment,
  managementAnnual,
  clearfell,
};

export default COSTS;
