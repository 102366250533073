import {useQuery} from '@tanstack/react-query';
import {useCallback} from 'react';

import {useFirebaseAuth} from '../common/EnvironmentProvider';
import {
  CurrentUserQuery,
  useCurrentUserQuery,
  User,
  UserPropsInput,
  useUpdateUserMutation,
  useUpdateUserPropsMutation,
} from './graphql';
import queryClient from './queryClient';

const USER_CACHE_KEY = useCurrentUserQuery.getKey();

export const hasFilledStartFlow = (user?: Partial<User> | null) => user && user.farmerProfile && user.farmerType;

const useCurrentUser = (initialData?: CurrentUserQuery | null) => {
  const currentUserQuery = useCurrentUserQuery(undefined, {
    cacheTime: Infinity,
    staleTime: Infinity,
    initialData: initialData ?? undefined,
  });
  return currentUserQuery.data?.currentUser ?? initialData?.currentUser;
};

export const useSignInMethod = () => {
  const auth = useFirebaseAuth();
  const authUser = auth?.currentUser;
  return useQuery({
    queryKey: [...USER_CACHE_KEY, 'signInMethod'],
    queryFn: async () => {
      const idToken = await authUser?.getIdTokenResult();
      if (!idToken) throw new Error('Not logged in');
      return idToken.signInProvider;
    },
  });
};

export const invalidateCurrentUser = () => {
  // Invalidate every user related query
  queryClient.invalidateQueries(USER_CACHE_KEY);
};

export const useUpdateCurrentUserMutation = () => useUpdateUserMutation({onSuccess: invalidateCurrentUser});

export const useUpdateUser = () => {
  const updateUserMutation = useUpdateCurrentUserMutation();
  return updateUserMutation.mutateAsync;
};

export const useUpdateUserProps = () => {
  const updateUserProps = useUpdateUserPropsMutation({onSuccess: invalidateCurrentUser});
  const {mutateAsync} = updateUserProps;
  return useCallback(async (data: UserPropsInput) => (await mutateAsync({data})).updateUserProps, [mutateAsync]);
};

export default useCurrentUser;
