import {User} from '@firebase/auth';
import {GoogleAuthProvider} from 'firebase/auth';

import ServerCookie, {cookies} from '../../cookies';

/**
 * Variables `firebaseApp` and `firebaseAuth` shall be fetched via
 * useEnvironment or useFirebaseAuth hooks
 */

export const installAuthCookie = async (currentUser: User, forceRefresh: boolean = false) => {
  const token = await currentUser.getIdToken(forceRefresh);
  const cookieToken = cookies.get(ServerCookie.authToken);
  if (token !== cookieToken) cookies.set(ServerCookie.authToken, token, {path: '/'});
};

export const clearAuth = async () => {
  cookies.remove(ServerCookie.authToken, {path: '/'});
};

export const googleAuthProvider = new GoogleAuthProvider();
