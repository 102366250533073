import classNames from 'classnames';
import React, {ReactElement} from 'react';

import beetle from '../../../../resources/animations/Beetle.json';
import {useBreakpoint} from '../../../common/util/useBreakpoint';
import LottieAnimation from '../LottieAnimation';

const N_BEETLES_WEB = 8;
const N_BEETLES_MOBILE = 4;

const getRandomInterval = (min: number, max: number) => {
  const intervalLength = max - min;
  return Math.random() * intervalLength + min;
};

const Beetles = (): ReactElement => {
  const {isMobile} = useBreakpoint('mobile');
  const nBeetles = isMobile ? N_BEETLES_MOBILE : N_BEETLES_WEB;
  return (
    <div className="absolute h-[23rem] w-full bottom-0 overflow-hidden">
      {Array(nBeetles)
        .fill(null)
        .map((_, colIdx) => {
          const top = getRandomInterval(0, 70);
          const randomLeft = getRandomInterval((colIdx * 100) / nBeetles - 10, (colIdx * 100) / nBeetles + 10);
          const left = Math.max(0, Math.min(100, randomLeft));
          const scale = getRandomInterval(0.3, 0.7);
          const rotation = getRandomInterval(0, 360);
          return (
            <LottieAnimation
              key={`beetle_${colIdx}`}
              className={classNames('absolute opacity-0 animate-rust')}
              animation={beetle}
              style={{
                transform: `scale(${scale}) rotate(${rotation}deg)`,
                top: `${top}%`,
                left: `${left}%`,
                animationDelay: `${colIdx * 0.5}s`,
              }}
              loop
            />
          );
        })}
    </div>
  );
};

export default Beetles;
