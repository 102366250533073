import {
  BeetlesEventIcon,
  CarbonSchemeEventIcon,
  DeerEventIcon,
  HarvesterMachineEventIcon,
  HarvestEventIcon,
  NewMarketEventIcon,
  PestsEventIcon,
  PlantingMachineEventIcon,
  RabbitEventIcon,
  RustEventIcon,
  TransportEventIcon,
  WeedEventIcon,
} from '../../../resources/Icons';
import Beetles from '../components/animations/Beetles';
import Deer from '../components/animations/Deer';
import Drought from '../components/animations/Drought';
import Fire from '../components/animations/Fire';
import Flood from '../components/animations/Flood';
import Pests from '../components/animations/Pests';
import Rabbit from '../components/animations/Rabbit';
import Rust from '../components/animations/Rust';
import StuckHarvester from '../components/animations/StuckHarvester';
import StuckPlanter from '../components/animations/StuckPlanter';
import Weeds from '../components/animations/Weeds';
import {GameLogicError} from '../errors';
import {
  choiceMadeInYear,
  eventOccurred,
  eventOccurredAtYear,
  findChoiceInHistory,
  isNHarvest,
  isPlantingYear,
  isPlantingYearPlus,
  normalizeMoney,
  scaleYield,
  toolIsPurchased,
} from '../mechanics';
import {Event, Status} from '../types';
import {CropId} from './crops';
import {LandUseId} from './landUses';
import {PlaceId} from './places';
import {OptionId, QuestionId} from './questions';
import {ToolId} from './tools';

export enum EventId {
  rabbits_1 = 'rabbits_1',
  rabbits_2 = 'rabbits_2',
  rabbits_3 = 'rabbits_3',
  rabbits_soft_1 = 'rabbits_soft_1',
  rabbits_soft_2 = 'rabbits_soft_2',
  rabbits_protected = 'rabbits_protected',
  deer_1 = 'deer_1',
  beetles_mono_1 = 'beetles_mono_1',
  beetles_mono_2 = 'beetles_mono_2',
  beetles_mono_3 = 'beetles_mono_3',
  beetles_mono_spray = 'beetles_mono_spray',
  beetles_light = 'beetles_light',
  weeds_rushed_willow_1 = 'weeds_rushed_willow_1',
  weeds_rushed_misc_1 = 'weeds_rushed_misc_1',
  weeds_no_herbicide_1 = 'weeds_no_herbicide_1',
  weeds_protected = 'weeds_protected',
  weeds_rushed_2 = 'weeds_rushed_2',
  flood = 'flood',
  drought = 'drought',
  stuck_planter = 'stuck_planter',
  stuck_harvester = 'stuck_harvester',
  rust_mono_1 = 'rust_mono_1',
  rust_mono_2 = 'rust_mono_2',
  rust_light_1 = 'rust_light_1',
  rust_light_2 = 'rust_light_2',
  new_incentive_scheme = 'new_incentive_scheme',
  new_incentive_scheme_2 = 'new_incentive_scheme_2',
  improved_growing = 'improved_growing',
  plantation_fire = 'plantation_fire',
  hike_energy_prices = 'hike_energy_prices',
  new_pest = 'new_pest',
  award = 'award',
  moth_1 = 'moth_1',
  moth_2 = 'moth_2',
  harvestTime_good = 'harvestTime_good',
  harvestTime_bad = 'harvestTime_bad',
  councilTransport_collect = 'councilTransport_collect',
  councilTransport_own = 'councilTransport_own',
  newContractor_stay = 'newContractor_stay',
  newContractor_change = 'newContractor_change',
  newMarket_bedding = 'newMarket_bedding',
  newMarket_miscrete = 'newMarket_miscrete',
  application_council = 'application_council',
  application_myself = 'application_myself',
  chips = 'chips',
  bales = 'bales',
}

const rollDices = (probability: number) => probability >= Math.random();

export const EVENTS: Event[] = [
  {
    id: EventId.stuck_planter,
    icon: PlantingMachineEventIcon,
    animation: StuckPlanter,
    name: 'Stuck planting machinery',
    description:
      "Oh no! Your planting machine is stuck because you decided to plant too early! You needed another tractor to pull your machine out of the field which has made a mess and damaged your yield. Next time it's important get good advice on the best time to plant.",
    hasHappened: (status: Status) => {
      // This can only happen on planting year
      if (!isPlantingYear(status)) return false;

      let probability = 0;
      if (status.plantingTime === 'march') {
        if (status.place?.id === PlaceId.dryshire) probability = 0.1;
        if (status.place?.id === PlaceId.rainyshire) probability = 0.9;
        if (status.place?.id === PlaceId.sunnyshire) probability = 0.4;
        if (status.place?.id === PlaceId.windyshire) probability = 0.5;
      }
      if (status.plantingTime === 'may') {
        if (status.place?.id === PlaceId.rainyshire) probability = 0.4;
        if (status.place?.id === PlaceId.sunnyshire) probability = 0.1;
        if (status.place?.id === PlaceId.windyshire) probability = 0.2;
      }
      if (status.plantingTime === 'july') {
        if (status.place?.id === PlaceId.rainyshire) probability = 0.1;
      }
      if (status.plantingTime === 'september') {
        if (status.place?.id === PlaceId.rainyshire) probability = 0.2;
        if (status.place?.id === PlaceId.windyshire) probability = 0.1;
      }
      return probability > 0 && rollDices(probability);
    },
    runEffects: state => {
      scaleYield(state, 0.8);
    },
  },
  {
    id: EventId.weeds_rushed_willow_1,
    icon: WeedEventIcon,
    animation: Weeds,
    name: 'Weed damage',
    description:
      "You should have spent a year planning ahead before rushing in and planting! You've now got a field full of weeds that are out-competing your crop! From now on you'll have to be really on your game to achieve your goal.",
    hasHappened: (status: Status) => {
      // This can only happen for Willow
      if (status.crop?.id !== CropId.willow) return false;
      // This can only happen on planting year
      if (!isPlantingYear(status)) return false;
      // This can only happen if player rushed
      if (status.plantingYear > 0) return false;

      // Will happen without herbicide; 50% chance with herbicide
      const probability = toolIsPurchased(status, ToolId.herbicide) ? 0.5 : 1;
      return probability > 0 && rollDices(probability);
    },
    runEffects: state => {
      scaleYield(state, 0.7);
    },
  },
  {
    id: EventId.weeds_rushed_misc_1,
    icon: WeedEventIcon,
    animation: Weeds,
    name: 'Weed damage',
    description:
      "You should have spent a year planning ahead before rushing in and planting! Leatherjackets (Daddy Long Legs) have eaten the roots of your crop, and grass weeds have sprung up everywhere! You'll have to harvest some impressive yields to achieve your goal.",
    hasHappened: (status: Status) => {
      // This can only happen for Miscanthus
      if (status.crop?.id !== CropId.miscanthus) return false;
      // This can only happen on planting year
      if (!isPlantingYear(status)) return false;
      // This can only happen if player rushed
      if (status.plantingYear > 0) return false;

      // Will happen without herbicide; 50% chance with herbicide
      const probability = toolIsPurchased(status, ToolId.herbicide) ? 0.5 : 1;
      return probability > 0 && rollDices(probability);
    },
    runEffects: state => {
      scaleYield(state, 0.7);
    },
  },
  {
    id: EventId.weeds_no_herbicide_1,
    icon: WeedEventIcon,
    animation: Weeds,
    name: 'Weed damage',
    description:
      'Your plantation has a weed problem! This is going to impact your yields. Herbicide would have controlled this and allowed your crop to reach canopy closure quicker.',
    hasHappened: (status: Status) => {
      // This can only happen on planting year
      if (!isPlantingYear(status)) return false;
      // This can only happen if player didn't rush
      if (status.plantingYear === 0) return false;
      // This can only happen if player didn't use herbicide
      if (toolIsPurchased(status, ToolId.herbicide)) return false;

      const probability = 0.5;
      return probability > 0 && rollDices(probability);
    },
    runEffects: state => {
      scaleYield(state, 0.7);
    },
  },
  {
    id: EventId.weeds_protected,
    icon: WeedEventIcon,
    name: 'Weeds have been kept in control',
    description:
      "You reap what you sow! You've done your homework and listened to good advice. Your crop has established well.",
    hasHappened: (status: Status) => {
      // This can only happen on planting year
      if (!isPlantingYear(status)) return false;
      // This can only happen if player didn't rush
      if (status.plantingYear === 0) return false;
      // This can only happen if player used herbicide
      if (!toolIsPurchased(status, ToolId.herbicide)) return false;

      return true;
    },
    runEffects: _state => {
      // No effects
    },
  },
  {
    id: EventId.weeds_rushed_2,
    icon: WeedEventIcon,
    animation: Weeds,
    name: 'Weeds',
    description:
      "You're still paying the price for bad planning! The weeds are back it’s taken longer for your crop to outcompete them and you've lost some of your yield.",
    hasHappened: (status: Status) => {
      // This can only happen on second harvest year
      if (!isNHarvest(status, 2)) return false;
      // This can only happen if we had weeds on planting year
      if (
        !eventOccurredAtYear(status, status.plantingYear, [
          EventId.weeds_rushed_willow_1,
          EventId.weeds_rushed_misc_1,
          EventId.weeds_no_herbicide_1,
        ])
      )
        return false;

      return true;
    },
    runEffects: state => {
      const usedCultivator =
        findChoiceInHistory(state, QuestionId.interRowCultivator)?.option?.id === OptionId.interRowCultivator_yes;
      scaleYield(state, usedCultivator ? 0.95 : 0.9);
    },
  },
  {
    id: EventId.rabbits_1,
    icon: RabbitEventIcon,
    animation: Rabbit,
    name: 'Rabbits damage',
    description:
      'Oh no! Rabbits have treated your crop like a fast food restaurant, with an all you can eat buffet. That’s taken a bite out of your potential yield.',
    hasHappened: (status: Status) => {
      // This can only happen on planting year
      if (!isPlantingYear(status)) return false;
      // This can only happen if we have NO Fence and NO Employee
      if (toolIsPurchased(status, ToolId.fence) || toolIsPurchased(status, ToolId.employee)) return false;
      let probability = 0.75;
      return probability > 0 && rollDices(probability);
    },
    runEffects: state => {
      scaleYield(state, 0.75);
    },
  },
  {
    id: EventId.rabbits_2,
    icon: RabbitEventIcon,
    name: 'Stunted plantation due to rabbits',
    description:
      'Your plantation is still suffering from the rabbit invasion during the plantation year. Your yield has been reduced by 10%',
    hasHappened: (status: Status) => {
      return isNHarvest(status, 2) && eventOccurredAtYear(status, status.plantingYear, EventId.rabbits_1);
    },
    runEffects: state => {
      scaleYield(state, 0.9);
    },
  },
  {
    id: EventId.rabbits_3,
    icon: RabbitEventIcon,
    name: 'Stunted plantation due to rabbits',
    description:
      'Your plantation is still suffering from the rabbit invasion during the plantation year. Your yield has been reduced by 5%',
    hasHappened: (status: Status) => {
      return isNHarvest(status, 3) && eventOccurredAtYear(status, status.plantingYear, EventId.rabbits_1);
    },
    runEffects: state => {
      scaleYield(state, 0.95);
    },
  },
  {
    id: EventId.rabbits_soft_1,
    icon: RabbitEventIcon,
    animation: Rabbit,
    name: 'Rabbits damage',
    description:
      'Pesky Rabbits! You managed to stop the rabbit damage to some extent. However, as the crops were young and quite small, the rabbits have still enjoyed eating some of your crop.',
    hasHappened: (status: Status) => {
      // This can only happen on planting year
      if (!isPlantingYear(status)) return false;
      // This can only happen if we don't have Fence AND have Employee
      if (toolIsPurchased(status, ToolId.fence) || !toolIsPurchased(status, ToolId.employee)) return false;
      let probability = 0.75;
      return probability > 0 && rollDices(probability);
    },
    runEffects: state => {
      scaleYield(state, 0.85);
    },
  },
  {
    id: EventId.rabbits_soft_2,
    icon: RabbitEventIcon,
    name: 'Stunted plantation due to rabbits',
    description:
      'Your plantation is still suffering from the rabbit invasion during the plantation year. Your yield has been reduced by 5%',
    hasHappened: (status: Status) => {
      return isNHarvest(status, 2) && eventOccurredAtYear(status, status.plantingYear, EventId.rabbits_soft_1);
    },
    runEffects: state => {
      scaleYield(state, 0.95);
    },
  },
  {
    id: EventId.rabbits_protected,
    icon: RabbitEventIcon,
    name: 'Your crops are protected!',
    description:
      'Good job! No yield loss to rabbits. It was very expensive but you should get a nice return on investment in the long run.',
    hasHappened: (status: Status) => isPlantingYear(status) && toolIsPurchased(status, ToolId.fence),
    runEffects: () => {},
  },
  {
    id: EventId.deer_1,
    icon: DeerEventIcon,
    animation: Deer,
    name: 'Deer damage',
    description: 'Your yield has been reduced by 10%',
    hasHappened: (status: Status) => {
      // This can only happen on planting year
      if (!isPlantingYear(status)) return false;
      // This can only happen for Willow
      if (status.crop?.id !== CropId.willow) return false;

      let probability = 0.5;
      if (toolIsPurchased(status, ToolId.sorghum_strip)) probability = 0.1;
      return probability > 0 && rollDices(probability);
    },
    runEffects: state => {
      scaleYield(state, 0.9);
    },
  },
  {
    id: EventId.beetles_mono_1,
    icon: BeetlesEventIcon,
    animation: Beetles,
    name: 'Beetles!',
    description: "A plague of beetles ate 5% of your crops! Maybe planting a monoculture wasn't a good idea.",
    hasHappened: (status: Status) => {
      // This can only happen for Willow
      if (status.crop?.id !== CropId.willow) return false;
      // This can't happen if we had a fire or flood this year
      if (eventOccurredAtYear(status, status.currentYear, [EventId.plantation_fire, EventId.flood])) return false;
      // This can only happen for monoculture
      if (status.plantationType !== 'monoculture') return false;
      // This can only happen if player did not spray
      if (toolIsPurchased(status, ToolId.beetles_spray)) return false;
      // This can only happen in planting year + 4
      if (!isPlantingYearPlus(status, 4)) return false;

      return true;
    },
    runEffects: state => {
      scaleYield(state, 0.95);
    },
  },
  {
    id: EventId.beetles_mono_2,
    icon: BeetlesEventIcon,
    animation: Beetles,
    name: 'Beetles!',
    description: "A plague of beetles ate 15% of your crops! Maybe planting a monoculture wasn't a good idea.",
    hasHappened: (status: Status) => {
      // This can only happen for Willow
      if (status.crop?.id !== CropId.willow) return false;
      // This can't happen if we had a fire or flood this year
      if (eventOccurredAtYear(status, status.currentYear, [EventId.plantation_fire, EventId.flood])) return false;
      // This can only happen for monoculture
      if (status.plantationType !== 'monoculture') return false;
      // This can only happen if player did not spray
      if (toolIsPurchased(status, ToolId.beetles_spray)) return false;
      // This can only happen in planting year + 6
      if (!isPlantingYearPlus(status, 6)) return false;

      return true;
    },
    runEffects: state => {
      scaleYield(state, 0.85);
    },
  },
  {
    id: EventId.beetles_mono_3,
    icon: BeetlesEventIcon,
    animation: Beetles,
    name: 'Beetles!',
    description: "A plague of beetles ate 35% of your crops! Maybe planting a monoculture wasn't a good idea.",
    hasHappened: (status: Status) => {
      // This can only happen for Willow
      if (status.crop?.id !== CropId.willow) return false;
      // This can't happen if we had a fire or flood this year
      if (eventOccurredAtYear(status, status.currentYear, [EventId.plantation_fire, EventId.flood])) return false;
      // This can only happen for monoculture
      if (status.plantationType !== 'monoculture') return false;
      // This can only happen if player did not spray
      if (toolIsPurchased(status, ToolId.beetles_spray)) return false;
      // This can only happen in planting year + 8
      if (!isPlantingYearPlus(status, 8)) return false;

      return true;
    },
    runEffects: state => {
      scaleYield(state, 0.65);
    },
  },
  {
    id: EventId.beetles_mono_spray,
    icon: BeetlesEventIcon,
    animation: Beetles,
    name: 'Beetles!',
    description:
      "A plague of beetles ate 10% of your crops! Maybe planting a monoculture wasn't a good idea. It would have been worse hadn't you used spray.",
    hasHappened: (status: Status) => {
      // This can only happen for Willow
      if (status.crop?.id !== CropId.willow) return false;
      // This can't happen if we had a fire or flood this year
      if (eventOccurredAtYear(status, status.currentYear, [EventId.plantation_fire, EventId.flood])) return false;
      // This can only happen for monoculture
      if (status.plantationType !== 'monoculture') return false;
      // This can only happen if player did spray
      if (!toolIsPurchased(status, ToolId.beetles_spray)) return false;
      // This can only happen in planting year + 8
      if (!isPlantingYearPlus(status, 8)) return false;

      return true;
    },
    runEffects: state => {
      scaleYield(state, 0.9);
    },
  },
  {
    id: EventId.beetles_light,
    icon: BeetlesEventIcon,
    animation: Beetles,
    name: 'Beetles!',
    description:
      'A plague of beetles ate 10% of your crops! Not too bad, lucky that you planted a mixture of varieties.',
    hasHappened: (status: Status) => {
      // This can only happen for Willow
      if (status.crop?.id !== CropId.willow) return false;
      // This can't happen if we had a fire or flood this year
      if (eventOccurredAtYear(status, status.currentYear, [EventId.plantation_fire, EventId.flood])) return false;
      // This can only happen for light mixture
      if (status.plantationType !== 'light_mixture') return false;
      // This can only happen if player did not spray
      if (toolIsPurchased(status, ToolId.beetles_spray)) return false;
      // This can only happen in planting year + 8
      if (!isPlantingYearPlus(status, 8)) return false;

      return true;
    },
    runEffects: state => {
      scaleYield(state, 0.9);
    },
  },
  {
    id: EventId.rust_mono_1,
    icon: RustEventIcon,
    animation: Rust,
    animationPosition: 'stem',
    name: 'Rust',
    description:
      'You lost 10% of your yield due to rust. Choosing a mixture of varieties would have helped prevent this.',
    hasHappened: (status: Status) => {
      // Only for willow
      if (status.crop?.id !== CropId.willow) return false;
      // This can't happen if we had a fire or drought this year
      if (eventOccurredAtYear(status, status.currentYear, [EventId.plantation_fire, EventId.drought])) return false;
      // Happens with monoculture on 2nd harvest year
      return status.plantationType === 'monoculture' && isNHarvest(status, 2);
    },
    runEffects: state => {
      scaleYield(state, 0.9);
    },
  },
  {
    id: EventId.rust_mono_2,
    icon: RustEventIcon,
    animation: Rust,
    animationPosition: 'stem',
    name: 'Rust',
    description:
      'You lost 20% of your yield due to rust. Choosing a mixture of varieties would have helped prevent this.',
    hasHappened: (status: Status) => {
      // Only for willow
      if (status.crop?.id !== CropId.willow) return false;
      // This can't happen if we had a fire or drought this year
      if (eventOccurredAtYear(status, status.currentYear, [EventId.plantation_fire, EventId.drought])) return false;
      return status.plantationType === 'monoculture' && isNHarvest(status, 3);
    },
    runEffects: state => {
      scaleYield(state, 0.8);
    },
  },
  {
    id: EventId.rust_light_1,
    icon: RustEventIcon,
    animation: Rust,
    animationPosition: 'stem',
    name: 'Rust',
    description:
      'You lost 5% of your yield due to rust. Choosing a hard mixture of varieties would have helped prevent this.',
    hasHappened: (status: Status) => {
      // Only for willow
      if (status.crop?.id !== CropId.willow) return false;
      // This can't happen if we had a fire or drought this year
      if (eventOccurredAtYear(status, status.currentYear, [EventId.plantation_fire, EventId.drought])) return false;
      return status.plantationType === 'light_mixture' && isNHarvest(status, 2);
    },
    runEffects: state => {
      scaleYield(state, 0.95);
    },
  },
  {
    id: EventId.rust_light_2,
    icon: RustEventIcon,
    animation: Rust,
    animationPosition: 'stem',
    name: 'Rust',
    description:
      'You lost 10% of your yield due to rust. Choosing a hard mixture of varieties would have helped prevent this.',
    hasHappened: (status: Status) => {
      // Only for willow
      if (status.crop?.id !== CropId.willow) return false;
      // This can't happen if we had a fire or drought this year
      if (eventOccurredAtYear(status, status.currentYear, [EventId.plantation_fire, EventId.drought])) return false;
      return status.plantationType === 'light_mixture' && isNHarvest(status, 3);
    },
    runEffects: state => {
      scaleYield(state, 0.9);
    },
  },
  {
    id: EventId.moth_1,
    icon: PestsEventIcon,
    animation: Pests,
    name: 'Pests',
    description:
      "You should have taken your time and planned ahead before you planted! A break crop would have broken the lifecycle of Rustic Moths, Ghost Moths and Wireworms. However, they’ve feasted on the roots of the developing crop. You'll have to harvest some impressive yields to achieve your goal!",
    hasHappened: (status: Status) => {
      // This can only happen on miscanthus
      if (status.crop?.id !== CropId.miscanthus) return false;
      // This can only happen on first harvest year if rushed
      if (!(isNHarvest(status, 1) && status.plantingYear === 0)) return false;

      const landUse = GameLogicError.assertNonNullish(status.landUse);
      let probability = 0;
      // Let probability = 0 for "arable"
      if (landUse.id === LandUseId.temp_grassland) probability = 0.1;
      if (landUse.id === LandUseId.permanent_pasture) probability = 0.6;
      if (landUse.id === LandUseId.rough_grazing) probability = 0.8;
      return probability > 0 && rollDices(probability);
    },
    runEffects: state => {
      const landUse = GameLogicError.assertNonNullish(state.landUse);
      // No effects for "arable"
      if (landUse.id === LandUseId.temp_grassland) scaleYield(state, 0.95);
      if (landUse.id === LandUseId.permanent_pasture) scaleYield(state, 0.9);
      if (landUse.id === LandUseId.rough_grazing) scaleYield(state, 0.9);
    },
  },
  {
    id: EventId.moth_2,
    icon: PestsEventIcon,
    animation: Pests,
    name: 'Pests',
    description:
      "They're called pests for a reason! A small but persistent population of Moth and Wireworms is continuing to nibble at your potential profits.",
    hasHappened: (status: Status) => {
      const landUse = GameLogicError.assertNonNullish(status.landUse);
      return (
        [LandUseId.permanent_pasture, LandUseId.rough_grazing].includes(landUse.id) &&
        isNHarvest(status, 2) &&
        eventOccurred(status, EventId.moth_1)
      );
    },
    runEffects: state => {
      const landUse = GameLogicError.assertNonNullish(state.landUse);
      // No effects for "arable" or "temp_grassland"
      if ([LandUseId.permanent_pasture, LandUseId.rough_grazing].includes(landUse.id)) {
        scaleYield(state, 0.95);
      }
    },
  },
  {
    id: EventId.stuck_harvester,
    icon: HarvesterMachineEventIcon,
    animation: StuckHarvester,
    name: 'Stuck harvesting machinery',
    description:
      "The harvester is stuck in mud! It needed to be towed out by a tractor which has made a mess of your field and you've lost some of your yield!",
    hasHappened: (status: Status) => {
      // This can only happen on first harvest year
      if (!isNHarvest(status, 1)) return false;

      const harvestTime = GameLogicError.assertNonNullish(status.harvestTime);
      let probability = 0;
      if (['october', 'april'].includes(harvestTime)) return false;
      if (harvestTime === 'december') probability = 0.3;
      if (harvestTime === 'february') probability = 0.4;
      return probability > 0 && rollDices(probability);
    },
    runEffects: state => {
      scaleYield(state, 0.8);
    },
  },
  // Informative events
  {
    id: EventId.harvestTime_good,
    icon: HarvestEventIcon,
    name: '',
    description: 'You chose to harvest at the right time - good move!',
    hasHappened: (status: Status) => {
      return (
        choiceMadeInYear(status, status.currentYear, QuestionId.harvestTime, OptionId.harvestTime_october) ||
        choiceMadeInYear(status, status.currentYear, QuestionId.harvestTime, OptionId.harvestTime_december)
      );
    },
    runEffects: () => {},
  },
  {
    id: EventId.harvestTime_bad,
    icon: HarvestEventIcon,
    name: '',
    description:
      "What a bountiful harvest! Pity it was cut at the wrong time of year! The crop was green and that means it's full of water, difficult to dry and ultimately a poor product. You won't make that mistake again!",
    hasHappened: (status: Status) => {
      return choiceMadeInYear(status, status.currentYear, QuestionId.harvestTime, OptionId.harvestTime_february);
    },
    runEffects: () => {},
  },
  {
    id: EventId.councilTransport_collect,
    icon: TransportEventIcon,
    name: 'Transport',
    description:
      'Very savvy move. You signed a very important contract that will safeguard you against future price rises!',
    hasHappened: (status: Status) => {
      return choiceMadeInYear(
        status,
        status.currentYear,
        QuestionId.councilTransport,
        OptionId.councilTransport_collect
      );
    },
    runEffects: () => {},
  },
  {
    id: EventId.councilTransport_own,
    icon: TransportEventIcon,
    name: 'Transport',
    description:
      "Transporting the crop yourself will probably cost you in the long run. The long term cost of transport fuel is always rising! You should've taken the deal.",
    hasHappened: (status: Status) => {
      return choiceMadeInYear(status, status.currentYear, QuestionId.councilTransport, OptionId.councilTransport_own);
    },
    runEffects: () => {},
  },
  {
    id: EventId.newContractor_stay,
    icon: HarvesterMachineEventIcon,
    name: 'Harvesting',
    description: 'You stuck with your current contractor, but unfortunately they left some of your yield uncut.',
    hasHappened: (status: Status) => {
      return (
        choiceMadeInYear(status, status.currentYear, QuestionId.newContractor_misc, OptionId.newContractor_stay) ||
        choiceMadeInYear(status, status.currentYear, QuestionId.newContractor_willow, OptionId.newContractor_stay)
      );
    },
    runEffects: () => {},
  },
  {
    id: EventId.newContractor_change,
    icon: HarvesterMachineEventIcon,
    name: 'Harvesting',
    description:
      'It’s important to move with the times. The new harvester worked a treat and gave your yield a boost and left less of an environmental footprint. Well done you!',
    hasHappened: (status: Status) => {
      return (
        choiceMadeInYear(status, status.currentYear, QuestionId.newContractor_misc, OptionId.newContractor_change) ||
        choiceMadeInYear(status, status.currentYear, QuestionId.newContractor_willow, OptionId.newContractor_change)
      );
    },
    runEffects: () => {},
  },
  {
    id: EventId.newMarket_bedding,
    icon: NewMarketEventIcon,
    name: 'New market',
    description:
      'The bedding option is a good one. There are plenty of farm animals that will enjoy the bedding! They all like a nice mattress and Miscanthus is perfect.',
    hasHappened: (status: Status) => {
      return choiceMadeInYear(status, status.currentYear, QuestionId.newMarket, OptionId.newMarket_bedding);
    },
    runEffects: () => {},
  },
  {
    id: EventId.newMarket_miscrete,
    icon: NewMarketEventIcon,
    name: 'New market',
    description:
      'Miscrete is one of the next generation uses for Miscanthus. Innovations like these will help farmers save the planet.',
    hasHappened: (status: Status) => {
      return choiceMadeInYear(status, status.currentYear, QuestionId.newMarket, OptionId.newMarket_miscrete);
    },
    runEffects: () => {},
  },
  {
    id: EventId.application_council,
    icon: CarbonSchemeEventIcon,
    name: 'Carbon scheme',
    description: "Not bad! You made some money and didn't have to spend much time or energy doing it.",
    hasHappened: (status: Status) => {
      return choiceMadeInYear(status, status.currentYear, QuestionId.application, OptionId.application_council);
    },
    runEffects: () => {},
  },
  {
    id: EventId.application_myself,
    icon: CarbonSchemeEventIcon,
    name: 'Carbon scheme',
    description:
      "Great work! You read all the information and interpreted it correctly. The application took some time, but you've learned a lot and made more money!",
    hasHappened: (status: Status) => {
      return choiceMadeInYear(status, status.currentYear, QuestionId.application, OptionId.application_myself);
    },
    runEffects: () => {},
  },
  // Jeopardies
  {
    id: EventId.flood,
    animation: Flood,
    name: 'Flooding',
    description:
      "It doesn't rain, it pours! Your crop is under water. There's no such thing as a subaqua harvester so you'll have to delay harvest until next year. You'll lose 5% growth and also have to clean up all the debris that washed into your crop.",
    silent: true,
    // Don't execute the event randomly, it just can be injected during Jeopardy phase
    hasHappened: () => false,
    runEffects: state => {
      scaleYield(state, 0.95);
      // Spend 10% of the money (max 4000 pounds) on cleaning the debris
      const moneyToClean = normalizeMoney(state.money * 0.1 < 4000 ? state.money * 0.1 : 4000);
      state.money -= moneyToClean;
    },
  },
  {
    id: EventId.new_incentive_scheme,
    name: 'New incentive scheme',
    description:
      "Good News! The Government has decided to reward the environmental benefits of biomass crops and are giving existing growers £50 per hectare, per year. You're quids in!",
    silent: true,
    // Don't execute the event randomly, it just can be injected during Jeopardy phase
    hasHappened: () => false,
    runEffects: _state => {
      // No effect. new_incentive_scheme_2 will happen from now on, event of the year this occured
    },
  },
  {
    id: EventId.new_incentive_scheme_2,
    name: 'Incentive scheme',
    description: 'Here is your yearly reward of £50 per hectare',
    hasHappened: (status: Status) => {
      return eventOccurred(status, EventId.new_incentive_scheme);
    },
    runEffects: state => {
      const landSize = GameLogicError.assertNonNullish(state.landSize);
      state.money += normalizeMoney(50 * landSize);
    },
  },
  {
    id: EventId.drought,
    name: 'Drought',
    animation: Drought,
    animationPosition: 'background',
    description:
      'This summer was a scorcher! Your crop stood still and looked a bit thirsty for a couple of months, and have now dried up. Wave goodbye to 10% of your yield.',
    silent: true,
    // Don't execute the event randomly, it just can be injected during Jeopardy phase
    hasHappened: () => false,
    runEffects: state => {
      scaleYield(state, 0.9);
    },
  },
  {
    id: EventId.improved_growing,
    name: 'Improved growing conditions',
    description:
      "This year's weather has been great for growing! These unseasonal conditions have increased your yield by 10%.",
    silent: true,
    // Don't execute the event randomly, it just can be injected during Jeopardy phase
    hasHappened: () => false,
    runEffects: state => {
      scaleYield(state, 1.1);
    },
  },
  {
    id: EventId.plantation_fire,
    animation: Fire,
    name: 'Plantation fire',
    description:
      'Fire! Someone thought it would be a good idea to light a disposable barbeque in the shade of your plantation and it caused a bad fire. That’s 40% of your yield gone up in smoke.',
    silent: true,
    // Don't execute the event randomly, it just can be injected during Jeopardy phase
    hasHappened: () => false,
    runEffects: state => {
      scaleYield(state, 0.6);
    },
  },
  {
    id: EventId.hike_energy_prices,
    name: 'Hike in global energy prices',
    description:
      'A nice little earner! The much needed journey to Net Zero means that geopolitical events and market forces have increased your biomass crop in value by £15/tonne.',
    silent: true,
    // Don't execute the event randomly, it just can be injected during Jeopardy phase
    hasHappened: () => false,
    runEffects: state => {
      const pricePerTonne = GameLogicError.assertNonNullish(state.prices.pricePerTonne);
      state.prices.pricePerTonne = pricePerTonne + 15;
    },
  },
  {
    id: EventId.new_pest,
    animation: Pests,
    name: 'New introduced pest',
    description:
      "Unexpected visitors! The rising temperature has meant that the conditions are now favourable to an exotic pest. It's greedy mandibles have decimated your crop and cost you 50% of your yield this year.",
    silent: true,
    // Don't execute the event randomly, it just can be injected during Jeopardy phase
    hasHappened: () => false,
    runEffects: state => {
      scaleYield(state, 0.5);
    },
  },
  {
    id: EventId.award,
    name: 'Farmer wins a £500 award',
    description:
      'You won £500! Having teamed up with some academics on a new concept called a biorefinery, you produced more products from the same crop, and the project was shortlisted for a farming prize.',
    silent: true,
    // Don't execute the event randomly, it just can be injected during Jeopardy phase
    hasHappened: () => false,
    runEffects: state => {
      state.money = state.money + 500;
    },
  },
  {
    id: EventId.chips,
    name: 'The council wants your crops delivered as chips!',
    description:
      'These aren’t the sort of chips you find in the school canteen! Miscanthus can be harvested in a form that is similar to wood chips. The good news though is that you get more from selling a tonne of Miscanthus chips. The bad news is that they are bulky and take up a lot of storage space.',
    silent: true,
    // Don't execute the event randomly, it just can be injected during Jeopardy phase
    hasHappened: () => false,
    runEffects: () => {
      // The occurrence of this event will make "dry" question occur on Miscanthus
    },
  },
  {
    id: EventId.bales,
    name: 'The council wants your crops delivered as bales!',
    description:
      'Rectangular bales are easy to store and more dense than chips. As the local school is just down the road, its a breeze to transport each bale using a spike on the front of a tractor. However, the boiler used is a bit old school so needs more cleaning. As a result the price the Council pays is cheaper than chips!',
    silent: true,
    // Don't execute the event randomly, it just can be injected during Jeopardy phase
    hasHappened: () => false,
    runEffects: () => {
      // The occurrence of this event will make "dry" question NOT occur on Miscanthus
    },
  },
];

export const getAllEvents = () => EVENTS;

export const getEvents = (ids: EventId[]) => EVENTS.filter(e => ids.includes(e.id));

export const getEvent = (id: EventId) => EVENTS.find(e => e.id === id);
