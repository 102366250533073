import classNames from 'classnames';
import React, {ReactElement, useCallback} from 'react';

import Col from '../../../common/Col';
import {isNullish} from '../../../common/util';
import {CostCollection, EditableInputs, HelpText, LocationOutput, Option, SimpleValue} from '../../decision/types';
import {Validator, ValidatorWithInputs} from '../../decision/validation';
import HelpButton from '../buttons/HelpButton';
import DefaultValueMessages from './DefaultValueMessages';
import QuestionCostsTable from './QuestionCostTable';
import QuestionDropdown from './QuestionDropdown';
import QuestionInput from './QuestionInput';
import QuestionLocation from './QuestionLocation';
import QuestionMultiSelect from './QuestionMultiSelect';
import QuestionSelect from './QuestionSelect';
import QuestionSlider from './QuestionSlider';
import QuestionTripleInput from './QuestionTripleInput';

type ValidatorProps<T> =
  | {
      validate: Validator<T>;
    }
  | {
      validate: ValidatorWithInputs<T>;
      inputs: EditableInputs;
    };

type CommonProps<T extends SimpleValue, U extends string> = ValidatorProps<T> & {
  id: U;
  title: string;
  value: T | null;
  defaultValue: T | undefined;
  onChange: (id: U, value: T | null) => void | Promise<void>;
  error?: string;
  isOmitted?: boolean;
  disabled?: boolean;
  info?: JSX.Element | ((props: PropsType<any, any>) => JSX.Element | undefined);
  sidePanel?: JSX.Element | ((props: PropsType<any, any>) => JSX.Element | undefined);
  tooltip?: HelpText | ((props: PropsType<any, any>) => HelpText | undefined);
  // Overrides the label for Next button in question sequence
  nextButtonLabel?: string;
  // If defined, a secondary submit button with this label is placed next to Next button
  alternateLabel?: string;
  // Handler to call when the alternate button is clicked
  alternateOnChange?: (id: U, value: T | null) => void | Promise<void>;
};

type SelectType<T extends SimpleValue, U extends string> = CommonProps<T, U> & {
  type: 'select';
  orientation?: 'vertical' | 'horizontal';
  options: Option<T>[];
};

type MultiSelectType<T extends SimpleValue, U extends string> = CommonProps<T, U> & {
  type: 'multiselect';
  options: Option<string>[];
};

type InputType<T extends SimpleValue, U extends string> = CommonProps<T, U> & {
  type: 'input';
} & (
    | {
        numeric?: false;
      }
    | {numeric: true; units?: string; isArea?: boolean; wide?: boolean}
  );

type SliderType<T extends SimpleValue, U extends string> = CommonProps<T, U> & {
  type: 'slider';
  min: number;
  max: number;
  units: string;
  unitsInSlider?: boolean;
};

type TripleType<T extends SimpleValue, U extends string> = CommonProps<T, U> & {
  type: 'triple';
  total: number;
  units: string;
};

type LocationType<T extends SimpleValue, U extends string> = CommonProps<T, U> & {
  type: 'location';
  googleMapsApiKey: string;
};

type DropdownType<T extends SimpleValue, U extends string> = CommonProps<T, U> & {
  type: 'dropdown';
  options: Option<T>[];
};

type CostTableType<T extends SimpleValue, U extends string> = CommonProps<T, U> & {
  type: 'costTable';
  cropName: string;
};

type CustomType<T extends SimpleValue, U extends string> = CommonProps<T, U> & {
  type: 'custom';
  content: ReactElement;
};

export type PropsType<T extends SimpleValue, U extends string> =
  | SelectType<T, U>
  | MultiSelectType<T, U>
  | InputType<T, U>
  | SliderType<T, U>
  | TripleType<T, U>
  | LocationType<T, U>
  | DropdownType<T, U>
  | CostTableType<T, U>
  | CustomType<T, U>;

const QuestionView = <T extends SimpleValue, U extends string>(props: PropsType<T, U>) => {
  const {id, title, value, defaultValue, onChange, error, info, disabled, sidePanel, tooltip} = props;
  const shownValue = value ?? defaultValue;
  const showingDefault = isNullish(value) && !isNullish(defaultValue);
  const canReset = !showingDefault && !isNullish(defaultValue) && shownValue != defaultValue && props.type !== 'triple';

  const handleResetToDefault = useCallback(async () => {
    await onChange(id, null);
  }, [id, onChange]);

  const handleChangeSelect = useCallback(
    async (val: T | null) => {
      await onChange(id, val);
    },
    [id, onChange]
  );

  const handleChangeMultiSelect = useCallback(
    async (val: string[]) => {
      await onChange(id, val as unknown as T);
    },
    [id, onChange]
  );

  const handleChangeInput = useCallback(
    async (val: string | null) => {
      await onChange(id, val as T); // TODO: Make TS know this should be a string
    },
    [id, onChange]
  );

  const handleChangeTripleInput = useCallback(
    async (val: [string, string, string]) => {
      await onChange(id, val as unknown as T);
    },
    [id, onChange]
  );

  const handleChangeLocationInput = useCallback(
    async (val: LocationOutput | null) => {
      await onChange(id, val as unknown as T);
    },
    [id, onChange]
  );

  const handleChangeCostsTable = useCallback(
    async (val: CostCollection) => {
      await onChange(id, val as unknown as T);
    },
    [id, onChange]
  );

  let Content;
  switch (props.type) {
    case 'select':
      Content = (
        <QuestionSelect<T>
          onChange={handleChangeSelect}
          options={props.options}
          value={shownValue}
          defaultValue={defaultValue}
          orientation={props.orientation}
          error={error}
        />
      );
      break;
    case 'multiselect':
      let sanitisedMultiselectValue: string[] | undefined = undefined;
      if (shownValue !== undefined) {
        if (!Array.isArray(shownValue)) {
          console.warn('QuestionView with type multiselect must be given an array as value');
        } else {
          sanitisedMultiselectValue = shownValue.map(v => String(v));
        }
      }

      Content = (
        <QuestionMultiSelect
          onChange={handleChangeMultiSelect}
          options={props.options}
          value={sanitisedMultiselectValue}
        />
      );
      break;
    case 'input':
      const inputUnits = props.numeric ? props.units : undefined;
      const inputIsArea = props.numeric ? props.isArea : undefined;
      const inputWide = props.numeric ? props.wide : undefined;
      Content = (
        <QuestionInput
          onChange={handleChangeInput}
          value={shownValue !== undefined ? String(shownValue) : ''}
          error={error}
          info={typeof info === 'function' ? info(props) : info}
          disabled={disabled}
          units={inputUnits}
          isArea={inputIsArea}
          wide={inputWide}
          pattern={props.numeric ? '^(0|[1-9]\\d*)(\\.\\d+)?$' : undefined}
          title={props.numeric ? 'A positive number' : undefined}
        />
      );
      break;
    case 'slider':
      const {min, max, units, unitsInSlider} = props;
      Content = (
        <QuestionSlider
          onChange={handleChangeInput}
          value={shownValue !== undefined ? String(shownValue) : ''}
          min={min}
          max={max}
          error={error}
          info={typeof info === 'function' ? info(props) : info}
          disabled={disabled}
          units={units}
          unitsInSlider={unitsInSlider}
          pattern={'^(0|[1-9]\\d*)(\\.\\d+)?$'}
          title={'A positive number'}
        />
      );
      break;
    case 'triple':
      let sanitisedTripleValue: [string, string, string] | undefined = undefined;
      if (shownValue !== undefined) {
        if (!Array.isArray(shownValue) || shownValue.length !== 3) {
          console.warn('QuestionView with type triple must be given a 3-dimensional array as value');
        } else {
          sanitisedTripleValue = [String(shownValue[0]), String(shownValue[1]), String(shownValue[2])];
        }
      }

      Content = (
        <QuestionTripleInput
          onChange={handleChangeTripleInput}
          value={sanitisedTripleValue}
          total={props.total}
          units={props.units}
          error={error}
          disabled={disabled}
        />
      );
      break;
    case 'location':
      let sanitisedLocation: LocationOutput | undefined = undefined;
      if (shownValue !== undefined) {
        if (typeof shownValue !== 'object' || !('postcode' in shownValue) || !('coordinates' in shownValue)) {
          console.warn('QuestionView with type location must be given a LocationOutput as value');
        } else {
          sanitisedLocation = shownValue;
        }
      }
      Content = (
        <QuestionLocation
          googleMapsApiKey={props.googleMapsApiKey}
          value={sanitisedLocation}
          onChange={handleChangeLocationInput}
          error={error}
          disabled={disabled}
        />
      );
      break;
    case 'dropdown':
      Content = (
        <QuestionDropdown onChange={handleChangeSelect} options={props.options} value={shownValue} error={error} />
      );
      break;
    case 'costTable':
      const {cropName} = props;
      let sanitisedCostCollection: CostCollection = {
        title: 'Unknown',
        costUnit: 'tonne',
        active: () => true,
        operations: [],
      };
      if (shownValue !== undefined) {
        if (
          typeof shownValue !== 'object' ||
          !('title' in shownValue) ||
          !('costUnit' in shownValue) ||
          !('operations' in shownValue)
        ) {
          console.warn('QuestionView with type costTable must be given a CostCollection as value');
        } else {
          sanitisedCostCollection = shownValue;
        }
      }
      Content = (
        <QuestionCostsTable
          value={sanitisedCostCollection}
          cropName={cropName}
          onChange={handleChangeCostsTable}
          error={error}
        />
      );
      break;
    case 'custom':
      Content = props.content;
      break;
    default:
      const _exhaustiveSwitchCheck: never = props;
  }

  const titleClass =
    props.type === 'location' ||
    props.type === 'dropdown' ||
    props.type === 'costTable' ||
    (props.type === 'select' && props.orientation !== 'horizontal')
      ? 'text-left'
      : 'text-center';
  const tooltipContent = typeof tooltip === 'function' ? tooltip(props) : tooltip;
  const sidePanelContent = typeof sidePanel === 'function' ? sidePanel(props) : sidePanel;
  const defaultToShow =
    props.type === 'select' ? props.options.find(o => o.value === defaultValue)?.label ?? defaultValue : undefined;
  return (
    <div className={classNames('flex-grow justify-center flex flex-row mobile:flex-col')}>
      <Col className={classNames('max-w-limit grow justify-center', sidePanel ? 'mx-lg' : 'mx-sm')}>
        <h1 className={classNames('mt-4 mb-lg leading-tight', titleClass)}>
          <span className={'text-3xl font-semibold'}>{title}</span>
          {!!tooltipContent && <HelpButton className={'ml-3'} tooltipClassName={'text-left'} {...tooltipContent} />}
        </h1>
        {Content}
        <DefaultValueMessages
          show={canReset ? 'reset' : showingDefault ? 'default' : 'none'}
          defaultValue={defaultToShow}
          onReset={handleResetToDefault}
        />
      </Col>
      {sidePanelContent && (
        <Col className={'basis-1/2 max-w-[745px] flex-1 px-lg py-xs bg-primary-700 text-white'}>{sidePanelContent}</Col>
      )}
    </div>
  );
};

export default QuestionView;
