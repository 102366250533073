import classNames from 'classnames';
import React, {ComponentProps, ReactElement} from 'react';

import {EditIcon} from '../../../../resources/Icons';

const EditButton = ({className, ...props}: Omit<ComponentProps<'button'>, 'children'>): ReactElement => {
  return (
    <button
      type={'button'}
      className={classNames('text-gray-200 enabled:hover:text-primary-300 disabled:text-gray-200', className)}
      {...props}
    >
      {<EditIcon />}
    </button>
  );
};

export default EditButton;
