import classNames from 'classnames';
import React, {ComponentProps, ReactElement, ReactNode} from 'react';
import {Tooltip} from 'react-tooltip';

const SimpleTooltip = ({
  className,
  children,
  ...rest
}: ComponentProps<typeof Tooltip> & {children?: ReactNode}): ReactElement => {
  return (
    <Tooltip
      variant={'light'}
      className={classNames(
        'z-10 max-w-[90vw] !p-0 border-thin border-gray-100 !rounded-xl !opacity-100 !text-xs text-primary-800 shadow'
      )}
      classNameArrow={'border-r-thin border-b-thin border-gray-100'}
      {...rest}
    >
      <div className={classNames('py-[4px] px-[8px]', className)}>{children}</div>
    </Tooltip>
  );
};

export default SimpleTooltip;
