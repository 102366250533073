import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {StartFlow} from './decision/types';

export const initialState: StartFlow = {
  farmerType: null,
  farmerProfile: null,
};

export const startFlowSlice = createSlice({
  name: 'startFlow',
  initialState,
  reducers: {
    reset: () => initialState,
    setValue: (state, action: PayloadAction<Partial<StartFlow>>) => ({...state, ...action.payload}),
  },
});

export const StartFlowActions = startFlowSlice.actions;

export default startFlowSlice.reducer;
